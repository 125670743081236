import { omit } from "lodash";
import api, { HttpError } from "utils/api";

export type HttpUser = {
  username: string;
  is_admin: boolean;
  is_deactivated?: boolean;
};

export type User = {
  id: string;
  username: string;
  is_admin: boolean;
  isDeactivated: boolean;
};

const convertFromHttp = (httpUser: HttpUser) => ({
  ...omit(httpUser, ["is_deactivated"]),
  id: httpUser.username,
  isDeactivated: Boolean(httpUser.is_deactivated),
});

export default User;

export async function fetchCurrentUser(): Promise<User> {
  const response = await api().get("/user/current");
  return convertFromHttp(response.data);
}

export type UserSignupInfo = {
  username: string;
  password: string;
  is_admin: boolean;
};

export async function createUser(userInfo: UserSignupInfo): Promise<User> {
  await api()
    .post(`/users`, userInfo)
    .catch((error) => {
      throw new HttpError(error.response.data.error);
    });
  return convertFromHttp(userInfo);
}

export type UserLoginInfo = {
  username: string;
  password: string;
};

export async function login(userInfos: UserLoginInfo): Promise<string> {
  const response = await api().post("/user/authentication", userInfos);

  return response.data.access_token;
}

// export async function checkIfLogged(): Promise<User> {
//   const response = await api().get("/user/current");
//   return response.data;
// }

export async function fetchAllUsers(): Promise<Array<User>> {
  const response = await api().get("/users");
  return response.data.map(convertFromHttp);
}

export async function deleteUser(user: User) {
  await api().delete("/users", { data: { username: user.username } });
}

export type UserUpdateInfo = {
  id: string;
  username: string;
  password: string;
  is_admin: boolean;
  isDeactivated: boolean;
};

export async function updateUser(userInfo: UserUpdateInfo) {
  const toUpdate = {
    username: userInfo.username,
    password: userInfo.password,
    is_admin: userInfo.is_admin,
    is_deactivated: userInfo.isDeactivated,
  };
  await api()
    .put(`/users`, toUpdate)
    .catch((error) => {
      throw new HttpError(error.response.data.error);
    });
}

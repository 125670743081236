import { getCurrentActivity } from "store";
import { useAppSelector } from "hooks/redux";

import FileExplorer from "./FilesExplorer";
import ConceptsExplorer from "./ConceptsExplorer";
import VersionsExplorer from "./VersionsExplorer";
import styles from "./SideBar.module.scss";

type Props = {
  readOnly: boolean;
};

const SideBar = ({ readOnly }: Props) => {
  const currentActivity = useAppSelector(getCurrentActivity);

  return (
    <div className={styles.SideBar}>
      {currentActivity === "files" && <FileExplorer readOnly={readOnly} />}
      {currentActivity === "concepts" && (
        <ConceptsExplorer readOnly={readOnly} />
      )}
      {currentActivity === "publish" && (
        <VersionsExplorer readOnly={readOnly} />
      )}
    </div>
  );
};

export default SideBar;

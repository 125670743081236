import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import BasicDialog from "components/molecules/BasicDialog";
import Form from "components/atoms/Form";
import FileNode, { getType } from "models/fileNode";
import { useAppDispatch } from "hooks/redux";
import { updateFileNode } from "store";

type Props = {
  open: boolean;
  onClose: () => void;
  file: FileNode;
};

export const RenameFileOrFolderDialog = ({ open, onClose, file }: Props) => {
  const [newName, setNewName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    setNewName(file.name);
    setErrorMessage("");
  }, [open, file]);

  const rename = async () => {
    if (!newName) {
      setErrorMessage(t("_Name can not be empty"));
      return;
    }
    try {
      await dispatch(updateFileNode({ file, name: newName })).unwrap();
      onClose();
    } catch (error: any) {
      setErrorMessage((error as Error).message);
    }
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    rename();
  };

  const type = getType(file);

  const { t } = useTranslation();

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      onSubmit={rename}
      title={t("_Rename...", { type })}
      actionTitle={t("_Rename")}
    >
      {t("_What is the new name of the", { type, fileName: file.name })}
      <Form onSubmit={onSubmit}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t("_New name")}
          type="text"
          fullWidth
          error={errorMessage !== ""}
          helperText={errorMessage}
          value={newName}
          onChange={(evt) => setNewName(evt.target.value)}
          variant="outlined"
        />
      </Form>
    </BasicDialog>
  );
};

import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Tooltip,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FolderZipIcon from "@mui/icons-material/FolderZip";

import { useAppDispatch } from "hooks/redux";
import { exportProjectAsZip, importProjectFromZip, parse } from "store";
import useCurrentProject from "hooks/useCurrentProject";
import usePopup from "hooks/usePopup";
import { CreateFileOrFolderDialog } from "components/organisms/FilesListDialogs/CreateFileOrFolderDialog";

import styles from "./CreateFileAndFolderActions.module.scss";

type Props = {
  readOnly: boolean;
};

const CreateFileAndFolderActions = ({ readOnly }: Props) => {
  const { project, version } = useCurrentProject();

  const { t } = useTranslation();

  const createFileDialog = usePopup();
  const createFolderDialog = usePopup();

  const [anchorMenuEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();

  const openMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMoreMenu = () => {
    setAnchorEl(null);
  };

  const exportProject = () => {
    dispatch(exportProjectAsZip({ project, version }));
    closeMoreMenu();
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const startUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
    closeMoreMenu();
  };

  const onUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files) {
      await dispatch(importProjectFromZip({ files, project }));
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      await dispatch(parse({ project, version })).unwrap();
    }
  };

  return (
    <div className={styles.menu}>
      {!readOnly && (
        <>
          <Tooltip title={t("_New file") || ""} placement="bottom">
            <NoteAddIcon fontSize="small" onClick={createFileDialog.open} />
          </Tooltip>
          <CreateFileOrFolderDialog
            open={createFileDialog.isOpen}
            onClose={createFileDialog.close}
            type="file"
          />
          <Tooltip title={t("_New folder") || ""} placement="bottom">
            <CreateNewFolderIcon
              fontSize="small"
              onClick={createFolderDialog.open}
            />
          </Tooltip>
          <CreateFileOrFolderDialog
            open={createFolderDialog.isOpen}
            onClose={createFolderDialog.close}
            type="folder"
          />
        </>
      )}
      <Tooltip title={t("_More") || ""} placement="bottom">
        <button className={styles.button} onClick={openMoreMenu}>
          <MoreVertIcon fontSize="small" />
        </button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorMenuEl}
        open={Boolean(anchorMenuEl)}
        onClose={closeMoreMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={exportProject}>
          <ListItemIcon>
            <FolderZipIcon />
          </ListItemIcon>
          <ListItemText>{t("_Export project as ZIP")}</ListItemText>
        </MenuItem>
        {!readOnly && (
          <MenuItem onClick={startUpload}>
            <ListItemIcon>
              <FileUploadIcon />
            </ListItemIcon>
            <ListItemText>{t("_Import project from a ZIP")}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <input
        ref={inputRef}
        type="file"
        onChange={onUpload}
        className={styles.hidden}
        accept=".zip"
      />
    </div>
  );
};

export default CreateFileAndFolderActions;

import { useState } from "react";
import { Tooltip } from "@mui/material";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";
import { useTranslation } from "react-i18next";

import useEventListener from "hooks/useEventListener";

import styles from "./StatusBar.module.scss";

const ConnectivityIndicator = () => {
  const { t } = useTranslation();

  const [isOnline, setOnline] = useState(navigator.onLine);

  useEventListener("online", () => {
    setOnline(true);
  });

  useEventListener("offline", () => {
    setOnline(false);
  });

  return (
    <div className={styles.connectivity}>
      <Tooltip
        title={
          (isOnline
            ? t("_connected to the server")
            : t("_disconnected from the server")) || ""
        }
        placement="top"
      >
        <div className={styles.item}>
          {isOnline ? (
            <CloudDoneOutlinedIcon className={styles.icon} />
          ) : (
            <CloudOffOutlinedIcon className={styles.icon} />
          )}

          <div className={styles.label}>
            {isOnline ? t("_connected") : t("_disconnected")}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default ConnectivityIndicator;

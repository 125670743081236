import { TableNd } from "models/parser/Table";
import { Element } from "models/parser";
import TableItem from "components/molecules/TableItem";

import styles from "./Table.module.scss";
import RowTransform, { noTransform } from "./RowTransform";

import {
  Table1Dimension,
  Table2Dimensions,
  Table3Dimensions,
} from "components/atoms/Table";

type Props = {
  element: Element;
  className?: string;
  table: TableNd;
  transform: RowTransform;
};

const TableNDimensions = ({
  table,
  element,
  className = "",
  transform = noTransform,
}: Props) => {
  const { headers, cells } = table;

  return (
    <table className={`${styles.Table} ${className}`}>
      <caption className={styles.caption}>
        <TableItem elementId={element.id} hideTableLink isHeader />
      </caption>
      {table.n_dim === 1 && (
        <Table1Dimension cells={cells} transform={transform} />
      )}
      {table.n_dim === 2 && (
        <Table2Dimensions
          headers={headers}
          cells={cells}
          transform={transform}
        />
      )}
      {table.n_dim === 3 && (
        <Table3Dimensions
          headers={headers}
          cells={cells}
          transform={transform}
        />
      )}
      {table.n_dim > 3 && (
        <tbody>
          <tr>
            <td className={styles.td}>dimension not managed</td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default TableNDimensions;

import { ReactNode } from "react";
import styles from "./ExplorerHeader.module.scss";

type Props = {
  title: string;
  children?: ReactNode;
};

const ExplorerHeader = ({ title, children }: Props) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      <div>{children}</div>
    </div>
  );
};

export default ExplorerHeader;

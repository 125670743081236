import { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

import { setCurrentBottomTab } from "store";
import { useAppDispatch } from "hooks/redux";

import styles from "./ParanodeTableLink.module.scss";

type Props = {
  table: string | null;
  tableRoot: string | null;
  className?: string;
  children?: ReactNode;
};

const ParanodeTableLink = ({
  table,
  tableRoot,
  className,
  children,
}: Props) => {
  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  const openTable = () => {
    searchParams.set("paranode_table", table || "");
    searchParams.set("paranode_table_root", tableRoot || "");
    if (searchParams.get("table")) {
      searchParams.delete("table");
    }
    setSearchParams(searchParams);

    dispatch(setCurrentBottomTab("tables"));
  };

  return (
    <div onClick={openTable} className={`${styles.Link} ${className}`}>
      {children}
    </div>
  );
};

export default ParanodeTableLink;

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { CategoryHierarchyDefinition } from "models/parser";
import Translation from "components/molecules/Translation";
import { useAppSelector } from "hooks/redux";
import { getElement, getCurrentTablePath } from "store";
import useCurrentProject from "hooks/useCurrentProject";
import ParanodeTableLink from "components/atoms/ParanodeTableLink";

import styles from "./Breadcrumb.module.scss";
import { Fragment } from "react";

type PropsItem = {
  table: CategoryHierarchyDefinition;
  target: string;
  isCurrent?: boolean;
};

const BreadCrumbItem = ({ table, target }: PropsItem) => {
  const { projectId } = useCurrentProject();

  const element = useAppSelector((state) =>
    getElement(state, projectId, target)
  );

  if (!element) {
    return <div>"Oups"</div>;
  }

  return (
    <div>
      <ParanodeTableLink table={table.root} tableRoot={target}>
        <Translation element={element} />
      </ParanodeTableLink>
    </div>
  );
};

type Props = {
  table: CategoryHierarchyDefinition;
  current: string;
};

const BreadCrumb = ({ table, current }: Props) => {
  const { projectId } = useCurrentProject();

  const links = useAppSelector((state) =>
    getCurrentTablePath(state, projectId, table, current)
  );

  if (links.length < 2) {
    return <></>;
  }

  return (
    <div className={styles.Breadcrumb}>
      {links.map((link, index, array) => (
        <Fragment key={link}>
          <BreadCrumbItem table={table} target={link} />
          {index !== array.length - 1 ? (
            <div className={styles.chevron}>
              <ChevronRightIcon />
            </div>
          ) : null}
        </Fragment>
      ))}
    </div>
  );
};

export default BreadCrumb;

import PositionedMessage from "models/parser/PositionedMessage";
import { useAppSelector } from "hooks/redux";
import { generatePath, Link } from "react-router-dom";

import { getFile } from "store";
import styles from "./ErrorBottomPanel.module.scss";
import { routes } from "models/routes";
import useCurrentProject from "hooks/useCurrentProject";

type Props = {
  positionedMessage: PositionedMessage;
};

const ErrorBottomPanel = ({ positionedMessage }: Props) => {
  const { range, message } = positionedMessage;

  const file = useAppSelector(getFile(range.file_id));

  const { projectId } = useCurrentProject();

  const { char_start, line_start, file_id } = range;

  let path = "";

  if (projectId) {
    const pattern = `${routes.editorWithFile}?line=${line_start}&char=${char_start}`;
    path = generatePath(pattern, { projectId, fileId: file_id });
  }

  return (
    <Link className={styles.item} to={path}>
      {file?.name} [{range.line_start}:{range.char_start}]: {message}
    </Link>
  );
};

export default ErrorBottomPanel;

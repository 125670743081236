import { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

import useCurrentProject from "hooks/useCurrentProject";
import { setCurrentBottomTab, getTable } from "store";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { isTableCell } from "models/parser/Table";

import styles from "./TableLink.module.scss";

type Props = {
  tableId: string | null;
  className?: string;
  children?: ReactNode;
};

const TableLink = ({ tableId, className, children }: Props) => {
  const { projectId } = useCurrentProject();

  const table = useAppSelector((state) =>
    getTable(state, projectId, tableId || "")
  );

  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  const openTable = () => {
    searchParams.set("table", tableId || "");
    if (searchParams.get("paranode_table")) {
      searchParams.delete("paranode_table");
    }

    if (searchParams.get("paranode_table_root")) {
      searchParams.delete("paranode_table_root");
    }
    setSearchParams(searchParams);

    dispatch(setCurrentBottomTab("tables"));
  };

  if (!table || isTableCell(table)) {
    return null;
  }

  return (
    <div onClick={openTable} className={`${styles.Link} ${className}`}>
      {children}
    </div>
  );
};

export default TableLink;

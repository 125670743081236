import { Suspense } from "react";
import { useTranslation } from "react-i18next";

import styles from "./ConceptsExplorer.module.scss";
import ConceptsAccordions from "components/organisms/ConceptsAccordions";
import ExplorerHeader from "components/molecules/ExplorerHeader";
import Explorer from "components/molecules/Explorer";
import SearchBar from "components/organisms/SearchBar";
import { useAppSelector } from "hooks/redux";
import { getFilters } from "store";
import CreateFileAndFolderActions from "components/organisms/CreateFileAndFolderActions";

type Props = {
  readOnly: boolean;
};

const ConceptsExplorer = ({ readOnly }: Props) => {
  const { t } = useTranslation();

  const { query } = useAppSelector(getFilters);

  return (
    <Explorer>
      <ExplorerHeader title={t("_Concepts")}>
        <CreateFileAndFolderActions readOnly={readOnly} />
      </ExplorerHeader>
      <div className={styles.search}>
        <SearchBar />
      </div>
      <div className={styles.results}>
        <div className={styles.resultsInner}>
          <div className={styles.sorted}>
            {query
              ? t("_Sorted by relevance")
              : t("_Sorted by definition order")}
          </div>
          <Suspense fallback={null}>
            <ConceptsAccordions />
          </Suspense>
        </div>
      </div>
    </Explorer>
  );
};

export default ConceptsExplorer;

type Range = {
  file_id: string;
  line_start: number;
  line_end: number;
  char_start: number;
  char_end: number;
};

export default Range;

export const isRangeInvalid = (range: Range): boolean =>
  range.char_start === range.char_end && range.line_start === 0;

export const isRangeValid = (range: Range): boolean => !isRangeInvalid(range);

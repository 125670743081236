import { useState, useEffect } from "react";

type Options = any[];

const useFetchApi = (
  condition: (...options: Options) => boolean,
  callback: (...options: Options) => void,
  options: Options,
  isFetched: boolean
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (condition(...options) && !isFetched) {
      try {
        setIsError(false);
        setIsLoading(true);
        return callback(...options);
      } catch (error: any) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...options, isFetched]);

  return { isLoading, isError };
};

export default useFetchApi;

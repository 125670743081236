import { useEffect, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Card,
  List,
  ListItem,
  Chip,
  Divider,
  Stack,
  Tooltip,
  IconButton,
  Switch,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  areAllUsersFetched,
  fetchAllUsers,
  getUsers,
  getCurrentUser,
  updateUser,
} from "store";
import TopBar from "components/organisms/TopBar";
import User from "models/user";
import { getRole } from "utils/permissions";
import * as Role from "models/role";
import CreateUserDialog from "components/organisms/UserDialogs/CreateUserDialog";
import DeleteUserDialog from "components/organisms/UserDialogs/DeleteUserDialog";
import UpdateUserDialog from "components/organisms/UserDialogs/UpdateUserDialog";

import styles from "./UserListPage.module.scss";
import usePopup from "hooks/usePopup";

const stopPropagationAnd = (func: () => void) => (event: React.MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
  func();
};

const UserListPage = () => {
  const { t } = useTranslation();

  const users = useAppSelector(getUsers);

  const areUserFetched = useAppSelector(areAllUsersFetched);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!areUserFetched) {
      dispatch(fetchAllUsers()).unwrap();
    }
  }, [dispatch, areUserFetched]);

  const createUserPopup = usePopup();
  const [selectedUser, setSelectedUser] = useState<User | undefined>();

  const deleteUserPopup = usePopup();
  const editUserPopup = usePopup();

  const currentUser = useAppSelector(getCurrentUser);

  const onToggleDeactivation =
    (user: User) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        updateUser({
          ...user,
          password: "",
          isDeactivated: !event.target.checked,
        })
      ).unwrap();
    };

  return (
    <div className={styles.layout}>
      <TopBar
        topLeft={
          <Link to="/" className={styles.back}>
            <Button variant="outlined" startIcon={<ArrowBackIcon />}>
              {t("_All Projects")}
            </Button>
          </Link>
        }
      />
      <main className={styles.main}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={createUserPopup.open}
              size="small"
            >
              {t("_Create User")}
            </Button>
          </Box>
          {!areUserFetched && <>{t("_Loading users")}</>}
          <Card sx={{ padding: 0, marginTop: "1rem" }}>
            <List sx={{ padding: 0 }}>
              {users.map((user, index) => (
                <Fragment key={user.id}>
                  {index !== 0 && <Divider />}
                  <ListItem>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "2rem",
                      }}
                    >
                      <Box sx={{ flex: "0 0 auto" }}>{user.username}</Box>
                      <Box sx={{ flex: "1 1 auto", marginLeft: "1rem" }}>
                        <Chip
                          label={t(Role.toString(getRole(user)))}
                          color={user.is_admin ? "primary" : "default"}
                          variant={user.is_admin ? "filled" : "outlined"}
                          size="small"
                        />
                      </Box>
                      {currentUser?.username !== user.username && (
                        <Stack direction="row" spacing={1}>
                          <Tooltip title={t("_Edit User") || ""}>
                            <IconButton
                              aria-label="edit"
                              onClick={(e) => {
                                stopPropagationAnd(editUserPopup.open)(e);
                                setSelectedUser(user);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("_Delete User") || ""}>
                            <IconButton
                              aria-label="delete"
                              onClick={(e) => {
                                stopPropagationAnd(deleteUserPopup.open)(e);
                                setSelectedUser(user);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      )}
                      <Switch
                        checked={!user.isDeactivated}
                        onChange={onToggleDeactivation(user)}
                        disabled={currentUser?.username === user.username}
                      />
                    </Box>
                  </ListItem>
                </Fragment>
              ))}
            </List>
          </Card>
        </Box>
        <CreateUserDialog
          open={createUserPopup.isOpen}
          onClose={createUserPopup.close}
        />
        {selectedUser && (
          <DeleteUserDialog
            user={selectedUser}
            open={deleteUserPopup.isOpen}
            onClose={deleteUserPopup.close}
          />
        )}
        {selectedUser && (
          <UpdateUserDialog
            user={selectedUser}
            open={editUserPopup.isOpen}
            onClose={editUserPopup.close}
          />
        )}
      </main>
    </div>
  );
};

export default UserListPage;

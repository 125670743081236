import { useEffect } from "react";

import { useAppSelector } from "hooks/redux";
import {
  getBottomPanelHeight,
  getCurrentBottomTab,
  isSeparateWindowOpen,
} from "store";

const useResizeEditor = (editor: any) => {
  const bottomTableHeight = useAppSelector(getBottomPanelHeight);
  const bottomTab = useAppSelector(getCurrentBottomTab);

  const separateWindowOpen = useAppSelector(isSeparateWindowOpen);

  useEffect(() => {
    if (editor.current) {
      // https://stackoverflow.com/questions/27534263/making-ace-editor-resizable
      editor.current.editor.resize();
      editor.current.editor.renderer.updateFull();
    }
  }, [bottomTab, bottomTableHeight, separateWindowOpen, editor]);
};

export default useResizeEditor;

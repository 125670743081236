import { useTranslation } from "react-i18next";

import {
  searchConcepts,
  getFilters,
  getScripts,
  isParserRunning,
  getFilesIds,
} from "store";
import { useAppSelector } from "hooks/redux";
import { Element } from "models/parser";
import { Scopes } from "models/filters";

import style from "./ConceptsAccordions.module.scss";

import GenericAccordion from "./GenericAccordion";
import useCurrentProject from "hooks/useCurrentProject";
import useCurrentFile from "hooks/useCurrentFile";

const ConceptsAccordion = () => {
  const { currentVersion, project } = useCurrentProject();

  const { scope, query } = useAppSelector(getFilters);

  const currentFile = useCurrentFile();

  const allFileIdsInProject = useAppSelector((state) =>
    getFilesIds(state, project?.id, currentVersion)
  );

  const fileIds =
    scope === Scopes.ALL
      ? undefined
      : scope === Scopes.CURRENT_PROJECT
      ? allFileIdsInProject
      : [currentFile?.id || ""];

  const concepts = useAppSelector((state) =>
    searchConcepts(state, project.id, currentVersion, fileIds)
  );

  const { nodes, links, inflections, auxiliaries, junctions, rootParadigms } =
    concepts;

  const scriptsByIds = useAppSelector((state) => getScripts(state, project.id));

  const { t } = useTranslation();

  const isProgressing = useAppSelector(isParserRunning);

  const allConceptsLength =
    nodes.length +
    links.length +
    inflections.length +
    auxiliaries.length +
    junctions.length +
    rootParadigms.length;

  const hasOnlyConcepts = (concepts: Element[]) =>
    allConceptsLength === concepts.length && concepts.length !== 0;

  const sections = [
    {
      label: t("_Inflections"),
      elements: inflections,
    },
    {
      label: t("_Auxiliaries"),
      elements: auxiliaries,
    },

    {
      label: t("_Junctions"),
      elements: junctions,
    },
    {
      label: t("_Nodes"),
      elements: nodes,
    },
    {
      label: t("_Links"),
      elements: links,
    },
    {
      label: t("_Root paradigms"),
      elements: rootParadigms,
    },
  ];

  return (
    <div className={style.ConceptsPanel}>
      <div className={style.accordions}>
        {sections
          .filter((s) => s.elements.length > 0)
          .map(({ label, elements }) => (
            <GenericAccordion
              key={label}
              elements={elements}
              label={label}
              scripts={scriptsByIds}
              isLoading={isProgressing}
              defaultExpanded={hasOnlyConcepts(elements)}
            />
          ))}
      </div>
      {query && allConceptsLength === 0 && (
        <p className={style.no_result}>{t("_Nothing found")}</p>
      )}
    </div>
  );
};

export default ConceptsAccordion;

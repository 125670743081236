import { useCallback, useRef, useEffect } from "react";
import { debounce } from "lodash";

function useIsMounted() {
  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return () => isMountedRef.current;
}

export default function useDebounce(
  callback: (...args: any[]) => void,
  delay: number
) {
  const options = {
    leading: false,
    trailing: true,
  };
  const inputsRef = useRef({ callback, delay });
  const isMounted = useIsMounted();
  useEffect(() => {
    inputsRef.current = { callback, delay };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(
      (...args: any[]) => {
        // Don't execute callback, if (1) component in the meanwhile
        // has been unmounted or (2) delay has changed
        if (inputsRef.current.delay === delay && isMounted())
          inputsRef.current.callback(...args);
      },
      delay,
      options
    ),
    [delay, debounce]
  );
}

import { Stack, Divider } from "@mui/material";

import styles from "./RelationshipBottomPanel.module.scss";
import useCurrentProject from "hooks/useCurrentProject";
import { useAppSelector } from "hooks/redux";
import { getInstancesForTargetElements } from "store";
import RelationshipTranslation from "components/molecules/RelationshipTranslation";

const RelationshipBottomPanel = () => {
  const { projectId } = useCurrentProject();

  const instances = useAppSelector((state) =>
    getInstancesForTargetElements(state, projectId || "")
  );

  return (
    <>
      <Stack
        divider={<Divider orientation="horizontal" flexItem />}
        spacing={1}
      >
        {instances.map((instance) => (
          <div className={styles.Item}>
            <RelationshipTranslation instance={instance} />
          </div>
        ))}
      </Stack>
    </>
  );
};

export default RelationshipBottomPanel;

import JSZip from "jszip";
import { saveAs } from "file-saver";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { getFilesToParse, createFileNode } from "./fileNodes.slice";
import { getFullPath, extractPathAndName } from "models/fileNode";
import Project from "models/project";
import FileNode from "models/fileNode";
import Version, { versionToString } from "models/version";
import { RootState } from "store";

export const exportProjectAsZip = createAsyncThunk(
  "importExport/exportProjectAsZip",
  async (
    { project, version }: { project: Project; version: Version },
    thunkApi
  ): Promise<string> => {
    const state = thunkApi.getState() as RootState;
    const files = getFilesToParse(state, project.id, version);

    const zip = new JSZip();

    files.forEach((file) => {
      zip.file(`${getFullPath(file)}.ieml`, file.content);
    });

    const blob = await zip.generateAsync({ type: "blob" });

    saveAs(blob, `${project.name}-${versionToString(version)}.zip`);

    return "ok";
  }
);

const isAuthorizedFileFormat = (
  filePath: string,
  zipEntry: JSZip.JSZipObject
) =>
  (filePath.match(/\.(ieml|txt|text)$/) || zipEntry.dir) &&
  !filePath.startsWith(".") &&
  !filePath.startsWith("__MACOSX");

export const importProjectFromZip = createAsyncThunk(
  "importExport/importProjectFromZip",
  async (
    { files, project }: { project: Project; files: FileList },
    thunkApi
  ): Promise<any> => {
    const { dispatch } = thunkApi;

    const promises: Promise<Array<FileNode>>[] = [];
    for (var i = 0; i < files.length; i++) {
      const zip = await JSZip.loadAsync(files[i]);

      let zipEntries: any[] = [];

      zip.forEach((relativePath, zipEntry) => {
        if (isAuthorizedFileFormat(relativePath, zipEntry)) {
          const { path, name } = extractPathAndName(relativePath);

          zipEntries.push({
            isFolder: zipEntry.dir,
            path,
            name,
            zipEntryName: zipEntry.name,
          });
        }
      });

      const rootFolders = zipEntries.filter(
        (entry) => entry.path === "" && entry.isFolder
      );

      if (rootFolders.length === 1) {
        zipEntries = zipEntries
          .filter((entry) => entry.path !== "")
          .map((e) => ({
            ...e,
            path: e.path.replace(rootFolders[0].name, "").replace(/^\//, ""),
          }));
      }

      for (const zipEntry of zipEntries) {
        const toCreate = {
          project,
          path: zipEntry.path,
          name: zipEntry.isFolder
            ? zipEntry.name
            : zipEntry.name.replace(/\.ieml$/, ""),
          isFolder: zipEntry.isFolder,
          content: "",
        };

        if (!zipEntry.isFolder) {
          const file = zip.file(zipEntry.zipEntryName);
          if (file) {
            toCreate.content = await file.async("string");
          }
        }

        await dispatch(createFileNode(toCreate));
      }
    }

    return await Promise.all(promises);
  }
);

import { TreeItem } from "@mui/lab";
import { SyntheticEvent, ReactNode } from "react";
import { InsertDriveFile, InsertDriveFileOutlined } from "@mui/icons-material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import FileNode from "models/fileNode";
import Project from "models/project";
import { useAppSelector } from "hooks/redux";
import { getChildrenFiles } from "store";

type Props = {
  // the file tree to display
  file: FileNode;

  // the file to display there are compilation errors
  erroneousFilesId?: string[];

  // the currently opened file in the editor
  selectedFileIds: string[];

  // the name of the project
  project: Project;

  // do we display the files in the folders ?
  folderOnly: boolean;

  // click on a file handler
  onFileNodeClick: (file: FileNode) => void;

  openContextMenu?: (evt: SyntheticEvent, file: FileNode) => void;

  expanded?: boolean;

  suffix: string;

  endIcon?: ReactNode;
};

const FileItem = ({
  file,
  project,
  selectedFileIds,
  erroneousFilesId,
  folderOnly,
  onFileNodeClick,
  openContextMenu,
  suffix,
  endIcon = null,
}: Props) => {
  const children = useAppSelector((state) => getChildrenFiles(state, file));

  if (folderOnly && !file.isFolder) {
    return <></>;
  }

  const isSelected = selectedFileIds.includes(file.id);

  const icon = file.isFolder ? null : isSelected ? (
    <InsertDriveFile />
  ) : (
    <InsertDriveFileOutlined />
  );

  return (
    <TreeItem
      expandIcon={<ArrowRightIcon />}
      collapseIcon={<ArrowDropDownIcon />}
      icon={icon}
      nodeId={file.id}
      label={`${file.name}${suffix}`}
      onClick={() => onFileNodeClick(file)}
      id={file.id}
      onContextMenu={(evt) => openContextMenu && openContextMenu(evt, file)}
      endIcon={endIcon}
    >
      {children.map((child) => (
        <FileItem
          key={child.id}
          project={project}
          file={child}
          erroneousFilesId={erroneousFilesId}
          selectedFileIds={selectedFileIds}
          folderOnly={folderOnly}
          onFileNodeClick={onFileNodeClick}
          openContextMenu={openContextMenu}
          suffix={suffix}
          endIcon={endIcon}
        />
      ))}
    </TreeItem>
  );
};

export default FileItem;

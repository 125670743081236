import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "hooks/redux";
import usePopup from "hooks/usePopup";
import { getLastestProjectVersion } from "store";
import { versionToString, UpgradeType, upgradeVersion } from "models/version";
import useCurrentProject from "hooks/useCurrentProject";
import { createProjectVersion } from "store";
import Expander from "components/atoms/Expander";

import ConfirmPublishVersionDialog from "./ConfirmPublishVersionDialog";
import styles from "../VersionsExplorer.module.scss";

type Props = {
  open: boolean;
  onClose: () => void;
  onError: (error: string) => void;
};

const Label = ({
  label,
  description,
}: {
  label: string;
  description: string;
}) => (
  <>
    <span className={styles.label}>{label}:</span>{" "}
    <span className={styles.description}>{description}</span>
  </>
);

const PublishVersionDialog = ({ open, onClose, onError }: Props) => {
  const { t } = useTranslation();

  const [versionType, setVersionType] = useState<UpgradeType>("minor");

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVersionType(event.target.value as UpgradeType);
  };
  const { currentProject } = useCurrentProject();

  const lastVersion = useAppSelector(
    getLastestProjectVersion(currentProject.id)
  );

  const newVersion = upgradeVersion(lastVersion, versionType);

  const confirmationPopup = usePopup();

  const dispatch = useAppDispatch();

  const onConfirm = async () => {
    if (currentProject) {
      try {
        await dispatch(
          createProjectVersion({
            project: currentProject,
            upgradeType: versionType,
          })
        ).unwrap();
        confirmationPopup.close();
        onClose();
      } catch (error: any) {
        confirmationPopup.close();
        onError(error.message);
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("_Publish Version")}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minWidth: 500,
              padding: 1,
            }}
          >
            <div>{t("_Choose the type")}</div>
            <FormControl
              sx={{
                padding: 1,
              }}
            >
              <RadioGroup
                aria-labelledby="version-type-radio-buttons-group-label"
                defaultValue={versionType}
                onChange={onChange}
                name="version-type-radio-buttons-group"
              >
                <FormControlLabel
                  value="major"
                  control={<Radio />}
                  label={
                    <Label
                      label={t("_Major")}
                      description={t("_include breaking changes")}
                    />
                  }
                />
                <FormControlLabel
                  value="minor"
                  control={<Radio />}
                  label={
                    <Label
                      label={t("_Minor")}
                      description={t("_include only non-breaking changes")}
                    />
                  }
                />
                <FormControlLabel
                  value="patch"
                  control={<Radio />}
                  label={
                    <Label
                      label={t("_Patch")}
                      description={t("_include only fixes")}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
            <div>
              {t("_new version number")}{" "}
              <span className={styles.newVersion}>
                {versionToString(newVersion)}
              </span>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t("_Cancel")}</Button>
          <Expander />
          <Button onClick={confirmationPopup.open} variant="contained">
            {t("_Publish")}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmPublishVersionDialog
        open={confirmationPopup.isOpen}
        onCancel={confirmationPopup.close}
        onSubmit={onConfirm}
        version={newVersion}
      />
    </>
  );
};

export default PublishVersionDialog;

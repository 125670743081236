import LoginForm from "components/organisms/LoginForm";
import CenterPage from "components/templates/CenterPage";

const LoginPage = () => {
  return (
    <CenterPage>
      <LoginForm />
    </CenterPage>
  );
};

export default LoginPage;

import { Fragment } from "react";
import Instance from "models/parser/Instance";
import { getTranslations } from "models/language";
import { useAppSelector } from "hooks/redux";
import { getFilters, getElements } from "store";
import useCurrentProject from "hooks/useCurrentProject";
import ConceptLink from "components/atoms/ConceptLink";

import styles from "./RelationshipTranslation.module.scss";
import Element from "models/parser/Element";

type Props = {
  instance: Instance;
};

const RelationshipTranslation = ({ instance }: Props) => {
  const { language } = useAppSelector(getFilters);
  const { projectId } = useCurrentProject();

  const elements = useAppSelector((state) =>
    getElements(state, projectId || "", Object.values(instance.linkedWordIds))
  ).filter((e) => e !== undefined && "id" in e);

  let label = getTranslations(instance, language).defaultTranslation.label;

  const elementsById = elements.reduce(
    (acc, element: any) => ({ ...acc, [element.id]: element }),
    {}
  ) as { [id: string]: Element };

  elements.forEach((element: any) => {
    label = label.replace(
      getTranslations(element, language).defaultTranslation.label,
      element.id
    );
  });

  return (
    <span className={styles.label}>
      {label.split(" ").map((word, index) => (
        <Fragment key={index}>
          {index !== 0 && <span className={styles.separator}>&nbsp;</span>}
          {(elementsById[word] && (
            <ConceptLink className={styles.link} element={elementsById[word]}>
              {
                getTranslations(elementsById[word as string], language)
                  .defaultTranslation.label
              }
            </ConceptLink>
          )) ||
            word}
        </Fragment>
      ))}
    </span>
  );
};

export default RelationshipTranslation;

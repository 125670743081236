import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import BookIcon from "@mui/icons-material/Book";
import TableViewIcon from "@mui/icons-material/TableView";
import DescriptionIcon from "@mui/icons-material/Description";
import { CircularProgress, Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

import { useTranslation } from "react-i18next";

import ConnectivityIndicator from "./ConnectivityIndicator";
import { getFullPath } from "models/fileNode";
import useCurrentFile from "hooks/useCurrentFile";
import StatsDialog from "components/organisms/StatsDialog";

import styles from "./StatusBar.module.scss";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import useSelectedTable from "hooks/useSelectedTable";
import { count } from "models/parser/Table";
import {
  getParserErrors,
  getParserWarnings,
  toggleCurrentBottomTab,
  getSingularSequences,
  isParserRunning,
  getNumberOFiles,
  getInstancesForTargetElements,
  getProjectById,
  parse,
} from "store";
import useCurrentProject from "hooks/useCurrentProject";
import { versionToString } from "models/version";
import usePopup from "hooks/usePopup";

const StatusBar = () => {
  const currentFile = useCurrentFile();

  const { projectId, currentProject, project, version } = useCurrentProject();

  const currentFileProject = useAppSelector(
    getProjectById(currentFile?.projectId)
  );

  const errors = useAppSelector(getParserErrors(projectId));
  const warnings = useAppSelector(getParserWarnings(projectId));
  const selectedTable = useSelectedTable();
  const tablesCount = count(selectedTable);
  const instances = useAppSelector((state) =>
    getInstancesForTargetElements(state, projectId)
  );

  const singularSequences = useAppSelector((state) =>
    getSingularSequences(state, projectId)
  );

  const filesCount = useAppSelector(getNumberOFiles(projectId));

  const dispatch = useAppDispatch();

  const openTab = (tab: string) => () => {
    dispatch(toggleCurrentBottomTab(tab));
  };

  const statDialog = usePopup();

  const isProgressing = useAppSelector(isParserRunning);

  const { t } = useTranslation();

  const dictionaryVersion = versionToString(currentProject.dictionaryVersion);

  const currentPath = currentFile
    ? `${currentFileProject?.name}/${getFullPath(currentFile)}`
    : "";

  const build = () => {
    dispatch(parse({ project, version }));
  };

  return (
    <div className={styles.StatusBar}>
      <div className={styles.file}>{currentPath}</div>
      <div className={styles.compilation}>
        <div className={styles.build}>
          {isProgressing ? (
            <CircularProgress size={15} color="inherit" />
          ) : (
            <button className={styles.buildButton} onClick={build}>
              Build
            </button>
          )}
        </div>
        <Tooltip title="Errors" placement="top">
          <button className={styles.button} onClick={openTab("errors")}>
            <CancelOutlinedIcon className={styles.icon} />
            <div
              className={`${styles.label} ${
                errors.length > 0 ? styles.active : ""
              }`}
            >
              {errors.length}
            </div>
          </button>
        </Tooltip>
        <Tooltip title="Warnings" placement="top">
          <button className={styles.button} onClick={openTab("warnings")}>
            <WarningAmberOutlinedIcon className={styles.icon} />
            <div
              className={`${styles.label} ${
                warnings.length > 0 ? styles.active : ""
              }`}
            >
              {warnings.length}
            </div>
          </button>
        </Tooltip>
        <Tooltip title={t("_Open tables") || ""} placement="top">
          <button className={styles.button} onClick={openTab("tables")}>
            <TableViewIcon className={styles.icon} />
            <div
              className={`${styles.label} ${
                tablesCount > 0 ? styles.active : ""
              }`}
            >
              {tablesCount}
            </div>
          </button>
        </Tooltip>
        <Tooltip title={t("_Open relationships") || ""} placement="top">
          <button className={styles.button} onClick={openTab("relationships")}>
            <LinkIcon className={styles.icon} />
            <div
              className={`${styles.label} ${
                instances.length > 0 ? styles.active : ""
              }`}
            >
              {instances.length}
            </div>
          </button>
        </Tooltip>
      </div>
      <div className={`${styles.container} ${styles.expand} ${styles.right}`}>
        {currentProject.isDictionary && (
          <div className={styles.stats}>
            <button className={styles.button} onClick={statDialog.open}>
              <BarChartOutlinedIcon className={styles.icon} />

              <Tooltip title={t("_Singular sequences") || ""} placement="top">
                <div className={styles.label}>{singularSequences.length}</div>
              </Tooltip>
            </button>
            <button className={styles.button} onClick={statDialog.open}>
              <DescriptionIcon className={styles.icon} />

              <Tooltip title={t("_Number of files") || ""} placement="top">
                <div className={styles.label}>{filesCount}</div>
              </Tooltip>
            </button>
            <StatsDialog open={statDialog.isOpen} onClose={statDialog.close} />
          </div>
        )}
        {!currentProject.isDictionary && (
          <div className={styles.stats}>
            <BookIcon className={styles.icon} />

            <Tooltip
              title={
                t("_Dictionary version", {
                  dictionaryVersion,
                }) || ""
              }
              placement="top"
            >
              <div className={styles.label}>v{dictionaryVersion}</div>
            </Tooltip>
          </div>
        )}

        <ConnectivityIndicator />
      </div>
    </div>
  );
};

export default StatusBar;

import { useEffect, useState } from "react";
import { IAnnotation } from "react-ace";

import FileNode from "models/fileNode";
import ParserOutput from "models/parser/ParserOutput";

import PositionedMessage from "models/parser/PositionedMessage";

const createAnnotation = (error: PositionedMessage): IAnnotation => ({
  row: error.range.line_start - 1,
  column: 0,
  text: error.message,
  type: "error",
});

const useAnnotations = (
  parserResult: ParserOutput | undefined,
  file: FileNode
): IAnnotation[] | undefined => {
  const [annotations, setAnnotations] = useState<IAnnotation[]>([]);

  // doesn't work with useMemo for no specic reason
  useEffect(() => {
    if (parserResult && "errors" in parserResult!) {
      const errors = parserResult.errors.filter(
        (error) => error.range.file_id === file.id
      );

      setAnnotations(errors.map(createAnnotation));
    }
  }, [parserResult, file]);

  return annotations;
};

export default useAnnotations;

import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "hooks/redux";
import useDebounce from "hooks/useDebounce";
import FileNode from "models/fileNode";

import { selectLine } from "store";

const useUpdateSelectedLine = (file: FileNode) => {
  const dispatch = useAppDispatch();

  let [searchParams, setSearchParams] = useSearchParams();

  return useDebounce((selection: any) => {
    dispatch(
      selectLine({
        start: selection?.getLineRange().start.row,
        end: selection?.getLineRange().end.row,
        fileId: file.id,
      })
    );

    if (
      `${selection?.getLineRange().start.row + 1}` !==
      `${searchParams.get("line")}`
    ) {
      searchParams.delete("char");
      searchParams.delete("line");

      setSearchParams(searchParams);
    }
  }, 200);
};

export default useUpdateSelectedLine;

import { TableSet } from "models/parser/Table";
import { Element } from "models/parser";
import Table from "./Table";

type Props = {
  element: Element;
  className?: string;
  table: TableSet;
  component?: any;
};

const TableSetComponent = ({ table, className, component }: Props) => {
  return (
    <>
      {table.children.map((tableId) => (
        <Table
          key={tableId}
          tableId={tableId}
          className={className}
          component={component}
        />
      ))}
    </>
  );
};

export default TableSetComponent;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Select,
  SelectChangeEvent,
  DialogTitle,
  InputLabel,
  MenuItem,
  FormControl,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import Language, { getCurrentLanguage, languages } from "models/language";
import {
  getFilterLanguage,
  setSearchLanguage,
  shouldUseLocalParser,
  setUseLocalParser,
  getLocalParserVersion,
  getRemoteParserVersion,
  parse,
  getTableLevelMax,
  setTableLevelMax,
} from "store";
import ParserVersion from "./ParserVersion";
import useCurrentProject from "hooks/useCurrentProject";

type Props = {
  open: boolean;
  onClose: () => void;
};

const availableLanguages = [{ language: "EN", name: "English" }];

const Settings = ({ open, onClose }: Props) => {
  const { t, i18n } = useTranslation();

  const onUserInterfaceLangugageChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value as Language);
  };

  const currentUserInterfaceLanguage = getCurrentLanguage(i18n);

  const dispatch = useAppDispatch();

  const onParserLanguageChange = (event: SelectChangeEvent) => {
    const newLanguage = event.target.value;
    dispatch(setSearchLanguage(newLanguage));
  };

  const currentParserLanguage = useAppSelector(getFilterLanguage);

  const useLocalParser = useAppSelector(shouldUseLocalParser);

  const { project, version } = useCurrentProject();

  const onUseLocalParserChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setUseLocalParser(event.target.value === "local"));
    if (project) {
      dispatch(parse({ project, version }));
    }
  };

  const localParserVersion = useAppSelector(getLocalParserVersion);
  const remoteParserVersion = useAppSelector(getRemoteParserVersion);

  const tableLevelMax = useAppSelector(getTableLevelMax);

  const onChangeTableLevelMax = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setTableLevelMax(parseInt(event.target.value, 10)));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("_Settings")}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            padding: 1,
          }}
        >
          {t("_Languages")}
          <Box
            sx={{
              minWidth: 500,
              padding: 2,
              paddingLeft: 20,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl>
              <InputLabel id="language">{t("_Language Interface")}</InputLabel>
              <Select
                labelId="language"
                id="language"
                value={currentUserInterfaceLanguage}
                label={t("_Language Interface")}
                onChange={onUserInterfaceLangugageChange}
              >
                {availableLanguages.map(({ language, name }) => (
                  <MenuItem key={language} value={language}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: 2 }}>
              <InputLabel id="language">
                {t("_Language ParserOutput")}
              </InputLabel>
              <Select
                labelId="language"
                id="language"
                value={currentParserLanguage}
                label={t("_Language ParserOutput")}
                onChange={onParserLanguageChange}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.id} value={lang.id}>
                    {lang.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            padding: 1,
          }}
        >
          {t("_Tables visualization")}
          <Box
            sx={{
              minWidth: 500,
              padding: 2,
              paddingLeft: 20,
            }}
          >
            <FormControl fullWidth>
              <TextField
                id="levelOfMax "
                label={t("_levels max of table")}
                variant="outlined"
                type="number"
                value={tableLevelMax}
                onChange={onChangeTableLevelMax}
                fullWidth
              />
            </FormControl>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            padding: 1,
          }}
        >
          {t("_Choices of parser")}
          <Box
            sx={{
              minWidth: 500,
              padding: 2,
              paddingLeft: 20,
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="local"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="local"
                  control={
                    <Radio
                      checked={useLocalParser}
                      onChange={onUseLocalParserChange}
                    />
                  }
                  label={
                    <ParserVersion
                      label={t("_Local")}
                      version={localParserVersion}
                      description={t("_will run in the browser")}
                    />
                  }
                />
                <FormControlLabel
                  value="remote"
                  control={
                    <Radio
                      checked={!useLocalParser}
                      onChange={onUseLocalParserChange}
                    />
                  }
                  label={
                    <ParserVersion
                      label={t("_Remote")}
                      version={remoteParserVersion}
                      description={t("_will run on the server")}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("_Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Settings;

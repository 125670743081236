import styles from "./ParserVersion.module.scss";

type Props = {
  label: string;
  version: string;
  description: string;
};

const ParserVersion = ({ label, version, description }: Props) => {
  return (
    <div className={styles.label}>
      <div>
        <span>{label}</span> <span>({version})</span>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default ParserVersion;

import Language from "models/language";

export type PersistedState = {
  language: Language;
  currentBottomTab: string | null;
  bottomPanelHeight: number;
  currentActivity: "files" | "concepts" | "publish";
  useLocalParser: boolean;
  tableLevelMax: number;
};

/**
 * inspired from https://medium.com/@jrcreencia/persisting-redux-state-to-local-storage-f81eb0b90e7e
 */

export const loadState = (): undefined | PersistedState => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState) as PersistedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: PersistedState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};

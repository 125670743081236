import { uniq } from "lodash";

export function insertUniq<T>(
  array: Array<T>,
  element: T | Array<T>
): Array<T> {
  if (Array.isArray(element)) {
    return uniq([...array, ...element]);
  }

  return uniq([...array, element]);
}

export function reverse<T>(
  acc: Array<T>,
  item: T,
  index: number,
  array: Array<T>
): Array<T> {
  return index !== array.length - 1 ? array : array.reverse();
}

export function nothing<T>(
  acc: Array<T>,
  item: T,
  index: number,
  array: Array<T>
): Array<T> {
  acc[index] = item;

  return acc;
}

import React, { useState, useRef } from "react";
import {
  TextField,
  Select,
  SelectChangeEvent,
  InputLabel,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { languages } from "models/language";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getFilters, setSearchFilters } from "store";
import { scopeList } from "models/filters";

import styles from "./SearchBar.module.scss";

export type SearchInput = {
  search: string;
  langue: string;
};

const SearchBar = () => {
  const { language, query, scope } = useAppSelector(getFilters);

  const [realTimeQuery, setQuery] = useState(query);
  const searchBar = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const onLanguageChange = (event: SelectChangeEvent) => {
    const newLanguage = event.target.value;
    dispatch(
      setSearchFilters({ query: realTimeQuery, language: newLanguage, scope })
    );
  };

  const onSearchQueryChange = (newQuery: string) => {
    setQuery(newQuery);
    dispatch(setSearchFilters({ query: newQuery, language, scope }));
  };

  const onScopeChange = (event: SelectChangeEvent) => {
    const newScope = event.target.value;
    dispatch(
      setSearchFilters({ query: realTimeQuery, language, scope: newScope })
    );
  };

  const { t } = useTranslation();

  return (
    <>
      <div className={styles.SearchBar} ref={searchBar}>
        <TextField
          size="small"
          label={t("_Search")}
          variant="outlined"
          className={styles.input}
          name="search"
          placeholder={t("_Search")}
          value={realTimeQuery}
          onChange={(evt) => onSearchQueryChange(evt.target.value)}
          autoFocus
        />
        <FormControl className={styles.select} size="small">
          <InputLabel id="choose-language">{t("_Language")}</InputLabel>
          <Select
            labelId="choose-language"
            value={language}
            label={t("_Language")}
            onChange={onLanguageChange}
          >
            {languages.map((lang) => (
              <MenuItem key={lang.id} value={lang.id}>
                {lang.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles.onlyCurrentFile}>
        <FormControl className={styles.select} size="small" fullWidth>
          <Select value={scope} onChange={onScopeChange}>
            {scopeList.map((scope) => (
              <MenuItem key={scope.id} value={scope.id}>
                {t(scope.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
};

export default SearchBar;

import { useEffect, useRef } from "react";
import FileNode from "models/fileNode";

const useResetUndoManager = (realtimeFile: FileNode, editor: any) => {
  const shouldResetUndoManager = useRef(false);
  useEffect(() => {
    if (shouldResetUndoManager.current) {
      shouldResetUndoManager.current = false;
      if (editor.current) {
        editor.current.editor.session.getUndoManager().reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtimeFile.content]);

  const resetUndoManagerAfterLoading = () => {
    shouldResetUndoManager.current = true;
  };

  return { resetUndoManagerAfterLoading };
};

export default useResetUndoManager;

import { useState } from "react";
import { Tooltip, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";

import ExplorerHeader from "components/molecules/ExplorerHeader";
import Explorer from "components/molecules/Explorer";
import useCurrentProject from "hooks/useCurrentProject";
import { useAppSelector } from "hooks/redux";
import { getVersions } from "store";
import {
  versionToString,
  VERSION_LATEST,
  areVersionEqual,
} from "models/version";
import usePopup from "hooks/usePopup";
import PublishVersionDialog from "./dialogs/PublishVersionDialog";
import ErrorVersionDialog from "./dialogs/ErrorVersionDialog";
import styles from "./VersionsExplorer.module.scss";

type Props = {
  readOnly: boolean;
};

const VersionsExplorer = ({ readOnly }: Props) => {
  const { t } = useTranslation();

  const versionDialog = usePopup();

  const { currentProject, currentVersion } = useCurrentProject();

  let versions = useAppSelector(getVersions(currentProject.id));

  if (currentVersion === VERSION_LATEST) {
    versions = [...versions, VERSION_LATEST];
  }

  const [error, setError] = useState("");

  const onError = (error: string) => {
    versionDialog.close();
    setError(error);
  };

  return (
    <Explorer>
      <ExplorerHeader title={t("_Versions")}>
        {!readOnly && (
          <Tooltip title={t("_Publish New Version") || ""} placement="bottom">
            <BookmarkAddIcon
              onClick={versionDialog.open}
              titleAccess="Versions"
              fontSize="small"
            />
          </Tooltip>
        )}
      </ExplorerHeader>
      <div className={styles.versions}>
        <div className={styles.content}>
          {versions.reverse().map((version, index) => (
            <div className={styles.version} key={versionToString(version)}>
              <span className={styles.versionLabel}>
                {versionToString(version)}
              </span>
              {areVersionEqual(currentVersion, version) && (
                <CheckOutlinedIcon fontSize="small" />
              )}
            </div>
          ))}
          {versions.length === 0 && (
            <div className={styles.noVersionContainer}>
              <div className={styles.noVersion}>
                {t("_No versions available")}
              </div>
              <Button
                size="small"
                variant="contained"
                endIcon={<BookmarkAddIcon />}
                onClick={versionDialog.open}
              >
                {t("_Publish New Version")}
              </Button>
            </div>
          )}
        </div>
      </div>
      <PublishVersionDialog
        open={versionDialog.isOpen}
        onClose={versionDialog.close}
        onError={onError}
      />
      <ErrorVersionDialog error={error} onClose={() => setError("")} />

      {/* <VersionsDialog
        open={versionsIsOpen}
        handleClose={() => setVersionsIsOpen(false)}
      /> */}
    </Explorer>
  );
};

export default VersionsExplorer;

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { getElement } from "store";
import { isScript, Script, isPhrase, Phrase } from "models/parser";
import useCurrentProject from "hooks/useCurrentProject";
import TableContext from "contexts/Table.context";
import NodeItem from "./NodeItem";

import ScriptTableItem from "./ScriptTableItem";
import styles from "./TableItem.module.scss";

type Props = {
  elementId: string;
  className?: string;
  hideTableLink?: boolean;
  title?: string;
  isHeader?: boolean;
};

const TableItem = ({
  elementId,
  hideTableLink,
  title,
  isHeader = false,
}: Props) => {
  const { projectId } = useCurrentProject();

  const element = useAppSelector((state) =>
    getElement(state, projectId, elementId)
  );

  const { t } = useTranslation();

  const tableContext = useContext(TableContext);

  if (!element) {
    return <div className={styles.button}>{t("_No translation")}</div>;
  }

  if (tableContext?.item) {
    const Component = tableContext?.item;
    return (
      <Component
        isHeader={isHeader}
        element={element}
        className={styles.TableItem}
      />
    );
  }

  if (isScript(element)) {
    return (
      <ScriptTableItem
        className={styles.TableItem}
        script={element as Script}
        projectId={projectId}
        hideTableLink={hideTableLink}
        title={title}
        isHeader={isHeader}
      />
    );
  }

  if (isPhrase(element)) {
    return (
      <NodeItem
        className={styles.TableItem}
        element={element as Phrase}
      ></NodeItem>
    );
  }

  return <span>not managed yet</span>;
};

export default TableItem;

import { flatten, omitBy } from "lodash";
import api, { HttpError } from "utils/api";
import Project from "./project";
import Version, { versionToString } from "./version";

type HttpFileNode = {
  _id: {
    $oid: string;
  };

  project: {
    $oid: string;
  };

  // if this file node is a regular file or a folder
  // is_folder: boolean;
  is_folder: boolean;

  // the absolute path to this file node
  // contains "name" as its last item
  path: string;

  // the file node name.
  name: string;

  // the content of the file node.
  // if the file node is a folder, this is empty string ""
  content: string;

  // the version of this file node
  version: Version;

  children?: HttpFileNode[];
};

export type FileNode = {
  // _id: {
  //   $oid: string;
  // };

  id: string;
  projectId: string;

  // project: {
  //   $oid: string;
  // };

  // if this file node is a regular file or a folder
  // is_folder: boolean;
  isFolder: boolean;

  // the absolute path to this file node
  // contains "name" as its last item
  path: string;

  // the file node name.
  name: string;

  // the content of the file node.
  // if the file node is a folder, this is empty string ""
  content: string;

  // the version of this file node
  version: Version;

  childrenIds: string[];
};

export default FileNode;

export const getType = (fileNode: FileNode): "file" | "folder" =>
  fileNode.isFolder ? "folder" : "file";

// export type ProjectFiles = {
//   project: Project;
//   version: Version;

//   tree: FileNode;
//   id_to_file: { [id: string]: FileNode };
// };

// function _flattenFilesTree(tree: FileNode): { [id: string]: FileNode } {
//   let res: { [id: string]: FileNode } = {};
//   res[tree._id.$oid] = tree;
//   for (let folder of tree.children)
//     res = { ...res, ..._flattenFilesTree(folder) };

//   return res;
// }

export function byAlphabeticalPath(a: FileNode, b: FileNode) {
  const fullPathA = getFullPath(a);
  const fullPathB = getFullPath(b);

  if (fullPathA < fullPathB) {
    return -1;
  }
  if (fullPathA > fullPathB) {
    return 1;
  }
  return 0;
}

export const getFullPath = (fileNode: FileNode) => {
  if (fileNode?.path) {
    return `${fileNode.path}/${fileNode.name}`;
  }
  return fileNode?.name || "";
};

export const extractPathAndName = (fullPath: string) => {
  let simplifiedFullPath = fullPath;
  if (simplifiedFullPath.endsWith("/")) {
    simplifiedFullPath = simplifiedFullPath.slice(0, -1);
  }

  const lastIndex = simplifiedFullPath.lastIndexOf("/");

  if (lastIndex === -1) {
    return {
      path: "",
      name: simplifiedFullPath,
    };
  }

  return {
    path: simplifiedFullPath.slice(0, lastIndex),
    name: simplifiedFullPath.slice(lastIndex + 1),
  };
};

export function isParent(parentNode: FileNode, childNode: FileNode) {
  return getFullPath(parentNode) === childNode.path;
}

export function isDescendant(childNode: FileNode, parentNode: FileNode) {
  return childNode.path.startsWith(getFullPath(parentNode));
}

const convertFromHttp = ({
  _id,
  project,
  children,
  is_folder,
  version,
  ...others
}: HttpFileNode): FileNode => ({
  ...others,
  version,
  id: _id.$oid || `root_${versionToString(version)}_${project.$oid}`,
  isFolder: is_folder,
  projectId: project.$oid,
  childrenIds: children ? children.map(({ _id }) => _id.$oid) : [],
});

type FileNodes = Array<FileNode>;

const convertFromHttpRecursive = (httpFileNode: HttpFileNode): FileNodes => {
  return [
    convertFromHttp(httpFileNode),
    ...flatten(
      httpFileNode.children
        ? httpFileNode.children.map(convertFromHttpRecursive)
        : []
    ),
  ];
};

export type FileNodeFetch = {
  project: Project;
  version: Version;
};

export async function fetch(fileNodeFetch: FileNodeFetch): Promise<FileNodes> {
  const params = {
    flat: false,
    version: versionToString(fileNodeFetch.version),
  };
  const url = `/project/${fileNodeFetch.project.id}/files`;
  const response = await api().get(url, { params });

  return convertFromHttpRecursive(response.data);
}

export async function fetchPublished(
  fileNodeFetch: FileNodeFetch
): Promise<FileNodes> {
  const params = {
    flat: false,
    version: versionToString(fileNodeFetch.version),
  };
  const url = `/project/${fileNodeFetch.project.id}/files/published`;
  const response = await api().get(url, { params });

  return convertFromHttpRecursive(response.data);
}

export type FileNodeCreate = {
  project: Project;
  path: string;
  name: string;
  isFolder: boolean;
  content?: string;
};

export async function create(payload: FileNodeCreate): Promise<FileNodes> {
  const url = `/project/${payload.project.id}/files`;
  const data = {
    file_name: payload.name,
    file_path: payload.path,
    is_folder: payload.isFolder,
    file_content: payload.content || "",
  };
  const response = await api()
    .post(url, data)
    .catch((error) => {
      throw new HttpError(error.response.data.error);
    });

  return convertFromHttpRecursive(response.data);
}

export type FileNodeUpdate = {
  file: FileNode;
  path?: string;
  name?: string;
  content?: string;
};

export async function update(payload: FileNodeUpdate): Promise<FileNodes> {
  const url = `/project/${payload.file.projectId}/files/${payload.file.id}`;
  const data = omitBy(
    {
      file_name: payload.name,
      file_path: payload.path,
      file_content: payload.content,
    },
    (v) => v === undefined
  );
  try {
    const response = await api().put(url, data);
    return convertFromHttpRecursive(response.data);
  } catch (e: any) {
    throw new Error(e.response.data.error);
  }
}

export async function deleteOne(file: FileNode) {
  const url = `/project/${file.projectId}/files/${file.id}`;
  await api().delete(url);
}

import { createSelector } from "@reduxjs/toolkit";

import {
  CategoryHierarchyDefinition,
  Phrase,
  ParserOutput,
  isTableSet,
  TableSet,
  isTableCell,
  Cell,
  isTableNd,
  TableNd,
  findParentNode,
} from "models/parser";

import { RootState } from "store";
import { getParserOutputInternal } from "../parserOutputs.slice";

const isInTable = (
  parserOutput: ParserOutput | null,
  tableId: string,
  nodeId: string
): boolean => {
  const table = parserOutput?.tables?.[tableId];

  if (!table) {
    return false;
  }

  if (isTableSet(table)) {
    return (table as TableSet).children.some((t) =>
      isInTable(parserOutput, t, nodeId)
    );
  }

  if (isTableCell(table)) {
    return (table as Cell).title === nodeId;
  }

  if (isTableNd(table)) {
    const tableNd = table as TableNd;

    if (table.n_dim === 1) {
      return tableNd.cells
        .map((cell) => cell[0][0])
        .some((cell) => cell === nodeId);
    }

    if (table.n_dim === 2) {
      return tableNd.cells.some((line) =>
        line.map((line) => line[0]).some((c) => c === nodeId)
      );
    }

    return false;
  }

  return false;
};

export const findParent = createSelector(
  getParserOutputInternal,
  (state: RootState, projectId: string, table: CategoryHierarchyDefinition) =>
    table,
  (
    state: RootState,
    projectId: string,
    table: CategoryHierarchyDefinition,
    nodeId: string
  ) => nodeId,
  (parserOutput, table, nodeId): string | undefined => {
    return findParentNode(table, ({ paradigmId }) => {
      const parentParadigm = parserOutput?.elements[paradigmId] as Phrase;

      if (parentParadigm) {
        return isInTable(parserOutput, parentParadigm?.table || "", nodeId);
      }

      return false;
    });
  }
);

export const getCurrentTablePath = (
  state: RootState,
  projectId: string,
  table: CategoryHierarchyDefinition,
  current: string
): string[] => {
  if (table.root === current) {
    return [table.root];
  }

  const path = [current];

  let parent = findParent(state, projectId, table, current);

  while (parent !== table.root && parent) {
    path.unshift(parent);
    parent = findParent(state, projectId, table, parent);
  }

  if (parent) {
    return [parent, ...path];
  }

  return path;
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import type { RootState } from "store";
import Version, * as versionApi from "models/version";
import { getParserVersion } from "utils/localParser";
import { logout, login } from "../users.slice";

export interface ParserState {
  localParserVersion: Version | undefined;
  remoteParserVersion: Version | undefined;
}

export const parserInitialState: ParserState = {
  localParserVersion: undefined,
  remoteParserVersion: undefined,
};

export const fetchRemoteParserVersion = createAsyncThunk(
  "parser/fetchRemoteVersion",
  async (): Promise<Version> => {
    return await versionApi.fetchRemoteParserVersion();
  }
);

export const fetchLocalParserVersion = createAsyncThunk(
  "parser/fetchLocalVersion",
  async (): Promise<Version> => {
    return versionApi.stringToVersion(await getParserVersion());
  }
);

export const parserSlice = createSlice({
  name: "parserOutputs",
  initialState: parserInitialState,
  reducers: {
    setLocalParserVersion: (state, action) => {
      state.localParserVersion = action.payload;
    },
    setRemoteParserVersion: (state, action) => {
      state.remoteParserVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRemoteParserVersion.fulfilled, (state, action) => {
      state.remoteParserVersion = action.payload;
    });
    builder.addCase(fetchLocalParserVersion.fulfilled, (state, action) => {
      state.localParserVersion = action.payload;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      Object.assign(state, parserInitialState);
    });

    builder.addCase(login.fulfilled, (state, action) => {
      Object.assign(state, parserInitialState);
    });
  },
});

export default parserSlice.reducer;

export const getLocalParserVersion = (state: RootState) =>
  state.parser.localParserVersion
    ? versionApi.versionToString(state.parser.localParserVersion)
    : "undefined";

export const getRemoteParserVersion = (state: RootState) =>
  state.parser.remoteParserVersion
    ? versionApi.versionToString(state.parser.remoteParserVersion)
    : "undefined";

import { useAppDispatch } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";
import WithTranslations from "models/parser/WithTranslations";
import { getParserOutput, searchChildren } from "store";
import { useAppSelector } from "hooks/redux";
import { getTranslations } from "models/language";

import styles from "./SearchChildrenLink.module.scss";

type Props = {
  element: WithTranslations;
  className?: string;
  children: React.ReactNode;
};

const SearchChildrenLink = ({ element, className, children }: Props) => {
  const { projectId } = useCurrentProject();

  const dispatch = useAppDispatch();
  const parserOutput = useAppSelector(getParserOutput(projectId));

  const translation = getTranslations(element, parserOutput?.language);

  const onClick = () => {
    if (parserOutput) {
      dispatch(searchChildren(`#${translation.defaultTranslation.label}`));
    }
  };

  if (!translation.defaultTranslation.label) {
    return null;
  }

  return (
    <div className={`${styles.Link} ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default SearchChildrenLink;

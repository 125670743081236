import { getTranslations } from "models/language";
import { useAppSelector } from "hooks/redux";
import { getFilters } from "store";
import WithTranslations from "models/parser/WithTranslations";

type Props = {
  element: WithTranslations;
};

const Translation = ({ element }: Props) => {
  const { language } = useAppSelector(getFilters);

  return <>{getTranslations(element, language).defaultTranslation.label}</>;
};

export default Translation;

import useCurrentProject from "hooks/useCurrentProject";
import {
  TableSet,
  TableNd,
  isTableCell,
  isTableNd,
  isTableSet,
} from "models/parser/Table";
import { useAppSelector } from "hooks/redux";
import { getTable, getElement } from "store";
import TableSetComponent from "./TableSet";

type Props = {
  tableId: string;
  className?: string;
  component?: any;
};

const Table = ({ tableId, className, component: Component }: Props) => {
  const { projectId } = useCurrentProject();

  const table = useAppSelector((state) => getTable(state, projectId, tableId));

  const element = useAppSelector((state) =>
    getElement(state, projectId, table?.title || "")
  );

  if (!table) {
    return <>impossible to find the table {tableId}</>;
  }

  if (!element) {
    return <>impossible to find the element {table?.title}</>;
  }

  return (
    <>
      {isTableCell(table) && "Should not be displayed"}
      {isTableNd(table) && (
        <Component
          element={element}
          className={className}
          table={table as TableNd}
        />
      )}
      {isTableSet(table) && (
        <TableSetComponent
          element={element}
          className={className}
          table={table as TableSet}
          component={Component}
        />
      )}
    </>
  );
};

export default Table;

import { useEffect, useState } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./ProjectDialogs.module.scss";
import SelectVersion from "components/organisms/SelectVersion";
import Expander from "components/atoms/Expander";
import BasicDialog from "components/molecules/BasicDialog";

import Form from "components/atoms/Form";
import { Version } from "models/version";
import { Project } from "models/project";
import { routes } from "models/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { createProject, getVersions, getDictionary } from "store";

type Props = {
  open: boolean;
  onClose: () => void;
};

const validateName = (name: string) =>
  name.length === 0
    ? { errorMessage: "_Project name cannot be empty", isError: true }
    : { errorMessage: "", isError: false };

export const CreateProjectDialog = ({ open, onClose }: Props) => {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedVersion, setSelectedVersion] = useState<Version>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setName("");
    setErrorMessage("");
    setSelectedVersion(undefined);
  }, [open]);

  const onCreateProject = async () => {
    setErrorMessage("");

    if (!selectedVersion) return;

    let createdProject: Project;
    try {
      const toCreate = {
        name,
        dictionaryVersion: selectedVersion,
      };
      createdProject = await dispatch(createProject(toCreate)).unwrap();
      navigate(generatePath(routes.editor, { projectId: createdProject.id }));
    } catch (e) {
      setErrorMessage((e as Error).message);
      return;
    }

    onClose();
  };

  const dictionary = useAppSelector(getDictionary);

  const dictionaryVersions = useAppSelector(getVersions(dictionary?.id));

  const { t } = useTranslation();

  if (dictionaryVersions.length === 0) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className={styles.title}>{t("_New Project")}</DialogTitle>
        <DialogContent>
          {t("_Impossible to create a new project")}
        </DialogContent>
        <DialogActions>
          <Expander />
          <Button onClick={onClose} variant="contained" color="primary">
            {t("_Close")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const nameValidation = validateName(name);

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={t("_New Project")}
      onSubmit={onCreateProject}
      actionTitle={t("_Create")}
      disabled={nameValidation.isError || !!errorMessage}
    >
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          onCreateProject();
        }}
      >
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t("_Name")}
            type="text"
            fullWidth
            error={nameValidation.isError || !!errorMessage}
            helperText={
              nameValidation.isError
                ? t(nameValidation.errorMessage)
                : errorMessage
            }
            value={name}
            onChange={(evt) => setName(evt.target.value)}
          />
        </FormControl>

        <SelectVersion
          selectedVersion={selectedVersion}
          onChange={setSelectedVersion}
        />
      </Form>
    </BasicDialog>
  );
};

export default CreateProjectDialog;

import ParserOutput from "models/parser/ParserOutput";

type Identifier = {
  value: string;
};

export default Identifier;

export const findIdentifiers = (parserOutput: ParserOutput) => {
  const language = parserOutput.language;

  return Object.values(parserOutput.elements)
    .flatMap((element) => element.translations[language])
    .map((translation) => ({ value: translation }));
};

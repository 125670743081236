import ParserOutput from "models/parser/ParserOutput";
import FileNode from "models/fileNode";

let worker: any = null;
let initialized = false;

let isRunning = false;

type Callbacks = {
  resolve: any;
  reject: any;
};

const callbacks: { [id: string]: Callbacks } = {};
const defaultCallback = () => {};

type Action = {
  id?: string;
  type: string;
  payload?: any;
};

function sendAction<T>(action: Action): Promise<T> {
  return new Promise((resolve, reject) => {
    const id = `${Math.floor(Math.random() * 1000000000)}`;

    const newAction = { id, ...action };

    callbacks[newAction.id] = { resolve, reject };

    worker.postMessage(newAction);
  });
}

const init = async () => {
  await sendAction({ type: "init" });
  initialized = true;
};

const setup = async () => {
  if (!window?.Worker) {
    throw Error("Web worker not supported");
  }

  worker = new Worker("/parser_wasm.worker.js");

  worker.onmessage = (event: any) => {
    const { type, payload, id } = event.data;

    if (type === "loaded") {
      const resolveCallback = callbacks[id]?.resolve || defaultCallback;
      resolveCallback();
    } else if (type === "success") {
      const resolveCallback = callbacks[id]?.resolve || defaultCallback;
      resolveCallback(payload);
    } else if (type === "error") {
      const rejectCallback = callbacks[id]?.reject || defaultCallback;
      rejectCallback();
    } else {
      throw Error("Expected loaded event !");
    }
  };
};

setup();

export const parseWithLocalParser = async (
  files: FileNode[]
): Promise<ParserOutput> => {
  isRunning = true;
  console.log("start local parser");
  if (!initialized) {
    await init();
  }
  const parserOut = await sendAction({ type: "parse_project", payload: files });
  isRunning = false;
  console.log("stop local parser");
  return parserOut as ParserOutput;
};

export const getParserVersion = async (): Promise<string> => {
  if (!initialized) {
    await init();
  }
  return await sendAction<string>({ type: "version" });
};

export const isLocalParserRunning = () => isRunning;

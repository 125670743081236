import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";
import { logout, login } from "./users.slice";

import { importProjectFromZip } from "./importExport.actions";

export interface ImportExportState {
  isImporting: boolean;
}

export const importExportInitialState: ImportExportState = {
  isImporting: false,
};

export const importExportSlice = createSlice({
  name: "importExport",
  initialState: importExportInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(importProjectFromZip.pending, (state) => {
      state.isImporting = true;
    });
    builder.addCase(importProjectFromZip.fulfilled, (state) => {
      state.isImporting = false;
    });
    builder.addCase(importProjectFromZip.rejected, (state) => {
      state.isImporting = false;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      Object.assign(state, importExportInitialState);
    });

    builder.addCase(login.fulfilled, (state, action) => {
      Object.assign(state, importExportInitialState);
    });
  },
});

export default importExportSlice.reducer;

export const isImporting = (state: RootState) => state.importExport.isImporting;

import { without } from "lodash";

import Version, { versionToString } from "models/version";
import FileNode, { getFullPath } from "models/fileNode";
import { insertUniq } from "utils/array";
import { FileNodesState } from "./fileNodes.slice";

export const getFilesIdsInProject = (
  state: FileNodesState,
  projectId: string,
  version: Version
) => {
  const versionString = versionToString(version);

  return state.byProjectIdAndVersion[projectId][versionString].fileNodesIds;
};

export const getFile = (state: FileNodesState) => (fileId: string) =>
  state.byId[fileId];

export const addFileToParent = (
  state: FileNodesState,
  parentNode: FileNode,
  file: FileNode
) => {
  state.byId[parentNode.id].childrenIds = insertUniq(
    state.byId[parentNode.id].childrenIds,
    file.id
  );
};

export const removeFileFromParent = (
  state: FileNodesState,
  parentNode: FileNode,
  file: FileNode
) => {
  state.byId[parentNode.id].childrenIds = without(
    state.byId[parentNode.id].childrenIds,
    file.id
  );
};

export const moveFile = (
  state: FileNodesState,
  file: FileNode,
  previousParentNode: FileNode,
  newParentNode: FileNode
) => {
  state.byId[file.id].path = state.byId[file.id].path.replace(
    getFullPath(previousParentNode),
    getFullPath(newParentNode)
  );
};

import { useAppSelector } from "hooks/redux";
import { useParams, useSearchParams } from "react-router-dom";

import Project from "models/project";

import { getProjectById } from "store";
import { Version, VERSION_LATEST, stringToVersion } from "models/version";

type ProjectContextType = {
  currentProject: Project;
  projectId: string;
  currentVersion: Version;
  project: Project;
  version: Version;
};

const defaultProject = {
  id: "",
  name: "",
  owner: "",
  slug: "",
  isDictionary: false,
  dictionaryVersion: { major: 0, minor: 0, patch: 0 },
};

const useCurrentProject = (): ProjectContextType => {
  const { projectId } = useParams();

  const [searchParams] = useSearchParams();

  const currentProject =
    useAppSelector(getProjectById(projectId)) || defaultProject;

  const currentVersion = stringToVersion(
    searchParams.get("version") || VERSION_LATEST
  );

  return {
    currentProject,
    projectId: projectId || "",
    currentVersion,
    project: currentProject,
    version: currentVersion,
  };
};

export default useCurrentProject;

import { useEffect, useState } from "react";
import { IMarker } from "react-ace";

import FileNode from "models/fileNode";
import ParserOutput from "models/parser/ParserOutput";

import PositionedMessage from "models/parser/PositionedMessage";

import styles from "../IEMLEditor.module.scss";

const createMarker = (error: PositionedMessage): IMarker => ({
  startRow: error.range.line_start - 1,
  endRow: error.range.line_end - 1,
  startCol: error.range.char_start,
  endCol: error.range.char_end,
  type: "text",
  className: styles.marker,
});

const useMarkers = (
  parserResult: ParserOutput | undefined,
  file: FileNode
): IMarker[] | undefined => {
  const [markers, setMarkers] = useState<IMarker[]>([]);

  // doesn't work with useMemo for no specic reason
  useEffect(() => {
    if (parserResult && "errors" in parserResult!) {
      const errors = parserResult.errors.filter(
        (error) => error.range.file_id === file.id
      );

      setMarkers(errors.map(createMarker));
    }
  }, [parserResult, file]);

  return markers;
};

export default useMarkers;

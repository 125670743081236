export type Role = "user" | "admin";

export default Role;

export const toString = (role: Role): string => {
  switch (role) {
    case "user":
      return "_user";
    case "admin":
      return "_administrator";
    default:
      throw new Error(`Unknown role: ${role}`);
  }
};

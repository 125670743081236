import { Button } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { useTranslation } from "react-i18next";

import { CreateFileOrFolderDialog } from "components/organisms/FilesListDialogs/CreateFileOrFolderDialog";
import usePopup from "hooks/usePopup";

const CreateFileOrFolder = () => {
  const createFileDialog = usePopup();
  const createFolderDialog = usePopup();

  const { t } = useTranslation();

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        endIcon={<NoteAddIcon />}
        onClick={createFileDialog.open}
      >
        {t("_File")}
      </Button>
      <Button
        size="small"
        variant="contained"
        endIcon={<CreateNewFolderIcon />}
        onClick={createFolderDialog.open}
        sx={{ marginLeft: 1 }}
      >
        {t("_Folder")}
      </Button>

      <CreateFileOrFolderDialog
        open={createFileDialog.isOpen}
        onClose={createFileDialog.close}
        type={"file"}
      />
      <CreateFileOrFolderDialog
        open={createFolderDialog.isOpen}
        onClose={createFolderDialog.close}
        type={"folder"}
      />
    </div>
  );
};

export default CreateFileOrFolder;

import { Fragment } from "react";
import TableViewIcon from "@mui/icons-material/TableView";
import { useTranslation } from "react-i18next";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import SearchIcon from "@mui/icons-material/Search";
import LinkIcon from "@mui/icons-material/Link";

import useCurrentProject from "hooks/useCurrentProject";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { getEditorContextMenuItems, setCurrentBottomTab } from "store";

import RelationshipTranslation from "components/molecules/RelationshipTranslation";
import ConceptLink from "components/atoms/ConceptLink";
import TableLink from "components/atoms/TableLink";
import EditorContextMenuItem from "./EditorContextMenuItem";
import ParanodeTableLink from "components/atoms/ParanodeTableLink";

import styles from "./EditorContextMenu.module.scss";
import SearchChildrenLink from "components/atoms/SearchChildrenLink/SearchChildrenLink";

type Props = {
  event: any;
  onHide: () => void;
};

const SCRIPT_MAX_LENGTH = 20;

const cutScript = (script: string) =>
  script.length > SCRIPT_MAX_LENGTH + 3
    ? `${script.substring(0, SCRIPT_MAX_LENGTH)} ...`
    : script;

const EditorContextMenu = ({ event, onHide }: Props) => {
  const position = { top: event.clientY + 20, left: event.clientX };

  const { projectId } = useCurrentProject();

  const items = useAppSelector((state) =>
    getEditorContextMenuItems(state, projectId || "")
  );

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const changeTab = (tab: string) => () => {
    dispatch(setCurrentBottomTab(tab));
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <div
      className={styles.Menu}
      style={position}
      onClick={(evt) => evt.stopPropagation()}
      onContextMenu={(evt) => {
        evt.preventDefault();
        evt.stopPropagation();
      }}
    >
      {items.map(({ type, script, instance, table, element }) => (
        <Fragment key={`${type}-${script?.id}`}>
          {type === "open-table" && script && script.table && (
            <TableLink tableId={script.table} className={styles.button}>
              <EditorContextMenuItem icon={<TableViewIcon />}>
                {t("_Open Table")}
              </EditorContextMenuItem>
            </TableLink>
          )}
          {type === "go-to-root-paradigm" && script && (
            <ConceptLink element={script} className={styles.button}>
              <EditorContextMenuItem icon={<ArrowUpwardIcon />}>
                {t("_Go to root paradigm")}
                <span className={styles.script}>{cutScript(script.str)}</span>
              </EditorContextMenuItem>
            </ConceptLink>
          )}
          {type === "link" && instance && (
            <EditorContextMenuItem
              button={false}
              icon={
                <LinkIcon
                  className={styles.iconButton}
                  onClick={changeTab("relationships")}
                />
              }
            >
              {t("_Relation")}
              {` `}
              <RelationshipTranslation instance={instance} />
            </EditorContextMenuItem>
          )}
          {type === "open-paranode-table" && table && (
            <ParanodeTableLink tableRoot={table.root} table={table.root}>
              <EditorContextMenuItem icon={<TableViewIcon />}>
                {t("_Open paranode table")}
              </EditorContextMenuItem>
            </ParanodeTableLink>
          )}
          {type === "search-children-node" && element && (
            <SearchChildrenLink element={element}>
              <EditorContextMenuItem icon={<SearchIcon />}>
                {t("_Search Children")}
              </EditorContextMenuItem>
            </SearchChildrenLink>
          )}
        </Fragment>
      ))}

      {/* <div className={styles.close}>
        <button className={styles.closeButton} onClick={onHide}>
          {t("_close context menu")}
        </button>
      </div> */}
    </div>
  );
};

export default EditorContextMenu;

import { useEffect, useState, SyntheticEvent } from "react";
import { FormControl, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

import ErrorMessage from "components/atoms/ErrorMessage";
import { useAppDispatch } from "hooks/redux";
import SelectVersion from "components/organisms/SelectVersion";
import Form from "components/atoms/Form";
import Project, { ProjectUpdate } from "models/project";
import { Version } from "models/version";
import { updateProject } from "store";
import BasicDialog from "components/molecules/BasicDialog";

type Props = {
  project: Project;
  open: boolean;
  onClose: () => void;
};

export const UpdateProjectDialog = ({ project, open, onClose }: Props) => {
  const [newName, setNewName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedVersion, setSelectedVersion] = useState<Version>(
    project.dictionaryVersion
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    setNewName(project.name);
    setSelectedVersion(project.dictionaryVersion);

    setErrorMessage("");
  }, [project, open]);

  // TODO: simplify error management
  const update = async (event: SyntheticEvent) => {
    event.preventDefault();

    setErrorMessage("");

    if (newName && selectedVersion) {
      try {
        const toUpdate: ProjectUpdate = {
          id: project.id,
          name: newName,
          dictionaryVersion: selectedVersion,
        };
        await dispatch(updateProject(toUpdate)).unwrap();
        onClose();
      } catch (e) {
        if ((e as Error).message === "non-existent dictionary version") {
          // setIsVersionError(true);
        }
        if (new RegExp(/^Already exists/).test((e as Error).message)) {
          setErrorMessage("Name already taken.");
        }
        return;
      }
    } else {
      setErrorMessage("Name can not be empty.");
    }
  };

  const { t } = useTranslation();

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={t("_Update Project")}
      onSubmit={update}
      actionTitle={t("_Save")}
    >
      <Form onSubmit={update}>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            error={!!errorMessage}
            helperText={errorMessage}
            value={newName}
            onChange={(evt) => setNewName(evt.target.value)}
          />
        </FormControl>

        <SelectVersion
          selectedVersion={selectedVersion}
          onChange={setSelectedVersion}
        />
        <ErrorMessage message={errorMessage} />
      </Form>
    </BasicDialog>
  );
};

import React, { Suspense } from "react";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import reportWebVitals from "reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import App from "components/App";
import "index.scss";
import store from "store";
import "utils/localParser";
// import { initLocalParser } from "utils/localParser";

import "./utils/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <BrowserRouter>
        <Provider store={store}>
          <CookiesProvider>
            <DndProvider backend={HTML5Backend}>
              <App />
            </DndProvider>
          </CookiesProvider>
        </Provider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// initLocalParser();

import { Chip, Stack } from "@mui/material";

import styles from "./BottomPanel.module.scss";

type Props = {
  title: string;
  indicator?: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
};

const Tab = ({ title, indicator, isActive, onClick }: Props) => {
  return (
    <button
      className={`${styles.tab} ${isActive ? styles.active : ""}`}
      onClick={onClick}
    >
      {title}
      {indicator ? (
        <div className={styles.indicators}>
          <Stack direction="row" spacing={1}>
            <Chip label={indicator} size="small" />
          </Stack>
        </div>
      ) : null}
    </button>
  );
};

export default Tab;

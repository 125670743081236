import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { getSingularSequences, getNumberOFiles } from "store";
import useCurrentProject from "hooks/useCurrentProject";

import styles from "./StatsDialog.module.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

const StatsDialog = ({ open, onClose }: Props) => {
  const { projectId } = useCurrentProject();

  const singularSequences = useAppSelector((state) =>
    getSingularSequences(state, projectId)
  );

  const filesCount = useAppSelector(getNumberOFiles(projectId));

  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={styles.title}>Stats</DialogTitle>
      <DialogContent>
        <ul className={styles.list}>
          <li>
            {t("_Singular sequences")}: {singularSequences.length}
          </li>
          <li>{t("_Number of files:", { filesCount })}</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("_Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatsDialog;

import { useState, MouseEvent } from "react";
import {
  ListItemText,
  ListItemIcon,
  Divider,
  Avatar,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import useCurrentUser from "hooks/useCurrentUser";
import { logout } from "store";
import { useAppDispatch } from "hooks/redux";
import Settings from "components/organisms/Settings";
import About from "components/organisms/UserMenu/About";
import { stringAvatar } from "utils/avatars";
import usePopup from "hooks/usePopup";
import { getRole } from "utils/permissions";
import * as Role from "models/role";

import styles from "./UserMenu.module.scss";

// cf https://mui.com/components/menus/#AccountMenu.tsx
const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const user = useCurrentUser();

  const settingsPopup = usePopup();
  const aboutPopup = usePopup();

  const { t } = useTranslation();

  if (!user) {
    return null;
  }

  return (
    <>
      <Tooltip title="Account">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 0.5, padding: 0 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <div className={styles.avatar}>
            <div className={styles.label}>
              <div className={styles.username}>{user?.username}</div>
              <div className={styles.role}>
                {t(Role.toString(getRole(user)))}
              </div>
            </div>
            <Avatar {...stringAvatar(user)} />
          </div>{" "}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={settingsPopup.open}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("_Settings")}</ListItemText>
        </MenuItem>
        {user.is_admin && (
          <MenuItem
            onClick={() => {
              navigate("/users");
            }}
          >
            <ListItemIcon>
              <PeopleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("_Manage Users")}</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={aboutPopup.open}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("_About")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("_Logout")}</ListItemText>
        </MenuItem>
      </Menu>
      <Settings open={settingsPopup.isOpen} onClose={settingsPopup.close} />
      <About open={aboutPopup.isOpen} onClose={aboutPopup.close} />
    </>
  );
};

export default UserMenu;

import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, TextField, Stack, Button, Paper } from "@mui/material";

import Form from "components/atoms/Form";
import { useAppDispatch } from "hooks/redux";
import { login, setWithoutAccount } from "store";
import ErrorMessage from "components/atoms/ErrorMessage";
import styles from "./LoginForm.module.scss";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  async function handleSubmit(event: FormEvent) {
    try {
      await dispatch(login({ username, password })).unwrap();
      navigate("/");
    } catch (error) {
      setErrorMessage("Invalid credentials. Username and/or password is bad.");
    }
  }

  async function readWithoutAccount() {
    dispatch(setWithoutAccount(true));
    navigate("/");
  }

  const { t } = useTranslation();

  return (
    <Paper>
      <Box sx={{ padding: 5, maxWidth: 350 }}>
        <div className={styles.brand}>
          <div className={styles.title}>
            IEML <span className={styles.editor}>EDITOR</span>
          </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField
              autoFocus
              margin="dense"
              label={t("_Username")}
              type="text"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              label={t("_Password")}
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
            <ErrorMessage message={errorMessage} />
            <Button type="submit" variant="contained">
              {t("_Login")}
            </Button>
          </Stack>
        </Form>

        <Box
          sx={{
            paddingTop: 2,
            paddingBottom: 2,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: 1, height: "1px", background: "#ccc" }}></Box>
          <Box sx={{ paddingLeft: 1, paddingRight: 1, color: "#aaa" }}>
            {t("_or")}
          </Box>
          <Box sx={{ flex: 1, height: "1px", background: "#ccc" }}></Box>
        </Box>

        <Stack spacing={2}>
          <Button
            type="button"
            variant="contained"
            onClick={readWithoutAccount}
          >
            {t("_Read without account")}
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default LoginForm;

import { Link } from "react-router-dom";
import BasicPage from "components/templates/BasicPage";

import styles from "./Error404.module.scss";

const Error404 = () => {
  return (
    <BasicPage>
      <div className={styles.message}>
        <h2 className="page-title">Erreur 404. Page non trouvée.</h2>
        <Link to="/">Back to known world</Link>
      </div>
    </BasicPage>
  );
};

export default Error404;

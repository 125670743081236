import axios from "axios";
import { getJwtToken } from "utils/auth";

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL || window.location.origin}/api`,
});

// https://stackoverflow.com/questions/43051291/attach-authorization-header-for-all-axios-requests
instance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = `Bearer ${getJwtToken()}`;
    config.headers.Public = localStorage.getItem("withoutaccount")
      ? "yes"
      : "no";
  }

  return config;
});

const api = () => instance;

export default api;

export class HttpError extends Error {
  constructor(message: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, HttpError.prototype);
  }
}

import { configureStore } from "@reduxjs/toolkit";
import { throttle } from "lodash";

import users from "./users.slice";
import projects from "./projects.slice";
import parserOutputs from "./parserOutputs.slice";
import versions from "./versions.slice";
import fileNodes from "./fileNodes.slice";
import parser from "./parser/parser.slice";
import settings, {
  mergeWithInitialState,
  getStateToPersist,
} from "./settings.slice";
import importExport from "./importExport.slice";
import { loadState, saveState } from "utils/localStorage";

export const reducer = {
  users,
  projects,
  parserOutputs,
  versions,
  fileNodes,
  settings,
  parser,
  importExport,
};

const persistedState = loadState();

const initialSettingsState = mergeWithInitialState(persistedState);

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableStateInvariant: false, //SerializableStateInvariantMiddleware took 66ms, which is more than the warning threshold of 32ms.
      serializableCheck: false, //
    }),
  preloadedState: { settings: initialSettingsState },
});

store.subscribe(
  throttle(() => {
    saveState(getStateToPersist(store.getState()));
  }, 1000)
);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;

export * from "./users.slice";
export * from "./projects.slice";
export * from "./parserOutputs.slice";
export * from "./versions.slice";
export * from "./fileNodes.slice";
export * from "./settings.slice";
export * from "./parser/instances.selectors";
export * from "./parser/parser.slice";
export * from "./importExport.slice";
export * from "./importExport.actions";
export * from "./saveAndParse.actions";
export * from "./parser/paths.selectors";

import { useMemo } from "react";

import TableItem from "components/molecules/TableItem";

import RowTransform, { noTransform } from "./RowTransform";
import styles from "./Table.module.scss";

type Table1DimensionProps = {
  cells: string[][][];
  transform?: RowTransform;
};

const Table1Dimension = ({
  cells,
  transform = noTransform,
}: Table1DimensionProps) => {
  const lines = useMemo(
    () => transform(cells.map((cell) => cell[0][0])),
    [cells, transform]
  );

  return (
    <tbody>
      <tr>
        {lines.map((cell) => (
          <td key={cell} className={styles.td}>
            <TableItem elementId={cell} />
          </td>
        ))}
      </tr>
    </tbody>
  );
};

export default Table1Dimension;

import { useEffect, useRef } from "react";

import FileNode from "models/fileNode";
import useCurrentProject from "hooks/useCurrentProject";
import { useAppDispatch } from "hooks/redux";
import useDebounce from "hooks/useDebounce";

import { saveAndParse } from "store";

const useSave = (file: FileNode, realtimeFile: FileNode) => {
  const { currentProject } = useCurrentProject();

  const dispatch = useAppDispatch();

  const hasUnsavedChanges = useRef(false);

  // save on close
  useEffect(() => {
    return () => {
      if (file && realtimeFile.content !== file.content) {
        // in case the user change the page very quickly
        dispatch(saveAndParse(realtimeFile, currentProject));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prepareSaving = () => {
    hasUnsavedChanges.current = true;
  };

  const saveOnFileChange = async () => {
    try {
      if (file && hasUnsavedChanges.current) {
        dispatch(saveAndParse(realtimeFile, currentProject));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveRealtime = useDebounce(async (fileId: string, content: string) => {
    if (fileId !== file.id || !currentProject) {
      // in case the file changed
      return;
    }

    await dispatch(saveAndParse({ ...realtimeFile, content }, currentProject));

    hasUnsavedChanges.current = false;
  }, 1000);

  return { saveRealtime, saveOnFileChange, prepareSaving };
};

export default useSave;

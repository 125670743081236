import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Project from "models/project";
import { useAppDispatch } from "hooks/redux";
import { deleteProject } from "store";
import BasicDialog from "components/molecules/BasicDialog";

type Props = {
  project: Project;
  open: boolean;
  onClose: () => void;
};

export const DeleteProjectDialog = ({ project, open, onClose }: Props) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const askToDelete = async () => {
    try {
      await dispatch(deleteProject(project.id));
      onClose();
      navigate(`/`);
    } catch (error) {
      console.error(error);
    }
  };

  const { t } = useTranslation();

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={t("_Delete")}
      onSubmit={askToDelete}
      actionTitle={t("_Delete")}
      color="error"
    >
      {t("_Do you really want to delete the project", {
        projectName: project.name,
      })}
    </BasicDialog>
  );
};

import { Element, Script } from "models/parser";
import Filters from "models/filters";

import ScriptsMap from "models/scriptsMap";
import { simplifyScript } from "utils/script";

export const onlyIn = (fileIds: string[]) => (e: Element) =>
  fileIds.includes(e.range.file_id);

export const onlyInSelectedFile =
  (fileId: string | undefined) => (e: Element) =>
    fileId ? e.range.file_id === fileId : true;

type ElementWithReferences = Element & {
  referenceObjs: Element[];
};

type ElementWithScore = Element & {
  score: number;
};

const isExactMatch = (a: string, b: string) =>
  a.toLowerCase() === b.toLowerCase();

const includeWord = (a: string, b: string) => {
  const a_ = a.toLowerCase();
  const b_ = b.toLowerCase();

  return (
    a_.includes(` ${b_} `) || a_.startsWith(`${b_} `) || a_.endsWith(` ${b_}`)
  );
};

const includeAsSubstring = (a: string, b: string) =>
  a.toLowerCase().includes(b.toLowerCase());

export const computeRelevanceScore = (
  script: Script | null,
  query: string,
  translations: string[],
  referencesTranslations: string[]
) => {
  if (query.startsWith("*") && script) {
    const simplifiedScript = simplifyScript(script.str);

    const simplifiedQuery = simplifyScript(query.slice(1));

    if (simplifiedScript === simplifiedQuery) {
      return 10;
    }

    if (simplifiedScript.includes(simplifiedQuery)) {
      return 2;
    }
  }

  if (query.startsWith("#")) {
    const subQuery = query.slice(1);

    if (translations.some((t) => isExactMatch(t, subQuery))) {
      return 10;
    }

    if (referencesTranslations.some((t) => isExactMatch(t, subQuery))) {
      return 5;
    }

    if (translations.some((t) => t.startsWith(subQuery))) {
      return 2;
    }

    if (referencesTranslations.some((t) => t.startsWith(subQuery))) {
      return 1;
    }
  }

  if (script && isExactMatch(script.str, query)) {
    return 10;
  }

  if (script && includeAsSubstring(script.str, query)) {
    return 2;
  }

  if (translations.some((t) => isExactMatch(t, query))) {
    return 10;
  }

  if (translations.some((t) => includeWord(t, query))) {
    return 5;
  }

  if (translations.some((t) => includeAsSubstring(t, query))) {
    return 2;
  }

  if (!query) {
    return 1;
  }

  return 0;
};

export const addRelevanceScore =
  (scriptsByIds: ScriptsMap, filters: Filters) =>
  (element: ElementWithReferences): ElementWithScore => {
    const { query, language } = filters;

    const script = scriptsByIds[element.id];

    const score = computeRelevanceScore(
      script,
      query,
      element.translations[language] || [],
      element.referenceObjs.flatMap((e) => e.translations[language] || [])
    );

    return {
      ...element,
      score,
    };
  };

export const removeRelevanceScore = ({
  score,
  ...element
}: ElementWithScore): Element => element;

export const onlyRelevant = (element: ElementWithScore) => {
  return element.score > 0;
};

export const byRelevance =
  (filters: Filters) =>
  (elementA: ElementWithScore, elementB: ElementWithScore) => {
    return filters.query ? elementB.score - elementA.score : 0;
  };

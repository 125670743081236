import { Chip, CircularProgress, Stack } from "@mui/material";

import styles from "./SectionLabel.module.scss";

type Props = {
  label: string;
  number: number;
  isLoading?: Boolean;
};

const SectionLabel = ({ label, number, isLoading = false }: Props) => {
  return (
    <div className={styles.label}>
      {label}
      <div className={styles.tags}>
        {isLoading ? (
          <CircularProgress size={15} color="inherit" />
        ) : (
          <Stack direction="row" spacing={1}>
            <Chip label={number} size="small" />
          </Stack>
        )}
      </div>
    </div>
  );
};

export default SectionLabel;

import Table from "components/atoms/Table";
import TableNDimensions from "./NodeTableNDimensions";

type Props = {
  tableId: string;
  className?: string;
};

const NodeTable = ({ tableId, className }: Props) => {
  return (
    <Table
      tableId={tableId}
      className={className}
      component={TableNDimensions}
    />
  );
};

export default NodeTable;

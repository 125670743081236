import TableViewIcon from "@mui/icons-material/TableView";

import ConceptButton from "components/atoms/ConceptButton";
import useSelectedParanodeTable from "hooks/useSelectedParanodeTable";
import { Phrase } from "models/parser";

import styles from "./TableItem.module.scss";
import ParanodeTableLink from "components/atoms/ParanodeTableLink";

type Props = {
  element: Phrase;
  className?: string;
};

const NodeItem = ({ element, className }: Props) => {
  const { table } = useSelectedParanodeTable();

  const prevent = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <ConceptButton className={styles.content} script={null} element={element}>
      {table &&
        table.invariant_mapping[element.id] &&
        table.root !== element.id && (
          <div className={styles.openParanodeTable} onClick={prevent}>
            <ParanodeTableLink table={table.root} tableRoot={element.id}>
              <TableViewIcon />
            </ParanodeTableLink>
          </div>
        )}
    </ConceptButton>
  );
};

export default NodeItem;

import { ReactNode } from "react";

import TableItem from "components/molecules/TableItem";
import RowTransform, { noTransform } from "components/atoms/Table/RowTransform";
import styles from "components/atoms/Table/Table.module.scss";

type Table2DimensionProps = {
  headers: string[][];
  cells: string[][][];
  thirdDimensionIndex?: number;
  thirdDimensionHeader?: ReactNode;
  transform?: RowTransform;
};

const ParanodeTable2Dimensions = ({
  headers,
  cells,
  thirdDimensionIndex = 0,
  thirdDimensionHeader = "",
  transform = noTransform,
}: Table2DimensionProps) => {
  return (
    <>
      <tbody>
        {transform(
          headers[0].map((header, index) => ({ header, index })),
          (a) => a.header
        ).map(({ header, index }) => (
          <tr key={header}>
            {transform(
              cells[index].map((cell) => cell[thirdDimensionIndex])
            ).map((cell) => (
              <td key={cell} className={styles.td}>
                <TableItem elementId={cell} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default ParanodeTable2Dimensions;

import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import type { RootState } from "store";
import User, * as UserApi from "models/user";
import { saveJwtToken, clearJwtToken, getJwtToken } from "utils/auth";

export interface UsersState {
  currentUser: User | null;
  isFetched: Boolean;
  byId: Record<string, User>;
  listOfIds: string[];
  areAllFetched: boolean;
  withoutAccount: boolean;
}

export const usersInitialState: UsersState = {
  currentUser: null,
  isFetched: false,
  byId: {},
  listOfIds: [],
  areAllFetched: false,
  withoutAccount: localStorage.getItem("withoutaccount") ? true : false,
};

export const login = createAsyncThunk(
  "users/login",
  async (args: UserApi.UserLoginInfo): Promise<User> => {
    localStorage.removeItem("withoutaccount");
    await clearJwtToken();
    const token = await UserApi.login(args);
    saveJwtToken(token);
    return await UserApi.fetchCurrentUser();
  }
);

export const checkSession = createAsyncThunk(
  "users/checkSession",
  async (): Promise<User> => {
    try {
      if (getJwtToken()) {
        return await UserApi.fetchCurrentUser();
      }
      throw new Error("no token");
    } catch (error) {
      await clearJwtToken();
      throw new Error("invalid token");
    }
  }
);

export const logout = createAsyncThunk("users/logout", async () => {
  await clearJwtToken();
});

export const fetchAllUsers = createAsyncThunk(
  "users/fetch",
  async (): Promise<Array<User>> => {
    return await UserApi.fetchAllUsers();
  }
);

export const createUser = createAsyncThunk(
  "users/create",
  async (userInfo: UserApi.UserSignupInfo): Promise<User> => {
    return UserApi.createUser(userInfo);
  }
);

export const deleteUser = createAsyncThunk(
  "users/delete",
  async (user: User) => {
    return UserApi.deleteUser(user);
  }
);

export const updateUser = createAsyncThunk(
  "users/update",
  async (userInfo: UserApi.UserUpdateInfo) => {
    return UserApi.updateUser(userInfo);
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: usersInitialState,
  reducers: {
    setWithoutAccount: (state, action) => {
      state.withoutAccount = action.payload;
      if (action.payload) {
        localStorage.setItem("withoutaccount", "true");
      } else {
        localStorage.removeItem("withoutaccount");
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isFetched = false;
      state.withoutAccount = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.isFetched = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.currentUser = null;
      state.isFetched = true;
    });

    builder.addCase(checkSession.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.isFetched = true;
    });
    builder.addCase(checkSession.rejected, (state) => {
      state.currentUser = null;
      state.isFetched = true;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.currentUser = null;
      state.isFetched = true;
    });

    builder.addCase(fetchAllUsers.rejected, (state) => {
      state.areAllFetched = true;
    });

    builder.addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
      state.listOfIds = payload.map((u) => u.id);
      state.byId = payload.reduce(
        (acc, user: User) => ({
          ...acc,
          [user.id]: user,
        }),
        {}
      );
      state.areAllFetched = true;
    });

    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.listOfIds.push(payload.id);
      state.byId[payload.id] = payload;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      const user = action.meta.arg;
      state.listOfIds = state.listOfIds.filter((id) => id !== user.id);
      delete state.byId[user.id];
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      const { id, is_admin, isDeactivated } = action.meta.arg;
      state.byId[id].is_admin = is_admin;
      state.byId[id].isDeactivated = isDeactivated;
    });
  },
});

export default usersSlice.reducer;

export const { setWithoutAccount } = usersSlice.actions;

export const getCurrentUser = (state: RootState): User | null =>
  state.users.currentUser;

export const isUserFetched = (state: RootState): Boolean =>
  state.users.isFetched;

export const getUsers = createSelector(
  (state: RootState) => state.users.listOfIds,
  (state: RootState) => state.users.byId,
  (listOfIds, byId) => listOfIds.map((id) => byId[id])
);

export const areAllUsersFetched = (state: RootState): Boolean =>
  state.users.areAllFetched;

export const accessWithoutAnAccount = (state: RootState): Boolean =>
  state.users.withoutAccount;

import { useEffect, useState, SyntheticEvent } from "react";
import {
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "hooks/redux";
import Form from "components/atoms/Form";
import User from "models/user";
import { updateUser } from "store";
import BasicDialog from "components/molecules/BasicDialog";

type Props = {
  user: User;
  open: boolean;
  onClose: () => void;
};

const validatePassword = (password: string) =>
  password.length < 8 && password.length > 0
    ? { message: "_Password length cannot be less than 8", isError: true }
    : { message: "_Keep empty to not update password", isError: false };

const validatePasswordConfirmation = (
  passwordConfirmation: string,
  password: string
) =>
  password !== passwordConfirmation
    ? {
        errorMessage:
          "_Password confirmation cannot be different from password",
        isError: true,
      }
    : { errorMessage: "", isError: false };

const UpdateUserDialog = ({ user, open, onClose }: Props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isAdmin, setIsAdmin] = useState(user.is_admin);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    setPassword("");
    setPasswordConfirmation("");
    setIsAdmin(user.is_admin);

    setErrorMessage("");
  }, [open, user]);

  // TODO: simplify error management
  const onUpdateUser = async (event: SyntheticEvent) => {
    event.preventDefault();
    setErrorMessage("");

    try {
      dispatch(updateUser({ ...user, password, is_admin: isAdmin })).unwrap();
      onClose();
    } catch (e) {
      console.log("e", e);
      setErrorMessage((e as Error).message);
      return;
    }

    onClose();
  };

  const { t } = useTranslation();

  const passwordValidation = validatePassword(password);
  const passwordConfirmationValidation = validatePasswordConfirmation(
    passwordConfirmation,
    password
  );

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={t("_Update User", { username: user.username })}
      onSubmit={onUpdateUser}
      actionTitle={t("_Save")}
      disabled={
        passwordValidation.isError ||
        passwordConfirmationValidation.isError ||
        !!errorMessage
      }
    >
      <Form onSubmit={onUpdateUser}>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <TextField
            margin="dense"
            id="password"
            label={t("_Password")}
            type="password"
            fullWidth
            error={passwordValidation.isError}
            helperText={t(passwordValidation.message)}
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <TextField
            margin="dense"
            id="password-confirmation"
            label={t("_Password confirmation")}
            type="password"
            fullWidth
            error={passwordConfirmationValidation.isError}
            helperText={t(passwordConfirmationValidation.errorMessage)}
            value={passwordConfirmation}
            onChange={(evt) => setPasswordConfirmation(evt.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAdmin}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIsAdmin(e.target.checked)
                }
              />
            }
            label={"Administrator"}
          />
        </FormControl>
      </Form>
    </BasicDialog>
  );
};

export default UpdateUserDialog;

import { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import BasicDialog from "components/molecules/BasicDialog";

import Form from "components/atoms/Form";
import { useAppDispatch } from "hooks/redux";
import { createUser } from "store";

type Props = {
  open: boolean;
  onClose: () => void;
};

const validateUsername = (username: string) =>
  username.length < 4
    ? { errorMessage: "_Username length cannot be less than 4", isError: true }
    : { errorMessage: "", isError: false };

const validatePassword = (password: string) =>
  password.length < 8
    ? { errorMessage: "_Password length cannot be less than 8", isError: true }
    : { errorMessage: "", isError: false };

const validatePasswordConfirmation = (
  passwordConfirmation: string,
  password: string
) =>
  password !== passwordConfirmation
    ? {
        errorMessage:
          "_Password confirmation cannot be different from password",
        isError: true,
      }
    : { errorMessage: "", isError: false };

export const CreateUserDialog = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    setUsername("");
    setPassword("");
    setPasswordConfirmation("");
    setErrorMessage("");
  }, [open]);

  const onCreateUser = async () => {
    setErrorMessage("");

    try {
      const toCreate = {
        username,
        password,
        is_admin: isAdmin,
      };
      await dispatch(createUser(toCreate)).unwrap();
      onClose();
    } catch (e) {
      console.log("e", e);
      setErrorMessage((e as Error).message);
      return;
    }
  };

  const usernameValidation = validateUsername(username);
  const passwordValidation = validatePassword(password);
  const passwordConfirmationValidation = validatePasswordConfirmation(
    passwordConfirmation,
    password
  );

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={t("_New User")}
      onSubmit={onCreateUser}
      actionTitle={t("_Create")}
      disabled={
        usernameValidation.isError ||
        passwordValidation.isError ||
        passwordConfirmationValidation.isError ||
        !!errorMessage
      }
    >
      <Form onSubmit={onCreateUser}>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t("_Username")}
            type="text"
            fullWidth
            error={usernameValidation.isError || !!errorMessage}
            helperText={
              usernameValidation.isError
                ? t(usernameValidation.errorMessage)
                : errorMessage
            }
            value={username}
            onChange={(evt) => setUsername(evt.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <TextField
            margin="dense"
            id="password"
            label={t("_Password")}
            type="password"
            fullWidth
            error={passwordValidation.isError}
            helperText={t(passwordValidation.errorMessage)}
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <TextField
            margin="dense"
            id="password-confirmation"
            label={t("_Password confirmation")}
            type="password"
            fullWidth
            error={passwordConfirmationValidation.isError}
            helperText={t(passwordConfirmationValidation.errorMessage)}
            value={passwordConfirmation}
            onChange={(evt) => setPasswordConfirmation(evt.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAdmin}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIsAdmin(e.target.checked)
                }
              />
            }
            label={"Administrator"}
          />
        </FormControl>
      </Form>
    </BasicDialog>
  );
};

export default CreateUserDialog;

import styles from "./CenterPage.module.scss";

type Props = {
  children: React.ReactNode;
};

const CenterPage = ({ children }: Props) => {
  return <div className={styles.CenterPage}>{children}</div>;
};

export default CenterPage;

import { createContext } from "react";

interface ParanodeTableContextInterface {
  level: number;
}

const ParanodeTableContext =
  createContext<ParanodeTableContextInterface | null>(null);

export default ParanodeTableContext;

import { useEffect } from "react";

import ProjectsList from "components/organisms/ProjectList";

import BasicPage from "components/templates/BasicPage";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { areVersionsFetched, getDictionary, fetchProjectVersions } from "store";

import styles from "./ProjectListPage.module.scss";

export const ProjectListPage = () => {
  const dictionary = useAppSelector(getDictionary);

  const versionsFetched = useAppSelector(
    areVersionsFetched(dictionary?.id || "")
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (dictionary && !versionsFetched) {
        await dispatch(fetchProjectVersions(dictionary));
      }
    })();
  }, [versionsFetched, dictionary, dispatch]);

  return (
    <BasicPage>
      <main className={styles.main}>
        <ProjectsList />
      </main>
    </BasicPage>
  );
};

export default ProjectListPage;

import { Box } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

interface Props {
  children: React.ReactNode;
}

const ReadOnlyBanner = ({ children }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "centers",
        padding: "0.2rem",
        paddingLeft: "1rem",
        fontSize: "0.9rem",
        color: "white",
        background: "black",
      }}
    >
      <LockIcon fontSize="small" />
      <Box sx={{ paddingLeft: "0.5rem" }}>{children}</Box>
    </Box>
  );
};

export default ReadOnlyBanner;

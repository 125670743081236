import Language from "models/language";

export enum Scopes {
  ALL = "all",
  CURRENT_FILE = "currentFile",
  CURRENT_PROJECT = "currentProject",
}

export const scopeList = [
  { id: Scopes.ALL, label: "_all" },
  { id: Scopes.CURRENT_PROJECT, label: "_current project only" },
  { id: Scopes.CURRENT_FILE, label: "_current file only" },
];

type Filters = { query: string; language: Language; scope: Scopes };

export default Filters;

import styles from "./EmptyEditor.module.scss";

type Props = {
  children: React.ReactNode;
};

const EmptyEditor = ({ children }: Props) => {
  return <div className={styles.EmptyEditor}>{children}</div>;
};

export default EmptyEditor;

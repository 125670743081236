import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import BookmarksIcon from "@mui/icons-material/Bookmarks";

import Activity from "models/Activity";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getCurrentActivity, setCurrentActivity } from "store";
import styles from "./ActivityBar.module.scss";

const activities = [
  {
    id: Activity.Files,
    icon: FileCopyOutlinedIcon,
    title: "Files",
  },
  {
    id: Activity.Concepts,
    icon: SearchIcon,
    title: "Concepts",
  },
  {
    id: Activity.Publish,
    icon: BookmarksIcon,
    title: "Versions",
    show: (readOnly: Boolean) => !readOnly,
  },
];

type Props = {
  readOnly: boolean;
};

const ActivityBar = ({ readOnly }: Props) => {
  const dispatch = useAppDispatch();

  const currentActivity = useAppSelector(getCurrentActivity);

  const onChooseActivity = (activity: string) => () => {
    dispatch(setCurrentActivity(activity));
  };

  return (
    <div className={styles.ActivityBar}>
      {activities
        .filter((a) => !a?.show || a?.show(readOnly))
        .map((activity) => (
          <div
            className={`${styles.Activity} ${
              currentActivity === activity.id ? styles.active : ""
            }`}
            key={activity.id}
          >
            <Tooltip title={activity.title} placement="right">
              <IconButton
                className={`${styles.ActivityButton} ${
                  currentActivity === activity.id ? styles.active : ""
                }`}
                onClick={onChooseActivity(activity.id)}
              >
                <activity.icon sx={{ fontSize: 25 }} />
              </IconButton>
            </Tooltip>
          </div>
        ))}
    </div>
  );
};

export default ActivityBar;

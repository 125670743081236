import { useState, SyntheticEvent, ReactNode } from "react";
import { TreeView } from "@mui/lab";
import { Box } from "@mui/material";
import { without } from "lodash";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import FileItem from "components/organisms/FileItem";
import { isImporting } from "store";
import "./FilesList.scss";

import FileNode from "models/fileNode";
import Project from "models/project";

type Props = {
  // the file tree to display
  files: FileNode[];

  // the file to display there are compilation errors
  erroneousFilesId?: string[];

  // the currently opened file in the editor
  selectedFileIds: string[];

  // the name of the project
  project: Project;

  // do we display the files in the folders ?
  folderOnly: boolean;

  // click on a file handler
  onFileNodeClick: (file: FileNode) => void;

  openContextMenu?: (event: SyntheticEvent, file: FileNode) => void;

  suffix?: string;

  forceExpandedRoot?: boolean;

  endIcon?: ReactNode;
};

export const FilesList = ({
  files,
  erroneousFilesId = [],
  selectedFileIds,
  project,
  onFileNodeClick,
  folderOnly = false,
  openContextMenu,
  suffix = "",
  forceExpandedRoot = false,
  endIcon = null,
}: Props) => {
  const rootFileIds = files.map((f) => f.id);

  // Expanded node ids. TreeView props
  const [expandedFolders, setExpandedFolders] = useState<string[]>(rootFileIds);

  const onClick = (file: FileNode) => {
    if (file.isFolder) {
      if (!rootFileIds.includes(file.id) || !forceExpandedRoot) {
        const newExpandedFolders = expandedFolders.includes(file.id)
          ? without(expandedFolders, file.id)
          : [...expandedFolders, file.id];

        setExpandedFolders(newExpandedFolders);
      }
    }

    onFileNodeClick(file);
  };

  const { t } = useTranslation();

  const isImportingNewFiles = useAppSelector(isImporting);

  return (
    <>
      <TreeView
        defaultCollapseIcon={<KeyboardArrowDown />}
        defaultExpandIcon={<KeyboardArrowRight />}
        expanded={expandedFolders}
        sx={{ flexGrow: 1, maxWidth: 400 }}
        selected={selectedFileIds}
      >
        {files.map((file) => (
          <FileItem
            key={file.id}
            file={file}
            erroneousFilesId={erroneousFilesId}
            selectedFileIds={selectedFileIds}
            project={project}
            folderOnly={folderOnly}
            onFileNodeClick={onClick}
            openContextMenu={openContextMenu}
            suffix={suffix}
            endIcon={endIcon}
          />
        ))}
        {isImportingNewFiles && (
          <Box
            sx={{
              padding: `0.2rem`,
              textAlign: "center",
              opacity: 0.5,
              fontSize: "0.9rem",
            }}
          >
            {t("_is importing")}
          </Box>
        )}
      </TreeView>
    </>
  );
};

export default FilesList;

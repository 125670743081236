import { useSearchParams } from "react-router-dom";

import { getParanodeTable, getElement } from "store";
import { useAppSelector } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";

const useSelectedParanodeTable = () => {
  let [searchParams] = useSearchParams();
  const { projectId } = useCurrentProject();

  const table = useAppSelector((state) =>
    getParanodeTable(state, projectId, searchParams.get("paranode_table") || "")
  );

  const tableRoot = useAppSelector((state) =>
    getElement(state, projectId, searchParams.get("paranode_table_root") || "")
  );

  return { table, tableRoot };
};

export default useSelectedParanodeTable;

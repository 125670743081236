import { useCallback } from "react";

import { TableNd } from "models/parser/Table";
import { Element } from "models/parser";
import { useAppSelector } from "hooks/redux";

import useCurrentProject from "hooks/useCurrentProject";
import { sortByIEML } from "utils/sort-by-ieml";
import { getScripts } from "store";
import { TableNDimensions } from "components/atoms/Table";

type Props = {
  element: Element;
  className?: string;
  table: TableNd;
};

const NodeTableNDimensions = ({ table, element }: Props) => {
  const { projectId } = useCurrentProject();

  const scriptsById = useAppSelector((state) => getScripts(state, projectId));

  const transform = useCallback(
    (line: string[], change = (foo: any) => foo) =>
      line.sort((a, b) => sortByIEML(scriptsById)(change(a), change(b))),
    [scriptsById]
  );

  return (
    <TableNDimensions transform={transform} table={table} element={element} />
  );
};

export default NodeTableNDimensions;

import { FormEvent, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onSubmit: (event: FormEvent) => void;
  className?: string;
};

const Form = ({ children, onSubmit, ...props }: Props) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit(event);
  };

  return (
    <form onSubmit={handleSubmit} {...props}>
      {children}
    </form>
  );
};

export default Form;

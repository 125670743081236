import { Routes, Route } from "react-router-dom";
import ProjectListPage from "components/pages/ProjectListPage/ProjectListPage";
import LoginPage from "components/pages/LoginPage/LoginPage";
import Error404 from "components/pages/Error404";
import EditorPage from "components/pages/EditorPage/EditorPage";
import ProjectPage from "components/templates/ProjectPage";
import UserListPage from "components/pages/UserListPage";

export const Router = () => {
  return (
    <Routes>
      {/* Private routes */}
      <Route path="/" element={<ProjectListPage />} />
      <Route path="/users" element={<UserListPage />} />
      <Route path=":projectId" element={<ProjectPage />}>
        <Route path="editor" element={<EditorPage />} />
        <Route path="editor/:fileId" element={<EditorPage />} />
      </Route>

      {/* Public routes */}
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

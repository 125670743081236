import { uniqBy } from "lodash";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

import type { RootState } from "store";
import * as ParserOutputApi from "models/parser";
import ParserOutput from "models/parser/ParserOutput";

import { PositionedMessage } from "models/parser/PositionedMessage";
import { CategoryHierarchyDefinition, orderByDefinition } from "models/parser";
import { Phrase, Word, Script, Element } from "models/parser/Element";
import Instance from "models/parser/Instance";
import { getFilesToParse, getFilesById } from "./fileNodes.slice";
import Project from "models/project";
import Table, { isTableCell } from "models/parser/Table";
import FileNode, { FileNodeUpdate } from "models/fileNode";
import { parseWithLocalParser, isLocalParserRunning } from "utils/localParser";
import RangeSelection from "models/RangeSelection";
import Range from "models/parser/Range";
import { getDictionary, getProjectById } from "./projects.slice";
import { shouldUseLocalParser } from "./settings.slice";
import {
  getFilters,
  getSelectedElementId,
  getSelectedRange,
} from "./settings.slice";
import Version from "models/version";
import ScriptsMap from "models/scriptsMap";
import {
  onlyIn,
  addRelevanceScore,
  onlyRelevant,
  byRelevance,
  removeRelevanceScore,
} from "utils/search";
import Identifier, { findIdentifiers } from "models/Indentifier";
import { logout, login } from "./users.slice";
import WithTranslations from "models/parser/WithTranslations";

interface ParserOutputByProject {
  projectId: string;
  parserOutput: ParserOutput;
  isProgressing?: boolean;
  scriptsById: ScriptsMap | null;
  identifiers: Identifier[];
}

export interface ParserOutputsState {
  byProjectId: Record<string, ParserOutputByProject>;
  localParserVersion: Version | undefined;
  remoteParserVersion: Version | undefined;
  isLocalParserRunning: boolean;
}

export const parserOutputsInitialState: ParserOutputsState = {
  byProjectId: {},
  localParserVersion: undefined,
  remoteParserVersion: undefined,
  isLocalParserRunning: false,
};

export const parseTemporary = createAsyncThunk<
  ParserOutput,
  FileNodeUpdate,
  { state: RootState }
>(
  "parserOutputs/parseOptimized",
  async (
    { file, content }: FileNodeUpdate,
    thunkApi
  ): Promise<ParserOutput> => {
    const state = thunkApi.getState() as RootState;
    const useLocalParser = shouldUseLocalParser(state);

    if (!useLocalParser) {
      throw new Error("Local parser is disabled");
    }

    if (isLocalParserRunning()) {
      console.log("cancel parsing because parser is already running");
      throw new Error("parser already running");
    }

    // ajouter dictionnaire
    let files = getFilesForLocalParser(state, file.projectId, file.version);

    files = files.map((f: FileNode): FileNode => {
      if (f.id === file.id) {
        return {
          ...f,
          content: content || "",
        };
      }

      return f;
    });

    const parserOutput = await parseWithLocalParser(files);
    return parserOutput;
  }
);

// https://redux-toolkit.js.org/usage/usage-with-typescript#createasyncthunk
export const parse = createAsyncThunk(
  "parserOutputs/parse",
  async (
    { project, version }: { project: Project; version: Version },
    thunkApi
  ): Promise<ParserOutput> => {
    const state = thunkApi.getState() as RootState;

    const useLocalParser = shouldUseLocalParser(state);

    if (isLocalParserRunning()) {
      console.log("cancel parsing because parser is already running");
      throw new Error("parser already running");
    }

    if (useLocalParser) {
      try {
        const files = getFilesForLocalParser(state, project.id, version);
        const parserOutput = await parseWithLocalParser(files);
        return parserOutput;
      } catch (error) {
        console.error("fail", error);
        // return await ParserOutputApi.parse(project);
      }
    }

    console.log("start online parser");
    const parserOutput = await ParserOutputApi.parse(project);
    console.log("succeed online");
    return parserOutput;
  }
);

const findScript = (
  element: Element,
  byId: { [id: string]: Element }
): Script | null => {
  if (isScript(element)) {
    return element as Script;
  }

  if (isWord(element)) {
    const word = element as Word;
    return byId[word.declaration] as Script;
  }

  if (isPhrase(element)) {
    const phrase = element as Phrase;
    const word = byId[phrase.wordId] as Word;

    if (word) {
      return findScript(word, byId);
    }
  }

  return null;
};

const findScripts = (parserOutput: ParserOutput) => {
  const byIds = parserOutput?.elements || {};

  const scriptsById = {} as ScriptsMap;

  const elementToFind = Object.values(byIds);

  const scripts = elementToFind.map((e) => ({
    id: e.id,
    script: findScript(e, byIds),
  }));

  scripts.forEach((s) => {
    scriptsById[s.id] = s.script;
  });

  return scriptsById;
};

export const parserOutputsSlice = createSlice({
  name: "parserOutputs",
  initialState: parserOutputsInitialState,
  reducers: {
    setLocalParserVersion: (state, action) => {
      state.localParserVersion = action.payload;
    },
    setRemoteParserVersion: (state, action) => {
      state.remoteParserVersion = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(parse.pending, (state, { meta }) => {
      const { project } = meta.arg;
      state.byProjectId[project.id] = {
        ...state.byProjectId[project.id],
        isProgressing: true,
      };
      state.isLocalParserRunning = true;
    });
    builder.addCase(parse.fulfilled, (state, action) => {
      const { project } = action.meta.arg;
      state.byProjectId[project.id] = {
        projectId: project.id,
        parserOutput: action.payload,
        isProgressing: false,
        scriptsById: findScripts(action.payload),
        identifiers: findIdentifiers(action.payload),
      };
      state.isLocalParserRunning = false;
    });
    builder.addCase(parse.rejected, (state, { meta }) => {
      const { project } = meta.arg;
      state.byProjectId[project.id] = {
        ...state.byProjectId[project.id],
        isProgressing: false,
      };
      state.isLocalParserRunning = false;
    });

    builder.addCase(parseTemporary.pending, (state, action) => {
      const { projectId } = action.meta.arg.file;
      state.byProjectId[projectId] = {
        ...state.byProjectId[projectId],
        isProgressing: true,
      };
      state.isLocalParserRunning = true;
    });
    builder.addCase(parseTemporary.fulfilled, (state, action) => {
      const { projectId } = action.meta.arg.file;
      state.byProjectId[projectId] = {
        projectId,
        parserOutput: action.payload,
        isProgressing: false,
        scriptsById: findScripts(action.payload),
        identifiers: findIdentifiers(action.payload),
      };
      state.isLocalParserRunning = false;
    });
    builder.addCase(parseTemporary.rejected, (state, action) => {
      const { projectId } = action.meta.arg.file;
      state.byProjectId[projectId] = {
        ...state.byProjectId[projectId],
        isProgressing: false,
      };
      state.isLocalParserRunning = false;
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      Object.assign(state, parserOutputsInitialState);
    });

    builder.addCase(login.fulfilled, (state, action) => {
      Object.assign(state, parserOutputsInitialState);
    });
  },
});

export default parserOutputsSlice.reducer;

export const { setLocalParserVersion, setRemoteParserVersion } =
  parserOutputsSlice.actions;

export const getParserOutputInternal = createSelector(
  (state: RootState) => state.parserOutputs.byProjectId,
  (state: RootState, projectId: string | null | undefined) => projectId,
  (byProjectId, projectId): ParserOutput | null =>
    byProjectId[projectId || ""]?.parserOutput
);

export const getParserOutput =
  (projectId: string | null | undefined) =>
  (state: RootState): ParserOutput | undefined =>
    state.parserOutputs.byProjectId[projectId || ""]?.parserOutput;

export const isParserRunning = (state: RootState): boolean =>
  state.parserOutputs.isLocalParserRunning;

export const getParserErrors =
  (projectId: string | null | undefined) =>
  (state: RootState): PositionedMessage[] =>
    getParserOutput(projectId)(state)?.errors || [];

export const getParserWarnings =
  (projectId: string | null | undefined) =>
  (state: RootState): PositionedMessage[] =>
    getParserOutput(projectId)(state)?.warnings || [];

export const getTables = (projectId: string) => (state: RootState) =>
  createSelector(
    (state: RootState) => state.parserOutputs.byProjectId,
    (state: RootState, projectId: string) => projectId,
    (byProjectId, projectId: string): Table[] => {
      return Object.values(byProjectId[projectId]?.parserOutput?.tables || {})
        .filter((t) => !isTableCell(t))
        .map((t) => t as Table);
    }
  )(state, projectId);

export const getTable = createSelector(
  getParserOutputInternal,
  (
    state: RootState,
    projectId: string | null | undefined,
    tableId: string | undefined | null
  ) => tableId,
  (parserOutput, tableId): Table | undefined =>
    parserOutput?.tables[tableId || ""]
);

export const getParanodeTable = createSelector(
  getParserOutputInternal,
  (
    state: RootState,
    projectId: string | null | undefined,
    tableRoot: string | undefined | null
  ) => tableRoot,
  (parserOutput, tableRoot): CategoryHierarchyDefinition | undefined =>
    parserOutput?.category_hierarchies.find((h) => h.root === tableRoot)
);

export const getFilesForLocalParser = (
  state: RootState,
  projectId: string,
  version: Version
) => {
  const project = getProjectById(projectId)(state);
  let files = getFilesToParse(state, projectId, version);

  if (!project?.isDictionary) {
    const dictionary = getDictionary(state);
    const dictionaryFiles = getFilesToParse(
      state,
      dictionary?.id || "",
      project?.dictionaryVersion
    );

    files = [...dictionaryFiles, ...files];
  }

  return files;
};

export const getElement = createSelector(
  getParserOutputInternal,
  (state: RootState, projectId: string, elementId: string | undefined | null) =>
    elementId,
  (parserOutput, elementId) => parserOutput?.elements[elementId || ""]
);

export const getElements = createSelector(
  getParserOutputInternal,
  (state: RootState, projectId: string, elementIds: string[]) => elementIds,
  (parserOutput, elementIds) =>
    elementIds.map((elementId) => parserOutput?.elements[elementId])
);

export const getSelectedElement = createSelector(
  getParserOutputInternal,
  getSelectedElementId,
  (parserOutput, selectedElementId) =>
    parserOutput?.elements[selectedElementId || ""]
);

const emptyObject = {};

export const getScripts = (state: RootState, projectId: string) =>
  state.parserOutputs.byProjectId[projectId]?.scriptsById || emptyObject;

const {
  isPhrase,
  isComponent,
  isNode,
  isParadigm,
  isScript,
  isWord,
  isAuxiliary,
  isInflection,
  isJunction,
  isCategory,
  isLink,
  isRootParadigm,
} = ParserOutputApi;

export const getSingularSequences = createSelector(
  getParserOutputInternal,
  (parserOutput) =>
    Object.values(parserOutput?.elements || {})
      .filter(isScript)
      .map((s) => s as Script)
      .filter((s) => s.multiplicity === 1)
);

export const searchConcepts = createSelector(
  getParserOutputInternal,
  getFilters,
  getScripts,
  getFilesById,
  (
    state: RootState,
    projectId: string,
    version: Version,
    fileIds: string[] | undefined
  ) => fileIds,
  (parserOutput, filters, scriptsById, filesById, fileIds) => {
    const byIds = parserOutput?.elements || {};

    const allElements = Object.values(byIds);

    const byDefinition = orderByDefinition(filesById);

    const elements = allElements
      .filter((e) => fileIds === undefined || onlyIn(fileIds)(e))
      .map((e) => ({
        ...e,
        referenceObjs:
          "references" in e
            ? e.references.map((referenceId) => byIds[referenceId])
            : [],
      }))
      .map(addRelevanceScore(scriptsById, filters))
      .filter(onlyRelevant)
      .sort(byDefinition)
      .sort(byRelevance(filters))
      .map(removeRelevanceScore);

    const phrases = elements.filter(isPhrase) as Phrase[];
    const words = elements.filter(isWord) as Word[];

    const rootParadigms = elements.filter(isRootParadigm) as Script[];

    return {
      rootParadigms: rootParadigms,
      phrases,
      components: phrases.filter(isComponent),
      nodes: phrases.filter(isNode),
      paradigms: phrases.filter(isParadigm),
      links: phrases.filter(isLink),
      words,
      auxiliaries: words.filter(isAuxiliary),
      inflections: words.filter(isInflection),
      junctions: words.filter(isJunction),
      categories: words.filter(isCategory),
    };
  }
);

const rootParadigmFirst = (a: Element, b: Element) => {
  if (isRootParadigm(a) && !isRootParadigm(b)) {
    return -1;
  }

  return 1;
};

const isInRange = (selectedRange: RangeSelection, range: Range) => {
  return selectedRange?.lineStart !== null &&
    selectedRange?.lineEnd !== null &&
    selectedRange?.fileId !== null
    ? range.line_start <= selectedRange.lineStart + 1 &&
        range.line_end >= selectedRange.lineEnd &&
        range.file_id === selectedRange.fileId
    : false;
};

export const getTargetElements = createSelector(
  getParserOutputInternal,
  getSelectedRange,
  (parserOutput, selectedRange) => {
    const elements = Object.values(parserOutput?.elements || {})
      .filter((element) => isInRange(selectedRange, element.range))
      .filter((element) => ("str" in element ? element.str !== "" : true))
      .sort(rootParadigmFirst);

    return elements;
  }
);

const emptyArray: Identifier[] = [];

export const getIdentifiers = (state: RootState, projectId: string) =>
  state.parserOutputs.byProjectId[projectId]?.identifiers || emptyArray;

export type ContextMenuItem = {
  type: string;
  script?: Script;
  instance?: Instance;
  table?: CategoryHierarchyDefinition;
  element?: WithTranslations;
  id: string;
};

export const getTargetParanodeTables = createSelector(
  getParserOutputInternal,
  getSelectedRange,
  (parserOutput, selectedRange) =>
    parserOutput?.category_hierarchies.filter((h) =>
      isInRange(selectedRange, h.range)
    )
);

export const getEditorContextMenuItems = createSelector(
  getParserOutputInternal,
  getTargetElements,
  getTargetParanodeTables,
  (parserOutput, targetElements, targetTables) => {
    const items: ContextMenuItem[] = [];

    if (!parserOutput) {
      return items;
    }

    const instances = Object.values(parserOutput.instances);

    targetElements.forEach((element) => {
      if (isScript(element) && isRootParadigm(element)) {
        const script = element as Script;

        items.push({
          type: "open-table",
          script,
          id: script.id,
        });
      }

      if (isWord(element)) {
        const word = element as Word;
        const script = parserOutput?.elements[word.declaration] as Script;

        const [rootParadigm] = Object.values(parserOutput?.elements || {})
          .filter(isRootParadigm)
          .map((e) => e as Script)
          .filter((e) => e.singular_sequences.includes(script.id));

        if (rootParadigm) {
          const script = rootParadigm as Script;

          items.push({
            type: "search-children-node",
            element: word,
            id: script.id,
          });

          items.push({
            type: "go-to-root-paradigm",
            script,
            id: script.id,
          });

          items.push({
            type: "open-table",
            script,
            id: script.id,
          });
        }
      }

      if (isPhrase(element)) {
        const phrase = element as Phrase;
        const word = parserOutput?.elements[phrase.wordId] as Word;

        items.push({
          type: "search-children-node",
          element: phrase,
          id: phrase.id,
        });

        if (word) {
          const script = parserOutput?.elements[word.declaration] as Script;

          const [rootParadigm] = Object.values(parserOutput?.elements || {})
            .filter(isRootParadigm)
            .map((e) => e as Script)
            .filter((e) => e.singular_sequences.includes(script.id));

          if (rootParadigm) {
            const script = rootParadigm as Script;
            items.push({
              type: "go-to-root-paradigm",
              script,
              id: script.id,
            });

            items.push({
              type: "open-table",
              script,
              id: script.id,
            });
          }
        }
      }

      items.push(
        ...instances
          .filter((instance: Instance) =>
            Object.values(instance.linkedWordIds).includes(element.id)
          )
          .map((i) => ({ type: "link", instance: i }))
          .map((i) => i as ContextMenuItem)
      );
    });

    targetTables?.forEach((table) => {
      items.push({
        type: "open-paranode-table",
        table,
        id: table.root,
      });
    });

    return uniqBy(items, (item) => `${item.type}-${item.id}`);
  }
);

// Tables
export type TableBase = {
  // unique and stable id for this table
  id: string;

  type: "CELL" | "TABLEND" | "TABLESET";

  // the type of the element id of the title, headers and cells attributes values (if present)
  element_type: "CATEGORY" | "SCRIPT";

  // element id of the title header of this table
  title: string;
};

// Cell are table for singular element
// there are needed because a tableset can have a sum with a singular script
// as an operand
// title is the element id of the content of the cell
export type Cell = TableBase & {
  type: "CELL";
};

export type TableNd = TableBase & {
  type: "TABLEND";

  // the number of dimension
  n_dim: 1 | 2 | 3;

  // shape of this table
  //       len(shape) == n_dim
  shape: number[];

  // object id of the elements in row, column and tab header
  //       len(headers) == n_dim
  //       i < n_dim => len(hearders[i]) == shape[i]
  //
  // NOTE VIEW: only display the headers when n_dim == 2 or 3
  //       n_dim == 1 => headers[0] == cells
  headers: string[][];

  // cells
  //     i < n_dim => len(cells[a][b]..i times..[c]) == shape[i]
  //
  //      n_dim == 1 => i < shape[0], len(cells[i]) == 1 and len(cells[i][0]) == 1 and
  //                                  cells[i][0][0] == headers[0][i] == title
  //      n_dim == 2 => i < shape[0], j < shape[1], len(cells[i][j]) == 1
  cells: string[][][];
};

export type TableSet = TableBase & {
  type: "TABLESET";

  // list of table id referencing Cell or TableNd tables
  children: string[];
};

export type Table = Cell | TableNd | TableSet;

export default Table;

export const isTableCell = (table: Table): table is Cell =>
  table.type === "CELL";

export const isTableSet = (table: Table): table is TableSet =>
  table.type === "TABLESET";

export const isTableNd = (table: Table): table is TableNd =>
  table.type === "TABLEND";

export const count = (table: Table | null | undefined): number => {
  if (table) {
    if (isTableSet(table)) {
      const tableSet = table as TableSet;

      return tableSet.children.length;
    }

    return 1;
  }

  return 0;
};

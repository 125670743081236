export type Routes = {
  projects: string;
  editor: string;
  editorWithFile: string;
  explorer: string;
};

export const routes: Routes = {
  projects: "/:projectId",
  editor: "/:projectId/editor",
  editorWithFile: "/:projectId/editor/:fileId",
  explorer: "/:projectId/explore",
};

export default Routes;

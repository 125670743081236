import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FilesList from "components/organisms/FilesList";
import { useAppSelector } from "hooks/redux";
import FileNode from "models/fileNode";
import { getChildrenFiles, getRootFile } from "store";
import useCurrentProject from "hooks/useCurrentProject";
import { routes } from "models/routes";
import { FileAndFolderContextMenu } from "components/organisms/FileAndFolderContextMenu/FileAndFolderContextMenu";
import { useContextMenu } from "hooks/useContextMenu";
import ExplorerHeader from "components/molecules/ExplorerHeader";
import Explorer from "components/molecules/Explorer";
import useRoutes from "hooks/useRoutes";
import CreateFileAndFolderActions from "components/organisms/CreateFileAndFolderActions";

type Props = {
  readOnly: boolean;
};

const FileExplorer = ({ readOnly }: Props) => {
  const navigate = useNavigate();

  const { generatePath } = useRoutes();

  const { currentProject, projectId, currentVersion } = useCurrentProject();

  const project = currentProject;

  const file = useAppSelector(getRootFile(projectId, currentVersion));
  const files = useAppSelector((state) =>
    file ? getChildrenFiles(state, file) : []
  );

  const { openContextMenu, closeContextMenu, isOpen, anchorEl, selectedFile } =
    useContextMenu();

  const { fileId } = useParams();

  const onFileNodeClick = (file: FileNode) => {
    if (!file.isFolder) {
      const path = generatePath(routes.editorWithFile, {
        projectId,
        fileId: file.id,
      });
      navigate(path);
    }
  };

  const selectedFileIds = fileId ? [fileId] : [];

  const { t } = useTranslation();

  return (
    <Explorer onContextMenu={(evt) => evt.preventDefault()}>
      <ExplorerHeader title={t("_Files")}>
        <CreateFileAndFolderActions readOnly={readOnly} />
      </ExplorerHeader>
      {file ? (
        <>
          <FilesList
            project={project}
            files={files}
            onFileNodeClick={onFileNodeClick}
            folderOnly={false}
            selectedFileIds={selectedFileIds}
            openContextMenu={openContextMenu}
          />
          {selectedFile && (
            <FileAndFolderContextMenu
              anchorEl={anchorEl}
              file={selectedFile}
              project={project}
              open={isOpen}
              onClose={closeContextMenu}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </Explorer>
  );
};

export default FileExplorer;

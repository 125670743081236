import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "store";
import Version, * as VersionApi from "models/version";
import Project from "models/project";
import { fetchProjects, addToPublicProjects } from "./projects.slice";
import { logout, login } from "./users.slice";

interface VersionsByProject {
  projectId: string;
  versions: Version[];
  areFetched?: boolean;
}

export interface VersionsState {
  byProjectId: Record<string, VersionsByProject>;
}

export const versionsInitialState: VersionsState = {
  byProjectId: {},
};

export const fetchProjectVersions = createAsyncThunk(
  "versions/fetch",
  async (project: Project): Promise<Array<Version>> => {
    return await VersionApi.fetchByProject(project);
  }
);

export const createProjectVersion = createAsyncThunk(
  "versions/create",
  async (args: VersionApi.VersionCreate, thunkApi): Promise<Version> => {
    const version = await VersionApi.create(args);

    thunkApi.dispatch(addToPublicProjects({ projectId: args.project.id }));

    return version;
  }
);

const initializeProjectIfNecessary = (
  state: VersionsState,
  project: Project
) => {
  if (!state.byProjectId[project.id]) {
    state.byProjectId[project.id] = {
      projectId: project.id,
      versions: [],
      areFetched: false,
    };
  }
};

export const versionsSlice = createSlice({
  name: "versions",
  initialState: versionsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProjectVersions.pending, (state, { meta }) => {
      const project = meta.arg;
      initializeProjectIfNecessary(state, project);
    });

    builder.addCase(fetchProjects.fulfilled, (state, { payload }) => {
      const { publicProjects } = payload;

      Object.keys(publicProjects.versions).forEach((projectId) => {
        state.byProjectId[projectId] = {
          projectId,
          versions: publicProjects.versions[projectId],
          areFetched: true,
        };
      });
    });

    builder.addCase(fetchProjectVersions.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const project = meta.arg;
      state.byProjectId[project.id].versions = payload;
      state.byProjectId[project.id].areFetched = true;
    });
    builder.addCase(fetchProjectVersions.rejected, (state, { meta }) => {
      const project = meta.arg;
      state.byProjectId[project.id].areFetched = true;
    });

    builder.addCase(createProjectVersion.fulfilled, (state, action) => {
      const { project } = action.meta.arg;
      initializeProjectIfNecessary(state, project);
      state.byProjectId[project.id].versions.unshift(action.payload);
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      Object.assign(state, versionsInitialState);
    });

    builder.addCase(login.fulfilled, (state, action) => {
      Object.assign(state, versionsInitialState);
    });
  },
});

export default versionsSlice.reducer;

export const getVersions =
  (projectId: string | undefined) =>
  (state: RootState): Version[] =>
    state.versions.byProjectId[projectId || ""]?.versions || [];

export const areVersionsFetched = (projectId: string) => (state: RootState) =>
  Boolean(state.versions.byProjectId[projectId]?.areFetched);

export const getLastestProjectVersion =
  (projectId: string | undefined) =>
  (state: RootState): Version | undefined => {
    const versions = getVersions(projectId)(state)
      .slice()
      .sort(VersionApi.compareVersion)
      .reverse();

    return versions[0];
  };

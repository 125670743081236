import useDebounce from "hooks/useDebounce";

import { useAppDispatch } from "hooks/redux";
import FileNode from "models/fileNode";
import { parseTemporary } from "store";

// TODO use throttle https://dmitripavlutin.com/react-throttle-debounce/
// https://css-tricks.com/debouncing-throttling-explained-examples/
const useParseRealtime = (file: FileNode, realtimeFile: FileNode) => {
  const dispatch = useAppDispatch();

  return useDebounce(async (fileId: string, content: string) => {
    if (fileId !== file.id) {
      // in case the file changed
      return;
    }

    try {
      await dispatch(parseTemporary({ file: realtimeFile, content })).unwrap();
    } catch (e) {
      console.error(e);
    }
  }, 1000);
};

export default useParseRealtime;

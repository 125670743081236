import { useTranslation } from "react-i18next";

import User from "models/user";
import { useAppDispatch } from "hooks/redux";
import { deleteUser } from "store";
import BasicDialog from "components/molecules/BasicDialog";

type Props = {
  user: User;
  open: boolean;
  onClose: () => void;
};

const DeleteUserDialog = ({ user, open, onClose }: Props) => {
  const dispatch = useAppDispatch();

  const askToDelete = async () => {
    try {
      await dispatch(deleteUser(user)).unwrap();
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const { t } = useTranslation();

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      title={t("_Delete")}
      onSubmit={askToDelete}
      actionTitle={t("_Delete")}
      color="error"
    >
      {t("_Do you really want to delete the user", {
        username: user.username,
      })}
    </BasicDialog>
  );
};

export default DeleteUserDialog;

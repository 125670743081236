import { useRef, useState, useEffect } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";

import styles from "./IEMLEditor.module.scss";

import FileNode from "models/fileNode";
import { useAppSelector } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";
import { getParserOutput, shouldUseLocalParser } from "store";

import EditorContextMenu from "./EditorContextMenu";
import useAnnotations from "./hooks/useAnnotations";
import useMarkers from "./hooks/useMarkers";
import useCompleters from "./hooks/useCompleters";
import useResizeEditor from "./hooks/useResizeEditor";
import useSessionMode from "./hooks/useSessionMode";
import useUpdateSelectedLine from "./hooks/useUpdateSelectedLine";
import useContextMenu from "./hooks/useContextMenu";
import useParseRealtime from "./hooks/useParseRealtime";
import useSave from "./hooks/useSave";
import useGoToLine from "./hooks/useGoToLine";
import useResetUndoManager from "./hooks/useResetUndoManager";

interface IEMLEditorProps {
  file: FileNode;
  line?: number;
  char?: number;
  readonly: boolean; // TODO change for readOnly
}

const IEMLEditor = ({ file, readonly, char, line }: IEMLEditorProps) => {
  const editor = useRef<AceEditor>(null);

  const { projectId } = useCurrentProject();

  const parserResult = useAppSelector(getParserOutput(projectId));

  const [realtimeFile, setRealtimeFile] = useState(file);

  const useLocalParser = useAppSelector(shouldUseLocalParser);

  const { prepareSaving, saveRealtime, saveOnFileChange } = useSave(
    file,
    realtimeFile
  );

  const { goToLineAfterLoading } = useGoToLine(
    file,
    realtimeFile,
    editor,
    line,
    char
  );

  const { resetUndoManagerAfterLoading } = useResetUndoManager(
    realtimeFile,
    editor
  );

  useEffect(() => {
    const fileChanged = realtimeFile.id !== file.id;
    if (fileChanged) {
      saveOnFileChange();
      setRealtimeFile(file);

      // setRealtimeFile is async so we can't reset the undo manager right now
      resetUndoManagerAfterLoading();
      goToLineAfterLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file.id, realtimeFile.id]);

  useSessionMode(editor);

  const annotations = useAnnotations(parserResult, file);

  const markers = useMarkers(parserResult, file);

  const { hideContextMenu, onClick, onContextMenu, contextMenu } =
    useContextMenu();

  const parseRealtime = useParseRealtime(file, realtimeFile);

  const onChange = async (content: string) => {
    setRealtimeFile({ ...realtimeFile, content });
    prepareSaving();
    if (useLocalParser) {
      parseRealtime(file.id, content);
    }
    saveRealtime(file.id, content);

    hideContextMenu();
  };

  const onCursorChange = useUpdateSelectedLine(file);

  useResizeEditor(editor);

  useCompleters();

  return (
    <div
      className={styles.editor}
      onClick={onClick}
      onContextMenu={onContextMenu}
    >
      <AceEditor
        className={styles.aceEditor}
        ref={editor}
        markers={markers}
        annotations={annotations}
        wrapEnabled
        value={realtimeFile.content}
        readOnly={readonly}
        mode="text"
        theme="xcode"
        fontSize={18}
        onChange={onChange}
        height="auto"
        editorProps={{ $blockScrolling: true }}
        onCursorChange={(cursor) => {
          onCursorChange(cursor);
          hideContextMenu();
        }}
        enableBasicAutocompletion
        enableLiveAutocompletion
        enableSnippets
        onScroll={hideContextMenu}
        setOptions={{
          // enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
        }}
        showPrintMargin={false}
      />
      {contextMenu && (
        <EditorContextMenu event={contextMenu} onHide={hideContextMenu} />
      )}
    </div>
  );
};

export default IEMLEditor;

import { useEffect } from "react";
import Ace from "ace-builds";

const { TextHighlightRules } = Ace.require("ace/mode/text_highlight_rules");
const { Mode } = Ace.require("ace/mode/text");

export class IEMLHighlightRules extends TextHighlightRules {
  constructor() {
    super();

    // let keywordMapper = this.createKeywordMapper({}, "identifier");

    this.$rules = {
      start: [
        {
          token: "storage.type",
          regex:
            "@language|@component|@node|@word|@rootparadigm|@inflection|@auxiliary|@junction|" +
            "@paranode|@link|@table|@function|\\.",
        },
        {
          token: "constant.language",
          regex:
            "[a-z][a-z]:|[1-3]d:|class:|role:|type:|template-[a-z][a-z]:|args:|phraseWordInflection:|link:|domain:|condition:",
        },
        {
          token: "constant.numeric", // role number
          regex: "[0-8]",
        },
        {
          token: "support.constant",
          regex: "\\~|\\#|\\&|\\*|\\!|\\[|\\]|\\(|\\)|,",
        },

        {
          token: "comment",
          regex: "//.*$",
        },
        {
          token: "markup.italic", // language string
          regex: '(fr|en)(?=")',
          next: "string_start",
        },
      ],
      string_start: [
        {
          token: "string",
          regex: '"',
          next: "string",
        },
      ],
      string: [
        {
          token: "escape",
          regex: '\\\\"',
        },
        {
          token: "string",
          regex: '"',
          next: "start",
        },
        {
          token: "string.invalid",
          regex: '[^"\\\\]*$',
          next: "start",
        },
        {
          token: "string",
          regex: '[^"\\\\]+',
        },
      ],
    };
  }
}

class IEMLMode extends Mode {
  constructor() {
    super();
    this.HighlightRules = IEMLHighlightRules;
  }
}

const useSessionMode = (editor: any) => {
  // initialization of Ace editor
  useEffect(() => {
    // TODO use better type
    let curr_ref = editor.current as any;
    curr_ref.editor.session.setMode(new IEMLMode());

    // snippetManager.insertSnippet(curr_ref.editor, "@paranode blabla");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useSessionMode;

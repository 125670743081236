import { useState } from "react";

type OptionalOptions = {
  isOpen?: boolean;
  open?: () => void;
  close?: () => void;
};

const usePopup = (defaultOptions: OptionalOptions = {}) => {
  const options = {
    isOpen: false,
    open: () => {},
    close: () => {},
    ...defaultOptions,
  };

  const [isOpen, setOpen] = useState(options.isOpen);

  return {
    isOpen,
    open: () => {
      setOpen(true);
      options.open();
    },
    close: () => {
      setOpen(false);
      options.close();
    },
  };
};

export default usePopup;

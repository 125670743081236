import { CategoryHierarchyDefinition, isPhrase } from "models/parser";

import { useAppSelector } from "hooks/redux";
import { getElement } from "store";
import { Phrase } from "models/parser";
import useCurrentProject from "hooks/useCurrentProject";
import Table from "components/atoms/Table";
import TableContext from "contexts/Table.context";

import BreadCrumb from "./Breadcrumb";
import styles from "./ParanodeTable.module.scss";
import ParanodeTableNDimensions from "./ParanodeTableNDimensions";
import ParanodeTableContext from "./ParanodeTableContext";
import ParanodeTableItem from "./ParanodeTableItem";

type Props = {
  table: CategoryHierarchyDefinition;
  root: string;
};

const ParanodeTable = ({ table, root }: Props) => {
  const { projectId } = useCurrentProject();

  const layoutElementId = table.invariant_mapping[root] || "";

  const layoutElement = useAppSelector((state) =>
    getElement(state, projectId, layoutElementId)
  );

  if (!layoutElement || !isPhrase(layoutElement)) {
    return <>impossible to find element</>;
  }

  const phrase = layoutElement as Phrase;

  return (
    <div className={styles.caption}>
      <BreadCrumb current={root} table={table} />
      {/* {root === table.root && <TableItem elementId={table.root} />} */}
      {phrase.table ? (
        <TableContext.Provider value={{ item: ParanodeTableItem }}>
          <ParanodeTableContext.Provider value={{ level: 0 }}>
            <Table
              tableId={phrase.table}
              component={ParanodeTableNDimensions}
            />
          </ParanodeTableContext.Provider>
        </TableContext.Provider>
      ) : (
        "no table"
      )}
    </div>
  );
};

export default ParanodeTable;

import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import BasicDialog from "components/molecules/BasicDialog";
import Project from "models/project";
import FileNode, { getType, getFullPath } from "models/fileNode";

import FilesList from "components/organisms/FilesList";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { updateFileNode, getRootFile, parse } from "store";
import useCurrentProject from "hooks/useCurrentProject";
import { capitalizeFirstLetter } from "utils/string";

type Props = {
  open: boolean;
  onClose: () => void;
  // the file on wich the move file or folder event was trigger -> initial path
  file: FileNode;
  project: Project;
};

export const MoveFileOrFolderDialog = ({ open, onClose, file }: Props) => {
  const [path, setPath] = useState("/");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setPath(file.path);
    setErrorMessage("");
  }, [open, file]);

  const dispatch = useAppDispatch();

  const { projectId, project, version } = useCurrentProject();

  const move = async () => {
    try {
      await dispatch(updateFileNode({ file, path })).unwrap();
      onClose();
      await dispatch(parse({ project, version })).unwrap();
    } catch (e: any) {
      setErrorMessage((e as Error)?.message);
      return;
    }
  };

  const rootFile = useAppSelector(getRootFile(projectId));

  const { t } = useTranslation();

  if (!rootFile) {
    return null;
  }

  const type = capitalizeFirstLetter(getType(file));

  const fullPath = getFullPath(file);

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      onSubmit={move}
      title={t("_Move...", { type })}
      actionTitle={t("_Move")}
    >
      {t("_Select a new folder")}
      <Box
        sx={{
          padding: 1,
          margin: 1,
          minWidth: 500,
          backgroundColor: "#eaeaeb",
        }}
      >
        <FilesList
          project={project}
          onFileNodeClick={(f) => {
            setPath(getFullPath(f));
          }}
          files={[rootFile]}
          folderOnly
          selectedFileIds={[]}
          suffix="/"
          forceExpandedRoot
        />
      </Box>
      {t("_will be moved to", { type, filePath: fullPath, path: `${path}/` })}
      <div>{errorMessage}</div>
    </BasicDialog>
  );
};

import { useState, Fragment } from "react";
import { Divider, List, Chip, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Element } from "models/parser";
import SectionLabel from "components/molecules/SectionLabel";
import SectionAccordion from "components/molecules/SectionAccordion";
import ScriptsMap from "models/scriptsMap";
import ConceptButton from "components/atoms/ConceptButton";

import styles from "./ConceptsAccordions.module.scss";
import { useAppSelector } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";
import { getFilesIds } from "store";

type Props = {
  label: string;
  elements: Element[];
  scripts: ScriptsMap;
  isLoading?: boolean;
  defaultExpanded?: boolean;
};

const GenericAccordion = ({
  label,
  elements,
  scripts,
  isLoading,
  defaultExpanded,
}: Props) => {
  const [showed, setShowed] = useState(100);

  const incrementShowed = () => {
    if (showed < 300) {
      setShowed(showed + 100);
    } else {
      setShowed(showed + 1000);
    }
  };

  const { t } = useTranslation();

  const { projectId, version } = useCurrentProject();

  const currentProjectFileIds = useAppSelector((state) =>
    getFilesIds(state, projectId, version)
  );

  return (
    <SectionAccordion
      defaultExpanded={defaultExpanded}
      label={
        <SectionLabel
          label={label}
          number={elements.length}
          isLoading={isLoading}
        />
      }
    >
      <List disablePadding>
        {elements.slice(0, showed).map((element, index) => (
          <Fragment key={element.id}>
            {index > 0 && <Divider />}
            <ConceptButton
              className={styles.item}
              element={element}
              script={scripts[element.id]}
            >
              {!currentProjectFileIds.includes(element.range.file_id) && (
                <Tooltip title={t("_Defined in dictionary") || ""}>
                  <Chip label={t("_D")} size="small" />
                </Tooltip>
              )}
            </ConceptButton>
          </Fragment>
        ))}
        {showed < elements.length && (
          <button className={styles.moreButton} onClick={incrementShowed}>
            {t("_More")}
          </button>
        )}
      </List>
    </SectionAccordion>
  );
};

export default GenericAccordion;

import { useCallback } from "react";

import { TableNd } from "models/parser/Table";
import { Element } from "models/parser";

import { Table3Dimensions } from "components/atoms/Table";
import TableItem from "components/molecules/TableItem";
import styles from "components/atoms/Table/Table.module.scss";

import ParanodeTable1Dimension from "./ParanodeTable1Dimension";
import ParanodeTable2Dimensions from "./ParanodeTable2Dimensions";

type Props = {
  element: Element;
  className?: string;
  table: TableNd;
};

const ParanodeTableNDimensions = ({
  table,
  element,
  className = "",
}: Props) => {
  const transform = useCallback((line: string[]) => line.reverse(), []);

  const { headers, cells } = table;

  return (
    <>
      {table.n_dim === 1 && (
        <ParanodeTable1Dimension
          table={table}
          element={element}
          className={className}
          transform={transform}
        />
      )}
      {table.n_dim > 1 && (
        <table className={`${styles.Table} ${className}`}>
          <caption className={styles.caption}>
            <TableItem elementId={element.id} hideTableLink isHeader />
          </caption>
          {table.n_dim === 2 && (
            <ParanodeTable2Dimensions
              headers={headers}
              cells={cells}
              transform={transform}
            />
          )}
          {table.n_dim === 3 && (
            <Table3Dimensions
              headers={headers}
              cells={cells}
              transform={transform}
            />
          )}
          {table.n_dim > 3 && (
            <tbody>
              <tr>
                <td className={styles.td}>dimension not managed</td>
              </tr>
            </tbody>
          )}
        </table>
      )}
    </>
  );
};

export default ParanodeTableNDimensions;

import { useParams } from "react-router-dom";

import FileNode from "models/fileNode";
import { useAppSelector } from "hooks/redux";
import { getFile } from "store";

const useCurrentFile = (): FileNode | null => {
  const { fileId } = useParams();

  return useAppSelector(getFile(fileId || ""));
};

export default useCurrentFile;

import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { without } from "lodash";
import type { RootState } from "store";
import FileNode, * as FileNodeApi from "models/fileNode";
import Version, { VERSION_LATEST, versionToString } from "models/version";
import Project from "models/project";
import { insertUniq } from "utils/array";
import * as builderHelper from "./builderHelper";
import { logout, login, accessWithoutAnAccount } from "./users.slice";

const { isParent, isDescendant, byAlphabeticalPath } = FileNodeApi;

export interface FileNodesState {
  byId: { [id: string]: FileNode };
  byProjectIdAndVersion: {
    [projectId: string]: {
      [version: string]: {
        fileNodesIds: string[];
        rootFileId: string;
        areFetched?: boolean;
      };
    };
  };
}

export const fileNodesInitialState: FileNodesState = {
  byId: {},
  byProjectIdAndVersion: {},
};

export const createFileNode = createAsyncThunk(
  "fileNodes/create",
  async (arg: FileNodeApi.FileNodeCreate): Promise<Array<FileNode>> => {
    return await FileNodeApi.create(arg);
  }
);

export const fetchFileNodes = createAsyncThunk(
  "filesNode/fetch",
  async (
    arg: FileNodeApi.FileNodeFetch,
    thunkApi
  ): Promise<Array<FileNode>> => {
    const state = thunkApi.getState() as RootState;

    return accessWithoutAnAccount(state)
      ? await FileNodeApi.fetchPublished(arg)
      : await FileNodeApi.fetch(arg);
  }
);

export const updateFileNode = createAsyncThunk(
  "filesNode/update",
  async (arg: FileNodeApi.FileNodeUpdate): Promise<Array<FileNode>> => {
    return await FileNodeApi.update(arg);
  }
);

export const deleteFileNode = createAsyncThunk(
  "filesNode/delete",
  async (file: FileNode) => {
    await FileNodeApi.deleteOne(file);
  }
);

const setByProjectIdAndVersion = (
  state: FileNodesState,
  projectId: string,
  version: Version,
  more: any
) => {
  const versionString = versionToString(version);

  state.byProjectIdAndVersion[projectId][versionString] = {
    ...state.byProjectIdAndVersion[projectId][versionString],
    ...more,
  };
};

const initializeProjectIfNecessary = (
  state: FileNodesState,
  project: Project,
  version: Version
) => {
  if (!state.byProjectIdAndVersion[project.id]) {
    state.byProjectIdAndVersion[project.id] = {};
  }

  if (!state.byProjectIdAndVersion[project.id][versionToString(version)]) {
    setByProjectIdAndVersion(state, project.id, version, {
      fileNodesIds: [],
      areFetched: false,
    });
  }
};

export const fileNodesSlice = createSlice({
  name: "fileNodes",
  initialState: fileNodesInitialState,
  reducers: {},
  extraReducers: (builder) => {
    const {
      getFile,
      getFilesIdsInProject,
      addFileToParent,
      removeFileFromParent,
      moveFile,
    } = builderHelper;

    builder.addCase(createFileNode.pending, (state, { meta }) => {
      const version = "latest";
      initializeProjectIfNecessary(state, meta.arg.project, version);
    });

    builder.addCase(createFileNode.fulfilled, (state, { meta, payload }) => {
      if (payload.length === 0) {
        return;
      }

      const [{ projectId, version }] = payload;

      const oldFileNodesIds = getFilesIdsInProject(state, projectId, version);
      const newFileNodesIds = payload.map((fileNode) => fileNode.id);
      const fileNodesIds = insertUniq(oldFileNodesIds, newFileNodesIds);
      setByProjectIdAndVersion(state, projectId, version, { fileNodesIds });

      payload.forEach((fileNode) => {
        state.byId[fileNode.id] = fileNode;

        oldFileNodesIds
          .map(getFile(state))
          .filter((parentNode) => parentNode.isFolder)
          .filter((parentNode) => isParent(parentNode, fileNode))
          .forEach((parentNode) => {
            addFileToParent(state, parentNode, fileNode);
          });
      });
    });

    builder.addCase(fetchFileNodes.pending, (state, { meta }) => {
      const { project, version } = meta.arg;
      initializeProjectIfNecessary(state, project, version);

      setByProjectIdAndVersion(state, project.id, version, {
        areFetched: false,
      });
    });

    builder.addCase(fetchFileNodes.fulfilled, (state, { meta, payload }) => {
      const { project, version } = meta.arg;

      const fileNodesIds = payload.map((fileNode) => fileNode.id);

      setByProjectIdAndVersion(state, project.id, version, {
        areFetched: true,
        fileNodesIds,
        rootFileId: payload[0].id,
      });

      payload.forEach((fileNode) => {
        state.byId[fileNode.id] = fileNode;
      });
    });

    builder.addCase(fetchFileNodes.rejected, (state, { meta }) => {
      const { project, version } = meta.arg;

      setByProjectIdAndVersion(state, project.id, version, {
        areFetched: true,
      });
    });

    builder.addCase(updateFileNode.fulfilled, (state, { payload, meta }) => {
      if (payload.length === 0) {
        return;
      }

      const [{ projectId, version }] = payload;

      const oldFileNode = meta.arg.file;
      const hasPathChanged = oldFileNode.path !== payload[0].path;

      const fileNodesIds = getFilesIdsInProject(state, projectId, version);

      if (hasPathChanged) {
        fileNodesIds
          .map(getFile(state))
          .filter((parentNode) => parentNode.isFolder)
          .filter((parentNode) => isParent(parentNode, oldFileNode))
          .forEach((parentNode) => {
            removeFileFromParent(state, parentNode, oldFileNode);
          });

        if (oldFileNode.isFolder) {
          fileNodesIds
            .map(getFile(state))
            .filter((descendantNode) =>
              isDescendant(descendantNode, oldFileNode)
            )
            .forEach((descendantNode) => {
              moveFile(state, descendantNode, oldFileNode, payload[0]);
            });
        }
      }

      payload.forEach((fileNode) => {
        state.byId[fileNode.id] = {
          ...fileNode,
          childrenIds: getFile(state)(fileNode.id).childrenIds,
        };

        if (hasPathChanged) {
          fileNodesIds
            .map(getFile(state))
            .filter((parentNode) => parentNode.isFolder)
            .filter((parentNode) => isParent(parentNode, fileNode))
            .forEach((parentNode) => {
              addFileToParent(state, parentNode, fileNode);
            });
        }
      });
    });

    builder.addCase(deleteFileNode.fulfilled, (state, { meta }) => {
      const fileNode = meta.arg;
      const { projectId, version } = fileNode;

      const oldFileNodesIds = getFilesIdsInProject(state, projectId, version);
      const fileNodesIds = without(oldFileNodesIds, fileNode.id);

      setByProjectIdAndVersion(state, projectId, version, { fileNodesIds });

      delete state.byId[fileNode.id];

      fileNodesIds
        .map(getFile(state))
        .filter((parentNode) => parentNode.isFolder)
        .filter((parentNode) => isParent(parentNode, fileNode))
        .forEach((parentNode) => {
          removeFileFromParent(state, parentNode, fileNode);
        });
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      Object.assign(state, fileNodesInitialState);
    });

    builder.addCase(login.fulfilled, (state, action) => {
      Object.assign(state, fileNodesInitialState);
    });
  },
});

export default fileNodesSlice.reducer;

export const getFile =
  (id: string) =>
  (state: RootState): FileNode | null =>
    state.fileNodes.byId[id];

const getProjectAndVersionNode = createSelector(
  (state: RootState) => state.fileNodes.byProjectIdAndVersion,
  (state: RootState, projectId: string, version: Version) => ({
    projectId,
    version,
  }),
  (byProjectIdAndVersion, { projectId, version }) => {
    const versionString = versionToString(version);

    return byProjectIdAndVersion[projectId]?.[versionString];
  }
);

export const getFilesIds = createSelector(
  getProjectAndVersionNode,
  (projectAndVersionNode) => projectAndVersionNode?.fileNodesIds ?? []
);

export const getFilesById = (state: RootState) => state.fileNodes.byId;

const getFilesInternal = createSelector(
  (state: RootState) => state.fileNodes.byId,
  getFilesIds,
  (byId, fileIds) => fileIds.map((id: string) => byId[id])
);

export const getFilesToParse = (
  state: RootState,
  projectId: string,
  version: Version = VERSION_LATEST
): Array<FileNode> =>
  getFilesInternal(state, projectId, version)
    .filter((f) => !f.isFolder)
    .sort(byAlphabeticalPath);

export const getNumberOFiles =
  (projectId: string, version: Version = VERSION_LATEST) =>
  (state: RootState) =>
    getFilesToParse(state, projectId, version).length;

const getRootFileInternal = createSelector(
  (state: RootState) => state.fileNodes.byId,
  getProjectAndVersionNode,
  (byId, projectAndVersionNode) => byId[projectAndVersionNode?.rootFileId]
);

export const getRootFile =
  (projectId: string, version: Version = VERSION_LATEST) =>
  (state: RootState): FileNode | null =>
    getRootFileInternal(state, projectId, version);

const areFileNodesFetchedInternal = createSelector(
  getProjectAndVersionNode,
  (projectAndVersionNode) => Boolean(projectAndVersionNode?.areFetched)
);

export const areFileNodesFetched =
  (projectId: string, version: Version = VERSION_LATEST) =>
  (state: RootState): boolean =>
    areFileNodesFetchedInternal(state, projectId, version);

export const getChildrenFiles = createSelector(
  (state: RootState) => state.fileNodes.byId,
  (state: RootState, file: FileNode) => file.childrenIds,
  (byId, fileIds) =>
    fileIds.map((id: string) => byId[id]).sort(byAlphabeticalPath)
);

import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { PersistedState } from "utils/localStorage";
import Filters, { Scopes } from "models/filters";
import RangeSelection from "models/RangeSelection";
import Activity from "models/Activity";
import { logout, login } from "./users.slice";

export interface SettingsState {
  currentActivity: Activity;
  searchFilters: Filters;
  currentBottomTab: string | null;
  bottomPanelHeight: number;
  selectedElementId: string | null;
  selectedRange: RangeSelection;
  useLocalParser: boolean;
  openSeparateWindow: boolean;
  tableLevelMax: number;
}

export const settingsInitialState: SettingsState = {
  currentActivity: Activity.Files,
  searchFilters: {
    query: "",
    language: "EN",
    scope: Scopes.ALL,
  },
  currentBottomTab: null,
  bottomPanelHeight: 400,
  selectedElementId: null,
  selectedRange: {
    fileId: null,
    lineStart: null,
    lineEnd: null,
  },
  useLocalParser: true,
  openSeparateWindow: false,
  tableLevelMax: 3,
};

export const mergeWithInitialState = (
  persistedState: PersistedState | undefined
) => ({
  ...settingsInitialState,
  searchFilters: {
    ...settingsInitialState.searchFilters,
    language:
      persistedState?.language || settingsInitialState.searchFilters.language,
  },
  bottomPanelHeight:
    persistedState?.bottomPanelHeight || settingsInitialState.bottomPanelHeight,
  currentBottomTab:
    persistedState?.currentBottomTab || settingsInitialState.currentBottomTab,
  currentActivity: (persistedState?.currentActivity ||
    settingsInitialState.currentActivity) as Activity,
  useLocalParser:
    persistedState?.useLocalParser !== undefined
      ? persistedState?.useLocalParser
      : settingsInitialState.useLocalParser,
  tableLevelMax:
    persistedState?.tableLevelMax !== undefined
      ? persistedState?.tableLevelMax
      : settingsInitialState.tableLevelMax,
});

export const settingsSlice = createSlice({
  name: "settings",
  initialState: settingsInitialState,
  reducers: {
    setSearchFilters: (state, action) => {
      state.searchFilters = action.payload;
    },
    setSearchLanguage: (state, action) => {
      state.searchFilters.language = action.payload;
    },
    setCurrentActivity: (state, action) => {
      state.currentActivity = action.payload;
    },
    setCurrentBottomTab: (state, action) => {
      state.currentBottomTab = action.payload;
    },
    selectElement: (state, action) => {
      state.selectedElementId = action.payload.id;
      state.currentBottomTab = "tables";
    },
    selectLine: (state, action) => {
      state.selectedRange.lineStart = action.payload.start;
      state.selectedRange.lineEnd = action.payload.end;
      state.selectedRange.fileId = action.payload.fileId;
    },
    toggleCurrentBottomTab: (state, action) => {
      state.currentBottomTab =
        state.currentBottomTab === action.payload ? null : action.payload;
    },
    setBottomPanelHeight: (state, action) => {
      state.bottomPanelHeight = action.payload;
    },
    setUseLocalParser: (state, action) => {
      state.useLocalParser = action.payload;
    },
    setSeparateWindowOpen: (state, action) => {
      state.openSeparateWindow = action.payload;
    },
    searchChildren: (state, action) => {
      state.searchFilters.query = action.payload;
      state.currentActivity = Activity.Concepts;
    },
    setTableLevelMax: (state, action) => {
      state.tableLevelMax = action.payload || 1; // zero is not accepted
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state, action) => {
      Object.assign(state, settingsInitialState);
    });

    builder.addCase(login.fulfilled, (state, action) => {
      Object.assign(state, settingsInitialState);
    });
  },
});

export default settingsSlice.reducer;

export const {
  setCurrentActivity,
  setSearchLanguage,
  setCurrentBottomTab,
  setSearchFilters,
  selectElement,
  selectLine,
  toggleCurrentBottomTab,
  setBottomPanelHeight,
  setUseLocalParser,
  setSeparateWindowOpen,
  searchChildren,
  setTableLevelMax,
} = settingsSlice.actions;

export const getCurrentActivity = (state: RootState) =>
  state.settings.currentActivity;

export const getCurrentBottomTab = (state: RootState) =>
  state.settings.currentBottomTab;

export const getBottomPanelHeight = (state: RootState): number =>
  state.settings.bottomPanelHeight;

export const getFilters = (state: RootState): Filters =>
  state.settings.searchFilters;

export const getSelectedElementId = (state: RootState) =>
  state.settings.selectedElementId;

export const getSelectedRange = (state: RootState) =>
  state.settings.selectedRange;

export const getFilterLanguage = (state: RootState) =>
  getFilters(state).language;

export const shouldUseLocalParser = (state: RootState): boolean =>
  state.settings.useLocalParser;

export const getTableLevelMax = (state: RootState) =>
  state.settings.tableLevelMax;

export const getStateToPersist = (state: RootState) => ({
  language: getFilterLanguage(state),
  bottomPanelHeight: getBottomPanelHeight(state),
  currentBottomTab: getCurrentBottomTab(state),
  currentActivity: getCurrentActivity(state),
  useLocalParser: shouldUseLocalParser(state),
  tableLevelMax: getTableLevelMax(state),
});

export const isSeparateWindowOpen = (state: RootState) =>
  state.settings.openSeparateWindow;

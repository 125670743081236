import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import UserMenu from "components/organisms/UserMenu";
import { Button } from "@mui/material";
import styles from "./TopBar.module.scss";

import useIsAuthenticated from "hooks/useIsAuthenticated";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { accessWithoutAnAccount, setWithoutAccount, logout } from "store";

type Props = {
  children?: ReactNode;
  topLeft?: ReactNode;
  center?: ReactNode;
};

export const TopBar = ({ children, topLeft }: Props) => {
  const isAuthenticated = useIsAuthenticated();

  const withoutAccount = useAppSelector(accessWithoutAnAccount);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const prepareLogin = async () => {
    dispatch(setWithoutAccount(false));
    await dispatch(logout()).unwrap();
    navigate("/login");
  };

  return (
    <>
      <nav className={styles.topBar}>
        <div className={styles.left}>
          <div className={styles.align}>
            <div className={styles.brand}>
              <div className={styles.title}>
                IEML <span className={styles.editor}>EDITOR</span>
              </div>
            </div>
            {topLeft}
          </div>
        </div>
        <div className={styles.center}>{children}</div>
        <div className={styles.right}>
          {isAuthenticated && <UserMenu />}
          {withoutAccount && (
            <Button variant="outlined" size="small" onClick={prepareLogin}>
              {t("_Login")}
            </Button>
          )}
        </div>
      </nav>
    </>
  );
};

const snippets = [
  {
    name: "link",
    value: "link",
    snippet: `link
    args:($A, $B)
    fr:
    en:
    template-fr:
    template-en:
    phraseWordInflection: ~noun 
        (
        
        ).`,
    score: 100,
  },
  {
    name: "function",
    value: "function",
    snippet: `function
    type:word
    link:
    domain:( , )
    condition: 
      (

      ).`,
    score: 100,
  },
  {
    name: "rootparadigm",
    value: "rootparadigm",
    snippet: `rootparadigm type: "...".`,
  },
  {
    name: "inflection",
    value: "inflection",
    snippet: `inflection
    fr: 
    en:
    class:NOUN 
    "...".`,
  },
  {
    name: "auxiliary",
    value: "auxiliary",
    snippet: `auxiliary
    fr:
    en:
    role:4
    "...".`,
  },
  {
    name: "junction",
    value: "junction",
    snippet: `junction
    fr:
    en:
    "...".`,
  },
  {
    name: "node",
    value: "node",
    snippet: `node
    en: variation1
    (
        0 #
    ).`,
  },
  {
    name: "paranode",
    value: "paranode",
    snippet: `paranode
    en:
    1d: /#/1
    (
        0 #,
    ).
    `,
  },
  {
    name: "table",
    value: "table",
    snippet: `table
    # ->
    # ->  .
    `,
  },
];

export default snippets;

import { useEffect } from "react";
import {
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from "@mui/material";

import Version, {
  stringToVersion,
  versionToString,
  VERSION_LATEST,
} from "models/version";
import Project from "models/project";
import { useAppSelector } from "hooks/redux";
import { getLastestProjectVersion, getDictionary, getVersions } from "store";

type Props = {
  onChange: (version: Version) => void;
  selectedVersion?: Version | null;
  includeLatest?: boolean;
};

const SelectVersion = ({ selectedVersion, onChange, includeLatest }: Props) => {
  const dictionary = useAppSelector<Project | undefined>(getDictionary);

  const lastDictionaryVersion = useAppSelector(
    getLastestProjectVersion(dictionary?.id)
  );

  const dictionaryVersions = useAppSelector(getVersions(dictionary?.id));

  useEffect(() => {
    if (!selectedVersion && lastDictionaryVersion) {
      onChange(lastDictionaryVersion);
    }
  });

  const selectVersion = (event: SelectChangeEvent) => {
    const version = stringToVersion(event.target.value);
    onChange(version);
  };

  const versionsStr = dictionaryVersions.map(versionToString);
  const selectedVersionStr = selectedVersion
    ? versionToString(selectedVersion)
    : "";

  return (
    <FormControl fullWidth sx={{ marginTop: 1, marginBottom: 1 }}>
      <InputLabel id="dictionary-versions">Dictionary Version</InputLabel>
      <Select
        label="dictionary-versions"
        value={selectedVersionStr}
        onChange={selectVersion}
      >
        {includeLatest && (
          <MenuItem value={VERSION_LATEST}>{VERSION_LATEST}</MenuItem>
        )}
        {versionsStr.map((version, i) => (
          <MenuItem
            value={version}
            key={version}
            selected={selectedVersionStr === version}
          >
            {`v${version}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectVersion;

import api from "utils/api";
import Project from "models/project";
import { versionToString } from "models/version";
import { isRangeValid } from "./Range";
import FileNode, { byAlphabeticalPath } from "models/fileNode";
import ParserOutput from "./ParserOutput";

import { Phrase, Word, Script, Element } from "./Element";

export const isNode = (phrase: Phrase) => phrase.category_type === "NODE";
export const isComponent = (phrase: Phrase) =>
  phrase.category_type === "COMPONENT";
export const isParadigm = (phrase: Phrase) =>
  phrase.category_type === "PARADIGM";
export const isLink = (phrase: Phrase) => phrase.category_type === "LINK";

export const isCategory = (word: Word) => word.word_type === "CATEGORY";
export const isInflection = (word: Word) => word.word_type === "INFLECTION";
export const isJunction = (word: Word) => word.word_type === "JUNCTION";
export const isAuxiliary = (word: Word) => word.word_type === "AUXILIARY";

export const byIndex = (a: Script, b: Script) => a.index - b.index;

export const isPhrase = (e: Element) => e.type === "CATEGORY";
export const isWord = (e: Element) => e.type === "WORD";
export const isScript = (e: Element) => e.type === "SCRIPT";

export const isRootParadigm = (e: Element) =>
  isScript(e) &&
  "definition" in e &&
  e.definition === null &&
  isRangeValid(e.range);

export const getType = (element: Element) => {
  if (isPhrase(element)) {
    return (element as Phrase).category_type.toLowerCase();
  } else if (isWord(element)) {
    return (element as Word).word_type.toLowerCase();
  } else if (isScript(element)) {
    return "script";
  } else {
    throw new Error("Unknown element type");
  }
};

export async function parse(project: Project): Promise<ParserOutput> {
  const data = {
    dictionary_version: versionToString(project.dictionaryVersion),
  };

  const response = await api().post(`/project/${project.id}/parse`, data);

  return response.data as ParserOutput;
}

export const orderByDefinition =
  (filesById: { [id: string]: FileNode }) => (a: Element, b: Element) => {
    const fileA = filesById[a.range.file_id];
    const fileB = filesById[b.range.file_id];

    const result = byAlphabeticalPath(fileA, fileB);

    if (result !== 0) {
      return result;
    }

    return a.range.line_start - b.range.line_start;
  };

export * from "./PositionedMessage";
export * from "./Element";
export * from "./Table";
export * from "./CategoryHierarchyDefinition";
export * from "./Range";
export * from "./ParserOutput";

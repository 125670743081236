import { useContext, useMemo } from "react";

import TableItem from "components/molecules/TableItem";
import { TableNd } from "models/parser/Table";
import { Element } from "models/parser";

import RowTransform, { noTransform } from "components/atoms/Table/RowTransform";
import parentStyle from "components/atoms/Table/Table.module.scss";
import styles from "./ParanodeTable1Dimension.module.scss";

import ParanodeTableContext from "./ParanodeTableContext";

type Table1DimensionProps = {
  element: Element;
  className?: string;
  table: TableNd;
  transform?: RowTransform;
};

const ParanodeTable1Dimension = ({
  element,
  className,
  table,
  transform = noTransform,
}: Table1DimensionProps) => {
  const { cells } = table;

  const lines = useMemo(
    () => transform(cells.map((cell) => cell[0][0])),
    [cells, transform]
  );

  const level = useContext(ParanodeTableContext)?.level || 0;

  const shouldBeVertical = level % 2 === 0;

  return (
    <div
      className={`${parentStyle.Table} ${styles.Table} ${
        shouldBeVertical ? styles.vertical : ""
      } ${className}`}
    >
      <div
        className={shouldBeVertical ? styles.captionTop : styles.captionLeft}
      >
        <TableItem elementId={element.id} hideTableLink isHeader />
      </div>
      {lines.map((cell) => (
        <div
          key={cell}
          className={`${styles.td} ${
            shouldBeVertical ? styles.vertical : styles.horizontal
          }`}
        >
          <TableItem elementId={cell} />
        </div>
      ))}
    </div>
  );
};

export default ParanodeTable1Dimension;

import { ReactNode } from "react";
import TopBar from "components/organisms/TopBar";

type PageProps = {
  children?: ReactNode;
};

const BasicPage = ({ children }: PageProps) => {
  return (
    <>
      <TopBar />
      {children}
    </>
  );
};

export default BasicPage;

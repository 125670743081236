import WithTranslations from "models/parser/WithTranslations";

export type Language = "EN" | "FR";

export const languages = [
  { id: "EN", label: "English" },
  { id: "FR", label: "Français" },
];

export default Language;

export type ElementTranslation = {
  label: string;
  language: Language;
};

export const getTranslation = (
  element: WithTranslations,
  lang: Language
): ElementTranslation => {
  return {
    label: element.translations[lang].join(", "),
    language: lang,
  };
};

export const getTranslations = (
  element: WithTranslations,
  defaultLang: Language = "EN"
) => {
  return {
    defaultTranslation: getTranslation(element, defaultLang),
    translations: languages.map((lang) =>
      getTranslation(element, lang.id as Language)
    ),
  };
};

export const getCurrentLanguage = (i18n: any) => i18n.language.toUpperCase();

export const noTranslations = (element: WithTranslations) =>
  Object.values(element.translations).every((t) => t.length === 0);

import { simplifyScript } from "utils/script";
import ScriptsMap from "models/scriptsMap";

const iemlOrder = [
  "E",
  "U",
  "A",
  "O",
  "S",
  "B",
  "T",
  "M",
  "F",
  "I",
  "wo",
  "wa",
  "y",
  "o",
  "e",
  "wu",
  "we",
  "u",
  "a",
  "i",
  "j",
  "g",
  "h",
  "c",
  "p",
  "x",
  "s",
  "b",
  "t",
  "k",
  "m",
  "n",
  "d",
  "f",
  "l",
];

const getOrder = (script: string) =>
  iemlOrder.findIndex((l) => script.startsWith(l));

export const sortScriptsByIEML = (a: string, b: string): number => {
  const simplifiedA = simplifyScript(a);
  const simplifiedB = simplifyScript(b);

  const orderA = getOrder(simplifiedA);
  const orderB = getOrder(simplifiedB);

  if (orderA === -1 || orderB === -1) {
    return 0;
  }

  if (orderA === orderB) {
    const subA = simplifiedA.slice(iemlOrder[orderA].length);
    const subB = simplifiedB.slice(iemlOrder[orderB].length);
    return sortScriptsByIEML(subA, subB);
  }

  return orderA - orderB;
};

export const sortByIEML =
  (scriptsById: ScriptsMap) =>
  (elementIdA: string, elementIdB: string): number => {
    const scriptA = scriptsById[elementIdA];
    const scriptB = scriptsById[elementIdB];

    if (!scriptA || !scriptB) {
      return 0;
    }

    return sortScriptsByIEML(scriptA.str, scriptB.str);
  };

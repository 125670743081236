import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { keyBy, omit, without, uniq } from "lodash";
import type { RootState } from "store";
import Project, * as ProjectApi from "models/project";
import { logout, login, accessWithoutAnAccount } from "./users.slice";

export interface ProjectsState {
  byId: Record<string, Project>;
  listOfIds: Array<string>;
  listOfPublicIds: Array<string>;
  areFetched: boolean;
}

export const projectsInitialState: ProjectsState = {
  byId: {},
  listOfIds: [],
  listOfPublicIds: [],
  areFetched: false,
};

export const fetchProjects = createAsyncThunk(
  "projects/fetch",
  async (args, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    return {
      publicProjects: await ProjectApi.fetchAllPublic(),
      privateProjects: accessWithoutAnAccount(state)
        ? []
        : await ProjectApi.fetchAllPrivate(),
    };
  }
);

export const createProject = createAsyncThunk(
  "projects/create",
  async (toCreate: ProjectApi.ProjectCreate): Promise<Project> => {
    return await ProjectApi.create(toCreate);
  }
);

export const updateProject = createAsyncThunk(
  "projects/update",
  async (toUpdate: ProjectApi.ProjectUpdate): Promise<Project> => {
    return await ProjectApi.update(toUpdate);
  }
);

export const deleteProject = createAsyncThunk(
  "projects/delete",
  async (projectId: string) => {
    await ProjectApi.deleteOne(projectId);
    return projectId;
  }
);

export const projectsSlice = createSlice({
  name: "projects",
  initialState: projectsInitialState,
  reducers: {
    addToPublicProjects: (state, action) => {
      const { projectId } = action.payload;
      state.listOfPublicIds = uniq([...state.listOfPublicIds, projectId]);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.areFetched = false;
    });
    builder.addCase(fetchProjects.fulfilled, (state, { payload }) => {
      const { privateProjects, publicProjects } = payload;
      state.listOfIds = privateProjects.map((project) => project.id);
      state.byId = keyBy(
        [...privateProjects, ...publicProjects.projects],
        (project) => project.id
      );

      state.listOfPublicIds = publicProjects.projects.map(
        (project) => project.id
      );

      state.areFetched = true;
    });
    builder.addCase(fetchProjects.rejected, (state) => {
      state.areFetched = true;
    });

    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      state.listOfIds.push(payload.id);
      state.byId[payload.id] = payload;
    });

    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.byId[payload.id] = payload;
    });

    builder.addCase(deleteProject.fulfilled, (state, { payload }) => {
      state.byId = omit(state.byId, [payload]);
      state.listOfIds = without(state.listOfIds, payload);
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      Object.assign(state, projectsInitialState);
    });

    builder.addCase(login.fulfilled, (state, action) => {
      Object.assign(state, projectsInitialState);
    });
  },
});

export default projectsSlice.reducer;

export const { addToPublicProjects } = projectsSlice.actions;

export const areProjectsFetched = (state: RootState): boolean =>
  state.projects.areFetched;

export const getProjects = createSelector(
  (state: RootState) => state.projects.listOfIds,
  (state: RootState) => state.projects.byId,
  (listOfIds, byId) => listOfIds.map((id: string) => byId[id])
);

export const getPublicProjects = createSelector(
  (state: RootState) => state.projects.listOfPublicIds,
  (state: RootState) => state.projects.byId,
  (listOfIds, byId) => listOfIds.map((id: string) => byId[id])
);

export const getProjectById =
  (projectId: string | undefined) =>
  (state: RootState): Project | null =>
    projectId ? state.projects.byId[projectId] : null;

export const getDictionary = createSelector(
  getPublicProjects,
  (projects: Project[]) => projects.find((p) => p.isDictionary)
);

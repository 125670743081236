import { useEffect, useState } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { TextField, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { FilesList } from "components/organisms/FilesList/FilesList";
import FileNode, { getFullPath } from "models/fileNode";
import { createFileNode, getRootFile } from "store";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";
import { routes } from "models/routes";
import BasicDialog from "components/molecules/BasicDialog";
import Form from "components/atoms/Form";

type Props = {
  open: boolean;
  onClose: () => void;
  type: "file" | "folder";

  selectedFolder?: FileNode;
};

export const CreateFileOrFolderDialog = ({
  open,
  onClose,
  type,
  selectedFolder,
}: Props) => {
  const { currentProject, projectId } = useCurrentProject();

  const rootFile = useAppSelector(getRootFile(projectId));

  const [name, setName] = useState("");
  const [targetFolder, setTargetFolder] = useState(selectedFolder || rootFile);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setName("");
    setTargetFolder(selectedFolder || rootFile);
    setErrorMessage("");
  }, [open, selectedFolder, rootFile]);

  const dispatch = useAppDispatch();

  const createFile = async () => {
    if (name && currentProject && targetFolder) {
      try {
        const path = getFullPath(targetFolder);

        const [file] = await dispatch(
          createFileNode({
            project: currentProject,
            path,
            name,
            isFolder: type === "folder",
          })
        ).unwrap();
        if (!file.isFolder) {
          const path = generatePath(routes.editorWithFile, {
            projectId,
            fileId: file.id,
          });
          navigate(path);
        }
      } catch (e) {
        setErrorMessage((e as Error).message);
        return;
      }

      onClose();
    } else {
      setErrorMessage("Name can not be empty.");
    }
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    createFile();
  };

  const { t } = useTranslation();

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      onSubmit={createFile}
      title={t("_Create...", { type })}
      actionTitle={t("_Create")}
    >
      <Form onSubmit={onSubmit}>
        <p>What is the name of the new {type} ?</p>

        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={t("_Name")}
          type="text"
          variant="outlined"
          fullWidth
          error={!!errorMessage}
          helperText={errorMessage}
          value={name}
          onChange={(evt) => setName(evt.target.value)}
        />

        <p>{t("_Select a new folder")}</p>
        <Box
          sx={{
            padding: 1,
            minWidth: 500,
            backgroundColor: "#eaeaeb",
          }}
        >
          {rootFile && currentProject && (
            <FilesList
              files={[rootFile]}
              project={currentProject}
              onFileNodeClick={(file) => {
                setTargetFolder(file);
              }}
              folderOnly={true}
              selectedFileIds={targetFolder ? [targetFolder?.id] : []}
              suffix="/"
              forceExpandedRoot
            />
          )}
        </Box>
      </Form>
    </BasicDialog>
  );
};

export default CreateFileOrFolderDialog;

import { useState, useCallback } from "react";

import useEventListener from "hooks/useEventListener";

const useContextMenu = () => {
  const [contextMenu, setContextMenu] = useState<null | object>(null);

  const onClick = useCallback((event: any) => {
    setContextMenu(event);
  }, []);

  const hideContextMenu = useCallback(() => {
    setContextMenu(null);
  }, []);

  const onContextMenu = useCallback((event: any) => {
    setContextMenu(event);
    event.preventDefault();
  }, []);

  useEventListener("keyup", (event) => {
    if (event.key === "Escape") {
      hideContextMenu();
    }
  });

  return { onClick, hideContextMenu, onContextMenu, contextMenu };
};

export default useContextMenu;

import styles from "./ErrorMessage.module.scss";

type Props = {
  message: string | undefined | null;
};

const ErrorMessage = ({ message }: Props) => {
  return message ? <p className={styles.ErrorMessage}>{message}</p> : null;
};

export default ErrorMessage;

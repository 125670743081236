import { useMemo, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import TableItem from "components/molecules/TableItem";
import RowTransform, { noTransform } from "./RowTransform";

import styles from "./Table.module.scss";

type Table2DimensionProps = {
  headers: string[][];
  cells: string[][][];
  thirdDimensionIndex?: number;
  thirdDimensionHeader?: ReactNode;
  transform?: RowTransform;
};

const Table2Dimensions = ({
  headers,
  cells,
  thirdDimensionIndex = 0,
  thirdDimensionHeader = "",
  transform = noTransform,
}: Table2DimensionProps) => {
  const { t } = useTranslation();

  const headerFirstLine = useMemo(
    () => transform([...headers[1]]),
    [headers, transform]
  );

  console.log("headers", headers, headerFirstLine);

  return (
    <>
      <thead>
        <tr>
          <th className={styles.th}>{thirdDimensionHeader}</th>
          {headerFirstLine.map((header) => (
            <th className={styles.th} key={header}>
              <TableItem
                elementId={header}
                title={t("_Open column as table")}
                isHeader
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {transform(
          headers[0].map((header, index) => ({ header, index })),
          (a) => a.header
        ).map(({ header, index }) => (
          <tr key={header}>
            <th className={styles.th}>
              <TableItem
                elementId={header}
                title={t("_Open line as table")}
                isHeader
              />
            </th>
            {transform(
              cells[index].map((cell) => cell[thirdDimensionIndex])
            ).map((cell) => (
              <td key={cell} className={styles.td}>
                <TableItem elementId={cell} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default Table2Dimensions;

import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../index";
import {
  getParserOutputInternal,
  getTargetElements,
} from "../parserOutputs.slice";
import Instance from "models/parser/Instance";

export const getInstances = createSelector(
  getParserOutputInternal,
  (_: RootState, _2: string | null | undefined, elementId: string) => elementId,
  (parserOutput, elementId): Instance[] => {
    if (!parserOutput || !parserOutput.instances) {
      return [];
    }

    return Object.values(parserOutput.instances).filter((instance: Instance) =>
      Object.values(instance.linkedWordIds).includes(elementId)
    );
  }
);

export const getInstancesForTargetElements = (
  state: RootState,
  projectId: string | null | undefined
) =>
  getTargetElements(state, projectId).flatMap((element) =>
    getInstances(state, projectId, element.id)
  );

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
} from "@mui/material";

import { useTranslation } from "react-i18next";

type Props = {
  error: string | null;
  onClose: () => void;
};

const ConfirmPublishVersionDialog = ({ error, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={!!error}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("_Impossible to create a new version")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            minWidth: 500,
            padding: 1,
          }}
        >
          <div>{error}</div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          {t("_Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPublishVersionDialog;

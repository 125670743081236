import { useSearchParams } from "react-router-dom";

import { getTable } from "store";
import { useAppSelector } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";

const useSelectedTable = () => {
  let [searchParams] = useSearchParams();
  const { projectId } = useCurrentProject();

  const table = useAppSelector((state) =>
    getTable(state, projectId, searchParams.get("table") || "")
  );

  return table;
};

export default useSelectedTable;

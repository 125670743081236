import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { versionToString } from "models/version";
import {
  getLocalParserVersion,
  getRemoteParserVersion,
  getDictionary,
  getLastestProjectVersion,
} from "store";
import logo from "assets/logo.png";

import styles from "./About.module.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

const editorVersion = process.env.REACT_APP_VERSION;

const About = ({ open, onClose }: Props) => {
  const { t } = useTranslation();

  const dictionary = useAppSelector(getDictionary);

  const localParserVersion = useAppSelector(getLocalParserVersion);
  const remoteParserVersion = useAppSelector(getRemoteParserVersion);
  const lastestDictionaryVersion = useAppSelector(
    getLastestProjectVersion(dictionary?.id)
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ textAlign: "center" }} id="alert-dialog-title">
        IEML EDITOR
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            minWidth: 300,
            padding: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>{t("_by")}</div>
          <img src={logo} alt="logo" className={styles.logo} />
          <Box sx={{ padding: 2 }}>
            <p>{t("_editor version", { editorVersion })}</p>
            <p>
              {t("_Latest Dictionary version", {
                dictionaryVersion: lastestDictionaryVersion
                  ? versionToString(lastestDictionaryVersion)
                  : "",
              })}
            </p>
            <p>{t("_remote parser version", { remoteParserVersion })}</p>
            <p>{t("_local parser version", { localParserVersion })}</p>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("_Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default About;

import { ButtonBase } from "@mui/material";

import { Element, Script } from "models/parser";
import WordScript from "components/molecules/WordScript";
import ConceptLink from "components/atoms/ConceptLink";

import styles from "./ConceptButton.module.scss";

type Props = {
  className?: string;
  element: Element;
  script: Script | undefined | null;
  children?: React.ReactNode;
};

const ConceptButton = ({ className, element, script, children }: Props) => {
  return (
    <ConceptLink element={element} className={styles.ConceptButton}>
      <ButtonBase>
        <WordScript
          className={`${styles.button} ${className}`}
          element={element}
          script={script}
        />
        {children}
      </ButtonBase>
    </ConceptLink>
  );
};

export default ConceptButton;

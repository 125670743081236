import {
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { UpdateProjectDialog } from "components/organisms/ProjectDialogs/UpdateProjectDialog";
import { DeleteProjectDialog } from "components/organisms/ProjectDialogs/DeleteProjectDialog";
import Project from "models/project";
import usePopup from "hooks/usePopup";
import useRoutes, { routes } from "hooks/useRoutes";
import { versionToString } from "models/version";
import React from "react";
import { getLastestProjectVersion } from "store";

type Props = {
  project: Project;
  showVersions: boolean;
};

const stopPropagationAnd = (func: () => void) => (event: React.MouseEvent) => {
  event.stopPropagation();
  event.preventDefault();
  func();
};

const ProjectListItem = ({ project, showVersions }: Props) => {
  const { generatePath } = useRoutes();

  const version = useAppSelector(getLastestProjectVersion(project.id));

  const path =
    showVersions && version
      ? generatePath(routes.editor, {
          projectId: project.id,
          version: versionToString(version),
        })
      : generatePath(routes.editor, { projectId: project.id });
  const deleteProjectDialog = usePopup();
  const updateProjectDialog = usePopup();

  const { t } = useTranslation();

  return (
    <>
      <ListItem disablePadding disableGutters>
        <ListItemButton component={Link} to={path}>
          <ListItemText primary={project.name} />

          {showVersions && version && (
            <Stack direction="row" spacing={1}>
              {versionToString(version)}
            </Stack>
          )}
          {!project.isDictionary && !showVersions && (
            <Stack direction="row" spacing={1}>
              <Tooltip title={t("_Edit Project") || ""}>
                <IconButton
                  aria-label="edit"
                  onClick={stopPropagationAnd(updateProjectDialog.open)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("_Delete Project") || ""}>
                <IconButton
                  aria-label="delete"
                  onClick={stopPropagationAnd(deleteProjectDialog.open)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </ListItemButton>
      </ListItem>
      <UpdateProjectDialog
        project={project}
        open={updateProjectDialog.isOpen}
        onClose={updateProjectDialog.close}
      />
      <DeleteProjectDialog
        project={project}
        open={deleteProjectDialog.isOpen}
        onClose={deleteProjectDialog.close}
      />
    </>
  );
};

export default ProjectListItem;

import { Script, Element } from "models/parser";
import Translation from "components/molecules/Translation";

import styles from "./WordScript.module.scss";

type Props = {
  className?: string;
  element: Element;
  script: Script | undefined | null;
};

const WordScript = ({ className, element, script }: Props) => {
  return (
    <div className={className}>
      {element && (
        <div className={styles.label}>
          <Translation element={element} />
        </div>
      )}
      {script?.str && <div className={styles.script}>{script?.str}</div>}
    </div>
  );
};

export default WordScript;

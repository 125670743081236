import { Tooltip } from "@mui/material";
import TableViewIcon from "@mui/icons-material/TableView";
import { useTranslation } from "react-i18next";

import { Script, Word } from "models/parser";
import { useAppSelector } from "hooks/redux";
import { getElement } from "store";
import { noTranslations } from "models/language";
import WordScript from "components/molecules/WordScript";
import TableLink from "components/atoms/TableLink";
import ConceptButton from "components/atoms/ConceptButton";

import styles from "./TableItem.module.scss";

type Props = {
  script: Script;
  className?: string;
  projectId: string;
  hideTableLink?: boolean;
  title?: string;
  isHeader?: boolean;
};

const ScriptTableItem = ({
  script,
  className,
  projectId,
  hideTableLink,
  title,
  isHeader = false,
}: Props) => {
  let word = useAppSelector((state) =>
    getElement(state, projectId, script.definition || "")
  ) as Word;

  const phrase = useAppSelector((state) =>
    getElement(state, projectId, word?.phrase_words[0] || "")
  ) as Word;

  if (word && noTranslations(word)) {
    word = phrase;
  }

  const { t } = useTranslation();

  const target = word || script;

  if (isHeader) {
    console.log("script", script);
  }

  return (
    <div className={className}>
      {isHeader && hideTableLink && (
        <ConceptButton
          className={styles.caption}
          script={script}
          element={script}
        />
      )}
      {isHeader && !hideTableLink && (
        <div className={styles.button}>
          <WordScript
            className={styles.content}
            script={script}
            element={word}
          />
          <div>
            <div className={styles.buttonIcon}>
              <Tooltip title={t("_Open Table") || ""} placement="bottom">
                <div>
                  <TableLink tableId={script.table}>
                    <TableViewIcon />
                  </TableLink>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
      {!isHeader && !hideTableLink && (
        <ConceptButton
          className={styles.link}
          script={script}
          element={target}
        />
      )}
    </div>
  );
};

export default ScriptTableItem;

import { useEffect } from "react";
import ace from "ace-builds/src-noconflict/ace";

import { useAppSelector } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";
import { getIdentifiers } from "store";

import snippets from "./snippets";

const langTools = ace.require("ace/ext/language_tools");

const useCompleters = () => {
  const { projectId } = useCurrentProject();

  const identifiers = useAppSelector((state) =>
    getIdentifiers(state, projectId)
  );

  useEffect(() => {
    const getCompletions = (
      editor: any,
      session: any,
      pos: any,
      prefix: string,
      callback: any
    ) => {
      const range = new ace.Range(
        pos.row,
        pos.column - prefix.length - 1,
        pos.row,
        pos.column - prefix.length
      );

      const beforePrefixCharacter = session.getTextRange(range);

      if (beforePrefixCharacter === "#") {
        callback(
          null,
          identifiers
            .filter((i) => i.value.includes(prefix))
            .map((identifier) => ({
              value: identifier.value,
              name: identifier.value,
              score: 100,
              meta: "",
            }))
        );
      } else if (beforePrefixCharacter === "@") {
        callback(null, snippets);
      } else {
        callback(null, []);
      }
    };

    const completer = {
      getCompletions,
      exactMatch: true,
    };

    langTools.setCompleters([completer]);
  }, [identifiers]);
};

export default useCompleters;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./App.module.scss";
import { CssBaseline } from "@mui/material";
import { Router } from "./routes";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import useIsAuthenticated from "hooks/useIsAuthenticated";
import {
  isUserFetched,
  checkSession,
  areProjectsFetched,
  fetchProjects,
  fetchRemoteParserVersion,
  fetchLocalParserVersion,
  accessWithoutAnAccount,
} from "store";

function App() {
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();
  const userFetched = useAppSelector(isUserFetched);
  const withoutAccount = useAppSelector(accessWithoutAnAccount);

  useEffect(() => {
    if (userFetched && !isAuthenticated && !withoutAccount) {
      navigate("/login");
    }
  }, [isAuthenticated, userFetched, navigate, withoutAccount]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(checkSession());
    dispatch(fetchRemoteParserVersion());
    dispatch(fetchLocalParserVersion());
  }, [dispatch]);

  const projectsFetched = useAppSelector(areProjectsFetched);

  useEffect(() => {
    if ((isAuthenticated || withoutAccount) && !projectsFetched) {
      dispatch(fetchProjects());
    }
  }, [isAuthenticated, projectsFetched, withoutAccount, dispatch]);

  return (
    <CssBaseline>
      <Router />
    </CssBaseline>
  );
}

export default App;

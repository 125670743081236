import { ReactNode } from "react";
import styles from "./Explorer.module.scss";

type Prop = {
  children?: ReactNode;
  onContextMenu?: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const Explorer = ({ children, onContextMenu }: Prop) => {
  return (
    <div className={styles.Explorer} onContextMenu={onContextMenu}>
      {children}
    </div>
  );
};

export default Explorer;

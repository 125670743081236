import { useTranslation } from "react-i18next";

import styles from "./BottomPanel.module.scss";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";
import {
  getParserErrors,
  getParserWarnings,
  getCurrentBottomTab,
  setCurrentBottomTab,
  getInstancesForTargetElements,
} from "store";
import useSelectedTable from "hooks/useSelectedTable";
import { count } from "models/parser/Table";
import ErrorBottomPanel from "./ErrorBottomPanel";
import TableBottomPanel from "./TableBottomPanel";
import RelationshipBottomPanel from "./RelationshipBottomPanel";
import Tab from "./Tab";
import useSelectedParanodeTable from "hooks/useSelectedParanodeTable";

type Props = {
  children?: React.ReactNode;
  onClose?: () => void;
};

const BottomPanel = ({ children, onClose }: Props) => {
  const currentBottomTab = useAppSelector(getCurrentBottomTab);

  const dispatch = useAppDispatch();

  const changeTab = (tab: string) => () => {
    dispatch(setCurrentBottomTab(tab));
  };

  const { projectId } = useCurrentProject();

  const errors = useAppSelector(getParserErrors(projectId));
  const warnings = useAppSelector(getParserWarnings(projectId));
  const selectedTable = useSelectedTable();
  const { table: selectedParanodeTable } = useSelectedParanodeTable();
  const tablesCount = count(selectedTable) + (selectedParanodeTable ? 1 : 0);
  const instances = useAppSelector((state) =>
    getInstancesForTargetElements(state, projectId)
  );

  const { t } = useTranslation();

  if (!currentBottomTab) {
    return null;
  }

  return (
    <div className={styles.BottomPanel}>
      <div className={styles.header}>
        <div className={styles.tabs}>
          <Tab
            title={t("_Errors")}
            onClick={changeTab("errors")}
            isActive={currentBottomTab === "errors"}
            indicator={errors?.length}
          />
          <Tab
            title={t("_Warnings")}
            onClick={changeTab("warnings")}
            isActive={currentBottomTab === "warnings"}
            indicator={warnings?.length}
          />
          <Tab
            title={t("_Tables")}
            onClick={changeTab("tables")}
            isActive={currentBottomTab === "tables"}
            indicator={tablesCount}
          />
          <Tab
            title={t("_Relationships")}
            onClick={changeTab("relationships")}
            isActive={currentBottomTab === "relationships"}
            indicator={instances.length}
          />
        </div>
        <div className={styles.buttons}>{children}</div>
      </div>
      <div className={styles.content}>
        {currentBottomTab === "errors" &&
          errors.map((error, index) => (
            <ErrorBottomPanel key={index} positionedMessage={error} />
          ))}
        {currentBottomTab === "errors" && errors.length === 0 && (
          <div className={styles.item}>No problems has been detected</div>
        )}
        {currentBottomTab === "warnings" &&
          warnings.map((warning, index) => (
            <ErrorBottomPanel key={index} positionedMessage={warning} />
          ))}
        {currentBottomTab === "warnings" && warnings.length === 0 && (
          <div className={styles.item}>No warning has been detected</div>
        )}
        {currentBottomTab === "tables" && <TableBottomPanel />}
        {currentBottomTab === "relationships" && <RelationshipBottomPanel />}
      </div>
    </div>
  );
};

export default BottomPanel;

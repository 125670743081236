import { Version } from "./version";
import api from "utils/api";

export type ProjectMode = "explore" | "editor";

type HttpProject = {
  _id: {
    $oid: string;
  };
  owner: string;
  name: string;
  slug: string;
  is_dictionary: boolean;
  dictionary_version: Version;
};

export type Project = {
  id: string;
  owner: string;
  name: string;
  slug: string;
  isDictionary: boolean;
  dictionaryVersion: Version;
};

const convertFromHttp = (httpProject: HttpProject): Project => ({
  id: httpProject._id.$oid,
  owner: httpProject.owner,
  name: httpProject.name,
  slug: httpProject.slug,
  isDictionary: httpProject.is_dictionary,
  dictionaryVersion: httpProject.dictionary_version,
});

export default Project;

export async function fetchAllPrivate(): Promise<Array<Project>> {
  const response = await api().get("/projects");
  return response.data.map(convertFromHttp);
}

export type publicReturn = {
  projects: Array<Project>;
  versions: Record<string, Version[]>;
};

export async function fetchAllPublic(): Promise<publicReturn> {
  const response = await api().get("/projects/published");

  return {
    projects: response.data.projects.map(convertFromHttp),
    versions: response.data.versions,
  };
}

export async function fetchOne(projectId: string): Promise<Project> {
  const response = await api().get(`/projects/${projectId}`);

  return convertFromHttp(response.data);
}

export type ProjectCreate = {
  name: string;
  dictionaryVersion: Version;
};

export async function create(toCreate: ProjectCreate): Promise<Project> {
  const response = await api().post(`/projects`, {
    name: toCreate.name,
    dictionary_version: toCreate.dictionaryVersion,
  });
  return convertFromHttp(response.data);
}

export type ProjectUpdate = {
  id: string;
  name: string;
  dictionaryVersion: Version;
};

export async function update(toUpdate: ProjectUpdate): Promise<Project> {
  const response = await api().put(`/project/${toUpdate.id}`, {
    new_name: toUpdate.name,
    new_dictionary_version: toUpdate.dictionaryVersion,
  });
  return convertFromHttp(response.data);
}

export async function deleteOne(projectId: string) {
  await api().delete(`/project/${projectId}`);
}

export const byAlphabeticalOrderForName = (a: Project, b: Project) =>
  a.name.localeCompare(b.name);

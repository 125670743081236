import { ReactNode, useState, useEffect } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import styles from "./SectionAccordion.module.scss";

type Props = {
  label: ReactNode;
  children?: ReactNode;
  right?: ReactNode;
  empty?: boolean;
  defaultExpanded?: boolean;
};

const SectionAccordion = ({
  label,
  right,
  children,
  empty,
  defaultExpanded,
  ...props
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const onToggleExpand = (event: React.SyntheticEvent, expanded: boolean) => {
    setIsExpanded(expanded);
  };

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true, timeout: 0 }}
      expanded={isExpanded}
      onChange={onToggleExpand}
    >
      <AccordionSummary
        className={styles.title}
        expandIcon={<ExpandMoreIcon />}
      >
        <span>{label}</span>
        {right ? <div className={styles.right}>{right}</div> : null}
      </AccordionSummary>
      {!empty && <AccordionDetails>{children}</AccordionDetails>}
    </Accordion>
  );
};

export default SectionAccordion;

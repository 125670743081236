import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import AbcIcon from "@mui/icons-material/Abc";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";

import {
  RenameFileOrFolderDialog,
  DeleteFileOrFolderDialog,
  MoveFileOrFolderDialog,
  CreateFileOrFolderDialog,
} from "components/organisms/FilesListDialogs";
import usePopup from "hooks/usePopup";
import FileNode from "models/fileNode";
import Project from "models/project";

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClose: any;
  project: Project;
  file: FileNode;
};

export const FileAndFolderContextMenu = ({
  anchorEl,
  file,
  open,
  onClose,
  project,
}: Props) => {
  const moveDialog = usePopup({ open: onClose });
  const renameDialog = usePopup({ open: onClose });
  const deleteDialog = usePopup({ open: onClose });
  const createFileDialog = usePopup({ open: onClose });
  const createFolderDialog = usePopup({ open: onClose });

  const { t } = useTranslation();

  return (
    <>
      <Menu
        id="file-context-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {file.isFolder && (
          <MenuItem onClick={createFileDialog.open}>
            <ListItemIcon>
              <NoteAddIcon />
            </ListItemIcon>
            <ListItemText>{t("_New file")}</ListItemText>
          </MenuItem>
        )}
        {file.isFolder && (
          <MenuItem onClick={createFolderDialog.open}>
            <ListItemIcon>
              <CreateNewFolderIcon />
            </ListItemIcon>
            <ListItemText>{t("_New folder")}</ListItemText>
          </MenuItem>
        )}
        {file.isFolder && <Divider />}
        <MenuItem onClick={renameDialog.open}>
          <ListItemIcon>
            <AbcIcon />
          </ListItemIcon>
          <ListItemText>{t("_Rename")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={moveDialog.open}>
          <ListItemIcon>
            <DriveFileMoveIcon />
          </ListItemIcon>
          <ListItemText>{t("_Move")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={deleteDialog.open}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t("_Delete")}</ListItemText>
        </MenuItem>
      </Menu>

      <CreateFileOrFolderDialog
        type="file"
        selectedFolder={file}
        open={createFileDialog.isOpen}
        onClose={createFileDialog.close}
      />
      <CreateFileOrFolderDialog
        type="folder"
        selectedFolder={file}
        open={createFolderDialog.isOpen}
        onClose={createFolderDialog.close}
      />

      <RenameFileOrFolderDialog
        file={file}
        open={renameDialog.isOpen}
        onClose={renameDialog.close}
      />

      <RenameFileOrFolderDialog
        file={file}
        open={renameDialog.isOpen}
        onClose={renameDialog.close}
      />

      <MoveFileOrFolderDialog
        open={moveDialog.isOpen}
        onClose={moveDialog.close}
        file={file}
        project={project}
      />

      <DeleteFileOrFolderDialog
        open={deleteDialog.isOpen}
        onClose={deleteDialog.close}
        file={file}
      />
    </>
  );
};

import { useTranslation } from "react-i18next";

import NodeTable from "components/molecules/NodeTable";
import useSelectedTable from "hooks/useSelectedTable";
import useSelectedParanodeTable from "hooks/useSelectedParanodeTable";
import ParanodeTable from "components/molecules/ParanodeTable";

import styles from "./TableBottomPanel.module.scss";
import parentStyles from "../BottomPanel.module.scss";

const TableBottomPanel = () => {
  const table = useSelectedTable();
  const { table: paranodeTable, tableRoot } = useSelectedParanodeTable();

  const { t } = useTranslation();

  return (
    <>
      {!table?.id && !paranodeTable?.root && (
        <div className={parentStyles.item}>{t("_No table selected")}</div>
      )}
      {table?.id && (
        <div className={styles.BottomPanel}>
          <NodeTable tableId={table?.id} />
        </div>
      )}
      {paranodeTable?.root && (
        <div className={styles.BottomPanel}>
          <ParanodeTable table={paranodeTable} root={tableRoot?.id || ""} />
        </div>
      )}
    </>
  );
};

export default TableBottomPanel;

import { useTranslation } from "react-i18next";

import TableItem from "components/molecules/TableItem";
import RowTransform, { noTransform } from "./RowTransform";
import Table2Dimensions from "./Table2Dimensions";

type Table3DimensionProps = {
  headers: string[][];
  cells: string[][][];
  transform: RowTransform;
};

const Table3Dimensions = ({
  headers,
  cells,
  transform = noTransform,
}: Table3DimensionProps) => {
  const { t } = useTranslation();

  return (
    <>
      {headers[2].map((headerThirdDimension, dimensionIndex) => (
        <Table2Dimensions
          key={headerThirdDimension}
          headers={headers}
          cells={cells}
          transform={transform}
          thirdDimensionIndex={dimensionIndex}
          thirdDimensionHeader={
            <TableItem
              elementId={headerThirdDimension}
              title={t("_Open only this table")}
            />
          }
        />
      ))}
    </>
  );
};

export default Table3Dimensions;

import { Link } from "react-router-dom";

import { Element } from "models/parser";
import useCurrentProject from "hooks/useCurrentProject";
import { isRangeInvalid } from "models/parser/Range";
import useRoutes, { routes } from "hooks/useRoutes";

import styles from "./ConceptLink.module.scss";

type Props = {
  element: Element;
  className?: string;
  children?: React.ReactNode;
};

const preventPropagation = async (
  evt: React.MouseEvent<HTMLLIElement | HTMLSpanElement>
) => {
  evt.stopPropagation();
};

const ConceptLink = ({ element, className, children }: Props) => {
  const { projectId } = useCurrentProject();

  const { file_id, line_start, char_start } = element.range;

  const { generatePath } = useRoutes();

  const path = generatePath(routes.editorWithFile, {
    line: line_start,
    char: char_start,
    projectId,
    fileId: file_id,
  });

  if (isRangeInvalid(element.range)) {
    return <div className={`${styles.Link} ${className}`}>{children}</div>;
  }

  return (
    <Link
      to={path}
      className={`${styles.Link} ${className}`}
      onClick={preventPropagation}
    >
      {children}
    </Link>
  );
};

export default ConceptLink;

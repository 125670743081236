import { useEffect, useRef } from "react";

import FileNode from "models/fileNode";

export const goToLine = (
  editor: any,
  line: number | undefined,
  char: number | undefined
) => {
  if (editor?.current?.editor && line !== undefined && char !== undefined) {
    editor?.current?.editor.gotoLine(line, char, true);
    editor?.current?.editor.scrollToLine(
      Math.max(line - 4, 0),
      false,
      false,
      () => {}
    );
  }
};

const useGoToLine = (
  file: FileNode,
  realtimeFile: FileNode,
  editor: any,
  line: number | undefined,
  char: number | undefined
) => {
  const shouldGoToLineAndChar = useRef(false);

  useEffect(() => {
    // if the file changed we should wait until the content is loaded
    if (realtimeFile?.id === file?.id) {
      goToLine(editor, line, char);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [line, char, editor]);

  // once the content is loaded we move the cursor to the line and char
  useEffect(() => {
    if (shouldGoToLineAndChar.current) {
      goToLine(editor, line, char);
      shouldGoToLineAndChar.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realtimeFile.content]);

  const goToLineAfterLoading = () => {
    shouldGoToLineAndChar.current = true;
  };

  return { goToLineAfterLoading };
};

export default useGoToLine;

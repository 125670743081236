import { ButtonBase } from "@mui/material";

import styles from "./EditorContextMenu.module.scss";

type Props = {
  icon?: React.ReactNode;
  children: React.ReactNode;
  button?: boolean;
};

const EditorContextMenuItem = ({ icon, children, button = true }: Props) => {
  if (!button) {
    return (
      <div className={styles.Item}>
        <div className={styles.label}>{children}</div>
        {icon && <div className={styles.icon}>{icon}</div>}
      </div>
    );
  }
  return (
    <ButtonBase className={styles.Item}>
      <div className={styles.Item}>
        <div className={styles.label}>{children}</div>
        {icon && <div className={styles.icon}>{icon}</div>}
      </div>
    </ButtonBase>
  );
};

export default EditorContextMenuItem;

import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useAppSelector, useAppDispatch } from "hooks/redux";
import IEMLEditor from "components/organisms/IEMLEditor";
import TopBar from "components/organisms/TopBar";
import StatusBar from "components/organisms/StatusBar";
import ActivityBar from "components/organisms/ActivityBar";
import SideBar from "components/organisms/SideBar";
import BottomPanel from "components/organisms/BottomPanel";
import EmptyEditor from "components/organisms/EmptyEditor";
import CreateFileOrFolder from "components/organisms/EmptyEditor/CreateFileOrFolder";
import ReadOnlyBanner from "components/molecules/ReadOnlyBanner";

import { setCurrentActivity, areFileNodesFetched } from "store";
import { versionToString, VERSION_LATEST } from "models/version";
import useCurrentProject from "hooks/useCurrentProject";

import styles from "./EditorPage.module.scss";
import useCurrentFile from "hooks/useCurrentFile";
import { getFullPath } from "models/fileNode";

const EditorPage = () => {
  const [searchParams] = useSearchParams();
  const line = searchParams.get("line")
    ? parseInt(searchParams.get("line") || "0", 10)
    : undefined;
  const char = searchParams.get("char")
    ? parseInt(searchParams.get("char") || "0", 10)
    : undefined;

  const { currentProject, projectId, currentVersion } = useCurrentProject();

  const filesNodeFetched = useAppSelector(
    areFileNodesFetched(projectId, currentVersion)
  );

  const currentFile = useCurrentFile();

  const isProjectReadOnly = currentVersion !== VERSION_LATEST;

  const isFileReadOnly =
    isProjectReadOnly || Boolean(currentFile?.version !== VERSION_LATEST);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentProject) {
      dispatch(setCurrentActivity("files"));
    }
  }, [currentProject, dispatch]);

  if (!currentProject) {
    return null;
  }

  return (
    <div className={styles.layout}>
      <TopBar
        topLeft={
          <Link to="/" className={styles.back}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              size="small"
            >
              {t("_Projects")}
            </Button>
          </Link>
        }
      >
        <Box sx={{ fontWeight: "bold" }}>{currentProject?.name}</Box>
        {currentVersion !== VERSION_LATEST && (
          <Box
            sx={{
              paddingLeft: "1rem",
              fontSize: "0.9rem",
              display: "inline",
              fontWeight: "bold",
            }}
          >{`v${versionToString(currentVersion)}`}</Box>
        )}
      </TopBar>
      <div className={styles.Editor}>
        <ActivityBar readOnly={isProjectReadOnly} />
        <SideBar readOnly={isProjectReadOnly} />
        <div className={styles.main}>
          <div className={styles.center}>
            {filesNodeFetched && currentFile && (
              <>
                {isProjectReadOnly && (
                  <ReadOnlyBanner>{t("_Read only")}</ReadOnlyBanner>
                )}
                {isFileReadOnly && !isProjectReadOnly && (
                  <ReadOnlyBanner>
                    {t("_Read only file", { path: getFullPath(currentFile) })}
                  </ReadOnlyBanner>
                )}
                <IEMLEditor
                  file={currentFile}
                  line={line}
                  char={char}
                  readonly={isFileReadOnly}
                />
              </>
            )}
            {filesNodeFetched && !currentFile && (
              <div className={styles.no_file}>
                <EmptyEditor>
                  {isProjectReadOnly ? (
                    t("_Please select a file.")
                  ) : (
                    <>
                      {t("_Please select a file or create one.")}
                      <div className={styles.create_menu}>
                        <CreateFileOrFolder />
                      </div>
                    </>
                  )}
                </EmptyEditor>
              </div>
            )}
            {!filesNodeFetched && (
              <div className={styles.no_file}>
                <EmptyEditor>{t("_Loading files")}</EmptyEditor>
              </div>
            )}
          </div>
          <BottomPanel />
        </div>
      </div>
      <StatusBar />
    </div>
  );
};

export default EditorPage;

import { SyntheticEvent, useState } from "react";
import { FileNode } from "../models/fileNode";

export const useContextMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedFile, setFile] = useState<FileNode | null>(null);

  const openContextMenu = (event: SyntheticEvent, file: FileNode) => {
    event.preventDefault();
    event.stopPropagation();

    setFile(file);
    setIsOpen(true);
    setAnchorEl(event.target as HTMLElement);
  };

  const closeContextMenu = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  return { openContextMenu, closeContextMenu, isOpen, anchorEl, selectedFile };
};

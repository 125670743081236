import { Fragment, useState } from "react";
import { List, Card, Divider, Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import CreateProjectDialog from "components/organisms/ProjectDialogs/CreateProjectDialog";
import usePopup from "hooks/usePopup";
import useIsAuthenticated from "hooks/useIsAuthenticated";

import { useTranslation } from "react-i18next";

import Project from "models/project";

import { useAppSelector } from "hooks/redux";
import {
  areProjectsFetched,
  getCurrentUser,
  getProjects,
  getPublicProjects,
} from "store";
import ProjectListItem from "./ProjectListItem";

interface TabPanelProps {
  projects: Project[];
  index: number;
  value: number;
  showVersions?: boolean;
  areFetched: boolean;
}

const TabPanel = ({
  projects,
  value,
  index,
  showVersions = false,
  areFetched,
  ...other
}: TabPanelProps) => {
  const { t } = useTranslation();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3 }}>
          {areFetched && projects.length > 0 && (
            <Card sx={{ padding: 0 }}>
              <List sx={{ padding: 0 }}>
                {projects
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((p, index) => (
                    <Fragment key={p.id}>
                      {index !== 0 && <Divider />}
                      <ProjectListItem
                        showVersions={showVersions}
                        key={p.id}
                        project={p}
                      />
                    </Fragment>
                  ))}
              </List>
            </Card>
          )}
          {areFetched && projects.length === 0 && (
            <Box sx={{ opacity: 0.7 }}>{t("_No projects")}</Box>
          )}
          {!areFetched && (
            <Box sx={{ opacity: 0.7 }}>{t("_Loading projects")}</Box>
          )}
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectsList = () => {
  const privateProjects = useAppSelector(getProjects);

  const publicProjects = useAppSelector(getPublicProjects);

  const user = useAppSelector(getCurrentUser);

  const areFetched = useAppSelector(areProjectsFetched);

  const { t } = useTranslation();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const createProjectDialog = usePopup();

  const isAuthenticated = useIsAuthenticated();

  const shouldDisplayPrivateTab = user && privateProjects.length > 0;

  return (
    <>
      {areFetched ? (
        <>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label={t("_Projects")}
              >
                {shouldDisplayPrivateTab && (
                  <Tab label={t("_Private projects")} {...a11yProps(0)} />
                )}
                <Tab label={t("_Public projects")} {...a11yProps(1)} />
                <Box sx={{ flex: "1 1 100%" }} />

                {isAuthenticated && (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Button
                      variant="contained"
                      onClick={createProjectDialog.open}
                      size="small"
                    >
                      {t("_Create Project")}
                    </Button>
                  </Box>
                )}
              </Tabs>
            </Box>
            {shouldDisplayPrivateTab && (
              <TabPanel
                value={value}
                index={0}
                projects={privateProjects}
                areFetched={areFetched}
              />
            )}
            <TabPanel
              value={value}
              index={shouldDisplayPrivateTab ? 1 : 0}
              projects={publicProjects}
              showVersions
              areFetched={areFetched}
            />
          </Box>
          <CreateProjectDialog
            open={createProjectDialog.isOpen}
            onClose={createProjectDialog.close}
          />
        </>
      ) : (
        <Box sx={{ opacity: 0.7 }}>{t("_Loading projects")}</Box>
      )}
    </>
  );
};

export default ProjectsList;

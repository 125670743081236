import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import Expander from "components/atoms/Expander";

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (event: React.SyntheticEvent) => void;
  title: string;
  actionTitle: string;
  children: React.ReactNode;
  color?: "primary" | "error";
  disabled?: boolean;
};

const BasicDialog = ({
  open,
  onClose,
  onSubmit,
  title,
  actionTitle,
  children,
  color = "primary",
  disabled = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          {t("_Cancel")}
        </Button>
        <Expander />
        <Button
          onClick={onSubmit}
          variant="contained"
          color={color}
          disabled={disabled}
        >
          {actionTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BasicDialog;

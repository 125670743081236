import { useTranslation } from "react-i18next";
import { useNavigate, generatePath, useParams } from "react-router-dom";

import { routes } from "models/routes";
import BasicDialog from "components/molecules/BasicDialog";
import FileNode, { getType } from "models/fileNode";
import { useAppDispatch } from "hooks/redux";
import { deleteFileNode, parse } from "store";
import useCurrentProject from "hooks/useCurrentProject";

type Props = {
  open: boolean;
  onClose: () => void;
  file: FileNode;
};

export const DeleteFileOrFolderDialog = ({ open, onClose, file }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { project, version, projectId } = useCurrentProject();
  const { fileId } = useParams();

  const deleteFile = async () => {
    try {
      await dispatch(deleteFileNode(file)).unwrap();

      if (fileId === file.id) {
        const path = generatePath(routes.editor, {
          projectId,
        });
        navigate(path);
      }
      onClose();
      await dispatch(parse({ project, version })).unwrap();
    } catch (error: any) {
      console.error(error);
    }
  };

  const type = getType(file);

  const { t } = useTranslation();

  return (
    <BasicDialog
      open={open}
      onClose={onClose}
      onSubmit={deleteFile}
      title={t("_Delete...", { type })}
      actionTitle={t("_Delete")}
      color="error"
    >
      {t("_Do you really want to delete the", { type, fileName: file.name })}
    </BasicDialog>
  );
};

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
} from "@mui/material";

import Version from "models/version";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  onCancel: () => void;
  version: Version;
  onSubmit: () => void;
};

const ConfirmPublishVersionDialog = ({
  open,
  onCancel,
  onSubmit,
  version,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("_Confirm publishing Version")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            minWidth: 500,
            padding: 1,
          }}
        >
          <div>{t("_The new version")}</div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t("_Cancel")}</Button>
        <Button onClick={onSubmit} variant="contained">
          {t("_Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPublishVersionDialog;

import api from "utils/api";
import Project from "models/project";

export type UpgradeType = "major" | "minor" | "patch";

export const VERSION_LATEST = "latest";

export type VersionNumber = {
  major: number;
  minor: number;
  patch: number;
};

export type Version = typeof VERSION_LATEST | VersionNumber;

export default Version;

export function stringToVersion(s: string): Version {
  if (s === VERSION_LATEST) {
    return VERSION_LATEST;
  }

  const [major, minor, patch] = s.split(".").map((p) => parseInt(p, 10));
  return { major, minor, patch };
}

function isInstanceOfVersionNumber(version: any): version is VersionNumber {
  return typeof version === "object" && "major" in version;
}

export function versionToString(version: Version): string {
  if (isInstanceOfVersionNumber(version)) {
    return `${version.major}.${version.minor}.${version.patch}`;
  }

  return VERSION_LATEST;
}

export const compareVersionNumbers = (
  a: VersionNumber,
  b: VersionNumber
): number => {
  if (a.major !== b.major) {
    return a.major - b.major;
  }

  if (a.minor !== b.minor) {
    return a.minor - b.minor;
  }

  return a.patch - b.patch;
};

export const areVersionEqual = (versionA: Version, versionB: Version) => {
  return compareVersion(versionA, versionB) === 0;
};

export const compareVersion = (a: Version, b: Version): number => {
  if (a === VERSION_LATEST) {
    if (b === VERSION_LATEST) {
      return 0;
    }
    return 1;
  }

  if (b === VERSION_LATEST) {
    return -1;
  }

  return compareVersionNumbers(a, b);
};

export async function fetchByProject(project: Project): Promise<Version[]> {
  const response = await api().get(`/project/${project.id}/versions`);

  return response.data.map(stringToVersion);
}

export type VersionCreate = {
  project: Project;
  upgradeType: UpgradeType;
};

class NotImplemented extends Error {
  constructor(message = "", m: string) {
    super(message);
    this.message = m;
  }
}

export async function create(versionCreate: VersionCreate): Promise<Version> {
  const response = await api()
    .post(`/project/${versionCreate.project.id}/versions`, {
      name: versionCreate.project.name,
      upgrade_type: versionCreate.upgradeType,
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data); // => the response payload
      }
      throw new NotImplemented("", error.response.data.error);
    });

  return stringToVersion(response.data.new_version);
}

export async function fetchRemoteParserVersion(): Promise<Version> {
  const response = await api().get(`/parser/version`);

  return stringToVersion(response.data.parser_version);
}

export const upgradeVersion = (
  version: Version | undefined,
  increment: UpgradeType
): Version => {
  if (version === undefined || version === "latest") {
    return { major: 1, minor: 0, patch: 0 };
  }

  if (increment === "major") {
    return {
      major: version.major + 1,
      minor: 0,
      patch: 0,
    };
  }

  if (increment === "minor") {
    return {
      major: version.major,
      minor: version.minor + 1,
      patch: 0,
    };
  }

  return {
    major: version.major,
    minor: version.minor,
    patch: version.patch + 1,
  };
};

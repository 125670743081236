import { omit, pick } from "lodash";
import { generatePath, useSearchParams, useParams } from "react-router-dom";

const onlyPathParams = ["projectId", "fileId"];

type Params = {
  [id: string]: any;
};

const useRoute = () => {
  let [searchParams] = useSearchParams();
  const { projectId } = useParams();

  return {
    generatePath: (route: string, params: Params) => {
      const pathParams = pick(params, onlyPathParams) as Params;
      const urlSearchParams = omit(params, onlyPathParams) as Params;

      const urlParams = new URLSearchParams();
      Object.keys(urlSearchParams).forEach((key: string) => {
        urlParams.append(key, urlSearchParams[key]);
      });

      const table = searchParams.get("table");
      if (table && projectId === params.projectId) {
        urlParams.append("table", table);
      }

      const paranodeTable = searchParams.get("paranode_table");
      if (paranodeTable && projectId === params.projectId) {
        urlParams.append("paranode_table", paranodeTable);
      }

      const paranodeTableRoot = searchParams.get("paranode_table_root");
      if (paranodeTableRoot && projectId === params.projectId) {
        urlParams.append("paranode_table_root", paranodeTableRoot);
      }

      const version = searchParams.get("version");
      if (version && projectId === params.projectId) {
        urlParams.append("version", version);
      }

      const pattern = `${route}?${urlParams.toString()}`;

      const path = generatePath(pattern, pathParams);

      return path;
    },
  };
};

export * from "models/routes";

export default useRoute;

import Range from "./Range";

// a TableDefinition represents a predefined set of Paradigm to display in a
// recursive table layout.
export type CategoryHierarchyDefinition = {
  // the id of the paradigm element that is the root (table initial layout) of
  // the table
  root: string;

  // range for this category_hierarchies definition
  range: Range;

  // the invariant_mapping associate cells to paradigms
  // the id strings are category_id to singular sequence and the values are
  // category id to paradigms
  invariant_mapping: { [id: string]: string };
};

export default CategoryHierarchyDefinition;

type CompareInterface = (o: { paradigmId: string; nodeId: string }) => boolean;

export const findParentNode = (
  table: CategoryHierarchyDefinition | undefined,
  compare: CompareInterface
) =>
  Object.entries(table?.invariant_mapping || {})
    .map(([nodeId, paradigmId]) => ({
      nodeId,
      paradigmId,
    }))
    .find(compare)?.nodeId;

import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import NewWindow from "react-new-window";
import { Tooltip } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CloseIcon from "@mui/icons-material/Close";

import styles from "./BottomPanel.module.scss";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import {
  getBottomPanelHeight,
  setBottomPanelHeight,
  getCurrentBottomTab,
  setCurrentBottomTab,
  isSeparateWindowOpen,
  setSeparateWindowOpen,
} from "store";
import useEventListener from "hooks/useEventListener";
import BottomPanel from "./BottomPanel";

const getWindowTitle = (currentBottomTab: string | null) => {
  if (currentBottomTab === "tables") return "_Tables";
  if (currentBottomTab === "errors") return "_Errors";
  if (currentBottomTab === "warnings") return "_Warnings";

  return "";
};

const BottomPanelContainer = () => {
  const currentBottomTab = useAppSelector(getCurrentBottomTab);

  const dispatch = useAppDispatch();

  const bottomPanelHeight = useAppSelector(getBottomPanelHeight);
  const [height, setHeight] = useState<number>(bottomPanelHeight);

  const currentPosition = useRef(0);
  const initialHeight = useRef<number>(0);
  const isResizing = useRef(false);
  const resizeBar = useRef<HTMLDivElement>(null);

  const separateWindow = useAppSelector(isSeparateWindowOpen);

  const dragStart = (event: React.MouseEvent) => {
    currentPosition.current = event.screenY;
    initialHeight.current = height;
    isResizing.current = true;
  };

  useEventListener("mouseup", (event) => {
    if (isResizing.current) {
      isResizing.current = false;
      if (bottomPanelHeight !== height) {
        dispatch(setBottomPanelHeight(height));
      }
    }
  });

  useEventListener("mousemove", (event) => {
    if (isResizing.current && event.which) {
      const diff = event.screenY - currentPosition.current;
      const newHeight = Math.max(initialHeight.current - diff, 0);
      setHeight(newHeight);
    } else {
      isResizing.current = false;
    }
  });

  const close = () => {
    dispatch(setCurrentBottomTab(null));
  };

  const { t } = useTranslation();

  if (separateWindow) {
    return (
      <NewWindow
        title={t(getWindowTitle(currentBottomTab))}
        onUnload={() => {
          dispatch(setSeparateWindowOpen(false));
          close();
        }}
      >
        <BottomPanel />
      </NewWindow>
    );
  }

  if (!currentBottomTab) {
    return null;
  }

  return (
    <div
      className={styles.BottomPanelContainer}
      style={{ flex: `0 0 ${height}px` }}
    >
      <div
        className={styles.resizeBar}
        ref={resizeBar}
        onMouseDown={dragStart}
      />
      <BottomPanel>
        <Tooltip title={t("_Open in new window") || ""} placement="top">
          <OpenInNewIcon
            className={styles.button}
            fontSize="small"
            onClick={() => dispatch(setSeparateWindowOpen(true))}
          />
        </Tooltip>
        <Tooltip title={t("_Close") || ""} placement="top">
          <CloseIcon
            className={styles.button}
            fontSize="small"
            onClick={close}
          />
        </Tooltip>
      </BottomPanel>
    </div>
  );
};

export default BottomPanelContainer;

import FileNode from "models/fileNode";
import Project from "models/project";

import { updateFileNode } from "./fileNodes.slice";
import { parse } from "./parserOutputs.slice";
import store from "./index";

export const saveAndParse =
  (fileToSave: FileNode, project: Project) =>
  async (dispatch: typeof store.dispatch) => {
    try {
      const updateContent = { file: fileToSave, content: fileToSave.content };
      await dispatch(updateFileNode(updateContent));
      if (project) {
        await dispatch(
          parse({ project, version: fileToSave.version })
        ).unwrap();
      }
    } catch (error) {
      console.error(error);
    }
  };

import { useAppSelector } from "hooks/redux";
import { getCurrentUser } from "store";

const useCurrentUser = () => {
  const currentUser = useAppSelector(getCurrentUser);

  return currentUser;
};

export default useCurrentUser;

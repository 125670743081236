import { useContext } from "react";

import TableViewIcon from "@mui/icons-material/TableView";

import ConceptButton from "components/atoms/ConceptButton";
import useSelectedParanodeTable from "hooks/useSelectedParanodeTable";
import { findParentNode, Phrase } from "models/parser";
import Table from "components/atoms/Table";
import ParanodeTableLink from "components/atoms/ParanodeTableLink";
import { useAppSelector } from "hooks/redux";
import useCurrentProject from "hooks/useCurrentProject";
import { getElement, getTableLevelMax } from "store";

import ParanodeTableContext from "./ParanodeTableContext";
import ParanodeTableNDimensions from "./ParanodeTableNDimensions";

import styles from "./ParanodeTableItem.module.scss";

type Props = {
  element: Phrase;
  className?: string;
  isHeader?: boolean;
};

const FinalCell = ({ element, className, isHeader }: Props) => {
  const { table } = useSelectedParanodeTable();
  const { projectId } = useCurrentProject();

  const prevent = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const phraseId = findParentNode(
    table,
    ({ paradigmId }) => paradigmId === element.id
  );

  const phrase = useAppSelector((state) =>
    getElement(state, projectId, phraseId)
  );

  return (
    <ConceptButton
      className={`${styles.content} ${isHeader ? styles.header : ""}`}
      script={null}
      element={phrase || element}
    >
      {table &&
        table.invariant_mapping[element.id] &&
        table.root !== element.id && (
          <div className={styles.openParanodeTable} onClick={prevent}>
            <ParanodeTableLink table={table.root} tableRoot={element.id}>
              <TableViewIcon />
            </ParanodeTableLink>
          </div>
        )}
    </ConceptButton>
  );
};

const RecursiveNodeItem = ({ element, className, isHeader }: Props) => {
  const level = useContext(ParanodeTableContext)?.level || 0;

  const { table } = useSelectedParanodeTable();

  const { projectId } = useCurrentProject();

  const paradigmId = table?.invariant_mapping?.[element.id];

  const paradigm = useAppSelector((state) =>
    getElement(state, projectId, paradigmId || "")
  ) as Phrase;

  if (!paradigm || !paradigm.table) {
    return (
      <FinalCell element={element} className={className} isHeader={isHeader} />
    );
  }

  return (
    <ParanodeTableContext.Provider value={{ level: level + 1 }}>
      <Table
        tableId={paradigm?.table}
        component={ParanodeTableNDimensions}
        className={styles.table}
      />
    </ParanodeTableContext.Provider>
  );
};

const NodeItem = ({ element, className, isHeader }: Props) => {
  const level = useContext(ParanodeTableContext)?.level || 0;

  const tableLevelMax = useAppSelector(getTableLevelMax);

  if (level === tableLevelMax - 1) {
    return (
      <FinalCell element={element} className={className} isHeader={isHeader} />
    );
  }

  return (
    <RecursiveNodeItem
      element={element}
      className={className}
      isHeader={isHeader}
    />
  );
};

export default NodeItem;

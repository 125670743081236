import { ReactNode, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "hooks/redux";
import useFetchApi from "hooks/useFetchApi";
import Project from "models/project";
import Version from "models/version";

import {
  fetchFileNodes,
  areFileNodesFetched,
  parse,
  getDictionary,
} from "store";
import useCurrentProject from "hooks/useCurrentProject";

type ProjectPageProps = {
  children?: ReactNode;
};

const ProjectPage = ({ children }: ProjectPageProps) => {
  const dispatch = useAppDispatch();

  const { projectId, currentProject, currentVersion } = useCurrentProject();

  const fileNodesFetched = useAppSelector(
    areFileNodesFetched(projectId || "", currentVersion)
  );

  // const versionsFetched = useAppSelector(areVersionsFetched(projectId || ""));

  const currentDictionary = useAppSelector(getDictionary);

  const dictionaryFilesNodesFetched = useAppSelector(
    areFileNodesFetched(
      currentDictionary?.id || "",
      currentProject?.dictionaryVersion
    )
  );

  const fetchFiles = useMemo(
    () => (project: Project, version: Version) => {
      return dispatch(fetchFileNodes({ project, version })).unwrap();
    },
    [dispatch]
  );

  useFetchApi(
    (project) => project && project.id,
    (project) => fetchFiles(project, currentVersion),
    [currentProject],
    fileNodesFetched
  );

  useFetchApi(
    (dictionary, project) => dictionary && !project?.isDictionary,
    (dictionary, project) => fetchFiles(dictionary, project?.dictionaryVersion),
    [currentDictionary, currentProject],
    dictionaryFilesNodesFetched
  );

  useEffect(() => {
    if (
      currentProject &&
      currentProject?.id &&
      fileNodesFetched &&
      (dictionaryFilesNodesFetched || currentProject.isDictionary)
    ) {
      dispatch(parse({ project: currentProject, version: currentVersion }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentProject?.id,
    dispatch,
    fileNodesFetched,
    dictionaryFilesNodesFetched,
  ]);

  return <>{currentProject && projectId ? <Outlet /> : null}</>;
};

export default ProjectPage;
